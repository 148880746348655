@import './datepicker.ios.colors';

@if ($mbsc-ios-theme and $mbsc-datepicker) {
  .mbsc-ios {
    &.mbsc-picker {
      .mbsc-popup-overlay-top,
      .mbsc-popup-overlay-bottom {
        background: none;
      }
    }

    &.mbsc-datepicker-inline {
      border-top: 1px solid;
      border-bottom: 1px solid;
      margin-top: -1px;
      margin-bottom: -1px;
      z-index: 2;
      position: relative;
    }

    &.mbsc-datepicker .mbsc-calendar-grid,
    &.mbsc-datepicker .mbsc-calendar-cell,
    &.mbsc-datepicker .mbsc-calendar-cell-inner {
      border-color: transparent;
    }

    &.mbsc-datepicker .mbsc-selected .mbsc-calendar-day-text,
    &.mbsc-datepicker .mbsc-highlighted .mbsc-calendar-day-text,
    &.mbsc-datepicker .mbsc-range-hover-start .mbsc-calendar-day-text,
    &.mbsc-datepicker .mbsc-range-hover-end .mbsc-calendar-day-text,
    &.mbsc-datepicker .mbsc-hover .mbsc-calendar-day-text {
      width: 1.444445em;
      height: 1.444445em;
      margin: 0.16667em;
      font-size: 1.125em;
      line-height: 1.444445em;
    }

    &.mbsc-picker-header {
      line-height: 1.25em;
      padding: 0.75em 4.375em;
      font-weight: bold;
      text-align: center;
      border-bottom: 1px solid;
    }

    /* Range Controls */

    &.mbsc-range-control-wrapper {
      padding: 0 0.75em;
      overflow: hidden;
    }

    &.mbsc-range-control-wrapper .mbsc-segmented {
      width: 17em; // 272px
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      box-sizing: border-box;
    }

    // Segmented width based on controls

    &.mbsc-datepicker-control-calendar.mbsc-datepicker-control-timegrid .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-calendar.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-date.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented,
    &.mbsc-datepicker-control-datetime .mbsc-range-control-wrapper .mbsc-segmented {
      width: 25em; // 400px: default format can show -> "09/25/2020 12:30 AM"
    }

    &.mbsc-range-control-wrapper .mbsc-segmented-button {
      display: block;
      padding: 0 0.5em;
    }

    &.mbsc-range-control-wrapper .mbsc-range-value-nonempty .mbsc-segmented-button {
      padding: 0 2.0625em 0 0.5em;
    }

    &.mbsc-range-control-wrapper .mbsc-range-value-nonempty .mbsc-segmented-button.mbsc-rtl {
      padding: 0 0.5em 0 2.0625em;
    }

    &.mbsc-range-control-label,
    &.mbsc-range-control-value {
      text-align: left;
      line-height: 1.538462em; // 20px

      &.active {
        font-weight: 600;
      }

      &.mbsc-rtl {
        text-align: right;
      }
    }

    &.mbsc-range-control-label {
      padding: 0.615385em 0.615385em 0 0.615385em; // 8px
    }

    &.mbsc-range-control-value {
      padding: 0 0.615385em 0.615385em 0.615385em; // 8px
      text-overflow: ellipsis;
      overflow: hidden;

      &.active {
        padding: 0 0.571429em 0.571429em 0.571429em; // 8px
        font-size: 1.076923em; // 14px
        line-height: 1.428572em; // 20px
      }
    }

    &.mbsc-range-label-clear {
      margin-top: -0.692308em; // 9px
      width: 1.307693em; // 17px
      height: 1.307693em;

      &.mbsc-ltr {
        right: 1em;
      }

      &.mbsc-rtl {
        left: 1em;
      }
    }
  }

  @include mbsc-ios-datepicker('ios', $mbsc-ios-colors);
  @include mbsc-ios-datepicker('ios-dark', $mbsc-ios-dark-colors);
}
