//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
$primary: #164387;
$primary-active: #20519D;
//$primary-light: #DCDDFF;
$primary-light: #164387;
$primary-light-dark: #212e48;
$primary-inverse: #ffffff;

// $primaryn: #164387;
// $primaryn-active: #0d2e5f;
// $primaryn-inverse: #ffffff;

$btnGray: #F3F6F9;
$btnGray-active: #164387;
$btnGray-inverse: #164387;



$prlightc : #DCDDFF;
$prlightc-active : #cccdf1;
$prlightc-inverse: #5357E9;

$priorbutton : #FF735F;
$priorbutton-active : #FF735F;
$priorbutton-inverse: #ffffff;

// Success
$success: #4ac481;
$success-active: #3aa86c;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: #ffffff;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: #ffffff;

// Danger
$danger: #f1416c;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: #ffffff;

// Warning
$warning: #ffc700;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: #ffffff;
