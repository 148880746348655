@import '../../../themes/common';

@if $mbsc-eventcalendar {
  .mbsc-schedule-wrapper {
    overflow: auto;
  }

  .mbsc-schedule-grid {
    // Needed for Safari 13/14
    height: 100%;
  }

  /* Resource groups */

  .mbsc-schedule-resource-group {
    // min-width: 16em;
    box-sizing: border-box;
    flex-basis: 16em;
  }

  .mbsc-schedule-resource-group:first-child:last-child {
    // min-width: 0;
    flex-basis: 0;
  }

  .mbsc-schedule-resource-group.mbsc-ltr {
    border-left-width: 2px;
    border-left-style: solid;
  }

  .mbsc-schedule-resource-group.mbsc-rtl {
    border-right-width: 2px;
    border-right-style: solid;
  }

  .mbsc-schedule-resource-group:first-child {
    border: 0;
  }

  /* Schedule header */

  .mbsc-schedule-date-header-text {
    line-height: 1.5em;
    text-align: center;
  }

  .mbsc-schedule-header {
    overflow: hidden;
  }

  .mbsc-schedule-all-day-group-wrapper,
  .mbsc-schedule-header-wrapper {
    min-width: 0;
    overflow: hidden;
  }

  .mbsc-schedule-header-item {
    display: block; // for angular component which displays in inline
    position: relative;
    text-align: center;
    line-height: 1.25em;
  }

  .mbsc-schedule-header-item.mbsc-hover {
    cursor: pointer;
  }

  .mbsc-schedule-header-dayname {
    font-size: 0.75em;
  }

  .mbsc-schedule-header-day {
    font-size: 1.125em;
  }

  /* Schedule column width for: header item, header resource, all-day item, schedule column  */

  .mbsc-schedule-col-width {
    box-sizing: border-box;
    // min-width: 0; // needed for the items equal width
    width: 2.5em;
  }

  .mbsc-schedule-wrapper-multi .mbsc-schedule-col-width {
    width: 6.25em;
  }

  /* Resource title */

  .mbsc-schedule-resource {
    overflow: hidden;
  }

  .mbsc-schedule-resource-title {
    padding: 0.5em;
    font-size: 0.875em;
    font-weight: 600;
    text-align: center;
  }

  /* Schedule all day event container */

  .mbsc-schedule-all-day-cont {
    position: relative;
  }

  .mbsc-schedule-all-day-wrapper {
    position: relative;
    max-height: 7.8125em;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .mbsc-schedule-all-day-wrapper-shadow:after {
    content: '';
    box-shadow: inset 0 0.5em 0.25em -0.5em rgba(48, 48, 48, 0.5);
    height: 4px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;
    z-index: 4;
    pointer-events: none;
  }

  .mbsc-schedule-all-day-item {
    position: relative;
  }

  .mbsc-schedule-all-day-item:first-child,
  .mbsc-schedule-column:first-child {
    border: 0;
  }

  .mbsc-schedule-timezone-labels {
    position: absolute;
    bottom: 0;
    z-index: 1;
    font-weight: bold;
  }

  .mbsc-schedule-timezone-label {
    font-size: 0.75em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mbsc-schedule-time-indicator-tz {
    width: 5.666667em;
  }

  .mbsc-schedule-time-indicator-tz.mbsc-ltr,
  .mbsc-schedule-timezone-label.mbsc-ltr {
    text-align: right;
  }

  .mbsc-schedule-time-indicator-tz.mbsc-rtl,
  .mbsc-schedule-timezone-label.mbsc-rtl {
    text-align: left;
  }

  /* Time section */

  .mbsc-schedule-time-wrapper {
    position: relative;
    width: 4.25em;
    height: 3.125em;
    box-sizing: border-box;
    pointer-events: auto;
  }

  .mbsc-schedule-time {
    position: relative;
    line-height: 2em;
    top: -1em;
    font-size: 0.75em;
  }

  .mbsc-schedule-time-end {
    position: absolute;
    top: auto;
    bottom: -1em;
    left: 0;
    right: 0;
  }

  .mbsc-schedule-time-col.mbsc-ltr {
    text-align: right;
  }

  .mbsc-schedule-time-col.mbsc-rtl {
    text-align: left;
  }

  .mbsc-schedule-time-cont-pos {
    position: relative;
  }

  /* Grid */

  .mbsc-schedule-time-cont-inner {
    position: absolute;
    width: 100%;
    z-index: 2;
    pointer-events: none;
    height: 100%;
    box-sizing: border-box;
    padding: 0.5em 0;
  }

  .mbsc-schedule-column {
    padding: 0.5em 0;
  }

  .mbsc-schedule-column-inner {
    position: relative;
  }

  .mbsc-schedule-all-day-item,
  .mbsc-schedule-item {
    box-sizing: border-box;
  }

  .mbsc-schedule-item {
    height: 3.125em;
    position: relative;
    border-top: 1px solid;
  }

  .mbsc-schedule-item-last {
    border-bottom: 1px solid;
  }

  /* Event spacing */

  .mbsc-schedule-events {
    position: absolute;
    height: 100%;
  }

  .mbsc-schedule-events.mbsc-ltr {
    left: 0;
    right: 0.5em;
  }

  .mbsc-schedule-events-more.mbsc-ltr {
    right: 1.875em;
  }

  .mbsc-schedule-events.mbsc-rtl {
    right: 0;
    left: 0.5em;
  }

  .mbsc-schedule-events-more.mbsc-rtl {
    left: 1.875em;
  }

  /* All day events */

  .mbsc-schedule-event-all-day {
    .mbsc-schedule-event-resize {
      width: 0.25em;
      height: 100%;
      cursor: ew-resize;
    }

    .mbsc-schedule-event-resize:after {
      top: 50%;
      right: auto;
      left: auto;
      margin-top: -0.25em;
    }

    .mbsc-schedule-event-resize-start.mbsc-ltr,
    .mbsc-schedule-event-resize-end.mbsc-rtl {
      right: auto;
    }

    .mbsc-schedule-event-resize-end.mbsc-ltr,
    .mbsc-schedule-event-resize-start.mbsc-rtl {
      left: auto;
    }

    .mbsc-schedule-event-resize-start.mbsc-ltr:after,
    .mbsc-schedule-event-resize-end.mbsc-rtl:after {
      right: -0.25em;
    }

    .mbsc-schedule-event-resize-end.mbsc-ltr:after,
    .mbsc-schedule-event-resize-start.mbsc-rtl:after {
      left: -0.25em;
    }

    /* Increase touch area for resize dots */

    .mbsc-schedule-event-resize-start-touch:before {
      height: 1.5em;
      top: 50%;
      left: -0.75em;
      right: auto;
      margin-top: -0.75em;
    }

    .mbsc-schedule-event-resize-end-touch:before {
      height: 1.5em;
      top: 50%;
      left: auto;
      right: -0.75em;
      margin-top: -0.75em;
    }
  }

  /* Event buffer */

  .mbsc-schedule-event-buffer {
    position: absolute;
    left: 1px;
    right: 1px;
    box-sizing: content-box;
    z-index: -1;
  }

  .mbsc-schedule-event-buffer-before {
    bottom: 100%;
    margin-bottom: -0.25em;
    padding-bottom: 0.25em;
    border-top-left-radius: 0.25em;
    border-top-right-radius: 0.25em;
  }

  .mbsc-schedule-event-buffer-after {
    top: 100%;
    margin-top: -0.25em;
    padding-top: 0.25em;
    border-bottom-left-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
  }

  /* Large screen */

  .mbsc-calendar-width-md {
    .mbsc-schedule-event-title {
      font-size: 1.2em;
    }

    .mbsc-schedule-event-range {
      font-size: 1em;
    }

    .mbsc-schedule-event-all-day-title {
      font-size: 1em;
    }

    .mbsc-schedule-event-all-day-inner {
      font-size: 0.75em;
      height: 1.5em;
      line-height: 1.5em;
      padding: 0 0.333334em;
    }
  }
}
