.planningScreen {
    @media (max-width: 1199.98px) {
        margin: 108px -30px 0;
    }

    @media (max-width: 991.98px) {
        margin: 124px -15px 0;
    }

    @media (min-width: 1200px) {
        margin: -15px -30px 0;
    }

    @media (max-width: 767px) {
        margin: 15px 0 0;
    }
}

.capacityShow {
    .p-main {
        @media (max-width: 1199.98px) {
            height: calc(100% - 500px) !important;
        }

        @media (max-width: 991.98px) {
            height: calc(100% - 490px) !important;
        }

        @media (min-width: 1200px) {
            /* height: calc(100% - 345px) !important; */
            height: calc(100% - 337px) !important;
        }

        @media (max-width: 767px) {
            height: calc(100% - 400px) !important;
        }

        .mbsc-grid,
        .mbsc-grid>.mbsc-row,
        .mbsc-grid>.mbsc-row>.mbsc-col-sm-12,
        .mbsc-grid>.mbsc-row>.mbsc-col-sm-12>.mbsc-timeline-grid-scroll {
            height: 100%;
        }
    }
}


.apexcharts-tooltip{    
    &.apexcharts-theme-light{
        background: #ffffff !important;
        padding: 5px 5px 0 !important;
        border-radius: 4px !important;
    }
    .apexcharts-tooltip-marker{
        top: 1px;
    }
}

.p-main {
    .bg-body {
        .card-header{
            align-items: inherit;
        }
        .card-body {

            >#kt_todo_plan_scroll {
                max-height: 100%;
            }
        }
    }
}

.heightShow {
    @media (max-width: 1199.98px) {
        height: calc(100vh - 500px);
    }

    @media (max-width: 991.98px) {
        height: calc(100vh - 490px);
    }

    @media (min-width: 1200px) {
        height: calc(100vh - 420px);
    }

    @media (max-width: 767px) {
        height: calc(100vh - 400px);
    }
}

.defaultHeight {
    min-height: 50vh;
}

.todoShow {
    width: 70% !important;
    float: left
}

.planningFilter {
    padding: 20px 0;
    border-bottom: 1px solid #DADCE0;
    @media (min-width: 768px) {
        position: fixed;
        left: 0;
        right: 0;
        top: 110px;
        z-index: 99;
    }

    @media (min-width: 1199.98px) {
        position: fixed;
        left: 210px;
        z-index: 9;
        top: 70px;
        right: 0;
        padding: 15px 0;
    }

    @media (max-width: 767px) {
        white-space: nowrap;
        overflow: auto;
        padding: 10px 0;
    }

}

.btn {
    &.btn-border-theme {
        border-color: #BCBEFF !important;
    }

    &.btn-bgLight {
        background-color: #DCDDFF;
    }

    &.btn-sm-48 {
        min-width: 40px;
        height: 40px;
        font-size: 13px !important;

        @media (min-width: 1400px) {
            min-width: 48px;
            height: 48px;
            font-size: 14px !important;
        }

        @media (max-width: 767px) {
            min-width: 35px;
            height: 35px;
            font-size: 12px !important;

            svg {
                width: 15px !important;
            }
        }
    }

    @media (hover: hover) {

        /* when hover is supported */
        &.btn-bgLight:hover {
            background-color: #cccdf1;
            transition: 300ms all;
        }
    }
}

.mbsc-ios {
    &:before {
        display: none;
    }

    &.mbsc-segmented {
        padding: 3px;
    }

    &.mbsc-segmented-selectbox{
        padding: 0 !important;
    }
    &.mbsc-segmented-selectbox-inner {
        border-radius:0 !important;
        background: var(--kt-primary) !important;
    }
    .mbsc-segmented-selectbox{
        padding: 0;
    }
    .mbsc-segmented-item{
        min-width: 90px;        
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        font-family: 'Source Sans Pro', sans-serif;
        line-height: 1.2;                    
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;        
    }
    &.mbsc-segmented-button {
        &.mbsc-button {      
            font: inherit !important;      
            display: inline !important;
        }
    }

    .mbsc-datepicker{
        .mbsc-segmented-item{
            height: auto;
        }
    }

    .mbsc-segmented-item-checked {
        &.mbsc-segmented-item-selected {
            .mbsc-button {
                color: #ffffff;
            }
        }
    }

    .mbsc-calendar-controls:empty {
        display: none;
    }

    .mbsc-schedule-event-inner {
        padding-left: 12px;
    }

    .mbsc-timeline-row {
        flex: none;
    }

    .mbsc-timeline-grid {
        min-height: 100%;
    }

    &.mbsc-calendar-width-md {
        .mbsc-calendar-title {
            font: 600 18px/1.1111 'Source Sans Pro', sans-serif !important;
            color: var(--kt-primary);
        }
    }

    .mbsc-button {
        font-size: 14px;
    }

    .mbsc-datepicker-control-calendar{
        .mbsc-range-control-wrapper{
            .mbsc-segmented{
                padding: 0;
                width: 100%;
                background-color: #f0f1f6;
                label{
                    &.mbsc-segmented-item{
                        .mbsc-segmented-selectbox-inner{
                            border-radius: 0;
                        }
                        .mbsc-range-control-value {
                            font-weight: 300;
                            font-size: 12px;
                            color: #000000;
                        }
                        .mbsc-range-control-value,
                        .mbsc-range-control-label {
                            padding-left: 5px;
                            padding-right: 5px;
                        }
                        .mbsc-range-label-clear{
                            background: url(icons/calender-close.svg) no-repeat center center / 21px auto;            
                            &:hover{
                                opacity: 0.9;
                            }
                        }
                    }
                    &.mbsc-segmented-item-selected{
                        .mbsc-range-control-value {
                            color: #ffffff !important;                            
                        }
                    }    
                }
            }    
        }
    }


    .md-custom-range-view-controls{
        .mbsc-calendar-button-today{
            color: var(--kt-primary) !important;
            font: 400 16px/1.5 'Source Sans Pro', sans-serif !important;
            margin: 0 16px !important;
        }
        .mbsc-calendar-button:not(.mbsc-calendar-button-today),
        .mbsc-icon-button{
            width: 32px;
            height: 32px;
            border: 1px solid #164387;
            background: url(icons/left-arrow.svg) no-repeat center center;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            padding: 0;
            span{
                &.mbsc-button-icon{
                    display: none;
                }
            }
            &[aria-label="Next page"]{
                background-image: url(icons/right-arrow.svg);
            }
        }
    }


}

.yearView{
    .mbsc-flex-none{
        > .mbsc-flex{
            &:nth-child(2){
                background: #F3F6F9;        
                .mbsc-timeline-month{
                    
                }                
            }
        }
    }
    .mbsc-timeline-header-bg{
        .mbsc-flex-1-1{
            .mbsc-flex:not(:first-child){
                background-color: var(--kt-gray-new);
            }
        }
    }
}

.mbsc-grid {

    .mbsc-ios.mbsc-schedule-all-day-item:after,
    .mbsc-ios.mbsc-schedule-column,
    .mbsc-ios.mbsc-schedule-item,
    .mbsc-ios.mbsc-schedule-resource,
    .mbsc-ios.mbsc-schedule-resource-group,
    .mbsc-ios.mbsc-timeline-column,
    .mbsc-ios.mbsc-timeline-day:after,
    .mbsc-ios.mbsc-timeline-header,
    .mbsc-ios.mbsc-timeline-header-column,
    .mbsc-ios.mbsc-timeline-header-date,
    .mbsc-ios.mbsc-timeline-header-month,
    .mbsc-ios.mbsc-timeline-header-week,
    .mbsc-ios.mbsc-timeline-resource,
    .mbsc-ios.mbsc-timeline-resource-empty,
    .mbsc-ios.mbsc-timeline-row,
    .mbsc-ios.mbsc-timeline-slot-header,
    .mbsc-ios.mbsc-timeline-slots,
    .mbsc-ios.mbsc-timeline-resource {
        border-color: #DADCE0 !important;
    }
    .mbsc-timeline-resource-bg {
        box-shadow: 0 0 10px rgb(0,2,19,0.1);
    }
    .mbsc-timeline-resource-title {
        padding: 12px 16px;
    }   
    .mbsc-timeline-header-time{
        font: 400 16px/2.5625 'Source Sans Pro', sans-serif;
        text-align: center;
        color: #5C5C71;
        text-overflow: inherit;
    }
    .mbsc-timeline-header-column:not(.mbsc-timeline-no-height){
        height: 41px;
    }
    .mbsc-timeline-header-week,
    .mbsc-timeline-header-month{
        height: 50px;       
    }
    .mbsc-timeline-header-week-text,
    .mbsc-timeline-header-month-text{
        font-size: 16px;
        font-weight: 600;
        line-height: 3.0625;         
        padding: 0 8px;
    }     
    .mbsc-timeline-header-week-text{
        font-weight: 400;        
        background: #F3F6F9; 
        color: #5C5C71;
    }

   .mbsc-timeline-resource {
        flex: none;
    }

    .md-meeting-planner-cont {
        border-radius: 6px;
        margin-top: 7.2px;

        .md-meeting-planner-title {
            font-size: 13px;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 600;   line-height: 1.875;
            min-width: 92px;           
            line-height: 1.4;
            padding: 5px 5px;
            word-break: break-word;
        }
    }

    .mbsc-ios.mbsc-schedule-event-title {        
        font:600 16px/1.25 'Source Sans Pro', sans-serif;        
        min-width: 92px;
    }

    .mbsc-schedule-event-range {
        font-size: 10px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 400;   line-height: 1.875;
        min-width: 92px;
    }

    .mbsc-ios.mbsc-eventcalendar .mbsc-calendar-wrapper,
    .dynamically-color-and-invalidate-calendar {
        border: 0;
    }


}

.searchBox {

}

.capacityCard {
    position: relative;
    z-index: 11;
    box-shadow: none;
    &::before{
        display: block;
        content: '';
        height: 26px;
        background-color: #ffffff;
        border-top: 1px solid #dadce0;
        border-bottom: 1px solid #dadce0;
    }

    @media (max-width: 1199.98px) {
        margin: 0 -30px;
    }

    @media (max-width: 991.98px) {
        margin: 0 -15px 0;
    }

    @media (min-width: 1200px) {
        margin: 0 -30px;
    }

    @media (max-width: 767px) {
        margin: 15px 0 0;
    }

    &[data-kt-expanddiv="on"] {
        position: fixed;
        left: 0;
        top: 0;
        width: 100% !important;
        height: 100%;
        z-index: 9999;
        margin: 0;
    }

    .card-header {
        background-color: #ffffff;
    }

    .mbsc-ios {
        .mbsc-segmented-item{
            min-width: 76px;
            flex: inherit;
            + .mbsc-segmented-item{
                border-left: 1px solid var(--kt-primary);
            }    
        }
        &.mbsc-segmented {
            background-color: #F0F1F6;
        }

        &.mbsc-segmented-button {
            &.mbsc-button {
                font: 400 16px/1.875 'Source Sans Pro', sans-serif;
                padding: 0 10px;
                display: block;
                
            }
        }

        .mbsc-segmented-item:first-child {
            .mbsc-ios.mbsc-segmented-selectbox-inner {
                border-radius: 50rem 0 0 50rem;
            }
        }

        .mbsc-segmented-item:last-child {
            .mbsc-ios.mbsc-segmented-selectbox-inner {
                border-radius: 0 50rem 50rem 0;
            }
        }

        .mbsc-segmented-button,
        .mbsc-segmented-selectbox-inner {
            transform: none !important;
        }
    }

    .card {
        // background-color: transparent;
        background-color: #ffffff;
    }

    .card-body {
        // background-color: var(--kt-gray-new);
        background-color: #ffffff;
    }

    .apexcharts-grid {
        line {
            stroke: #EFEFEF;
            stroke-dasharray: 0;
        }
    }

    .card-header {
        .card-toolbar {
            @media (max-width: 575px) {
                margin-top: 1rem !important;
            }
        }
    }


}



.splash-screen {
    font-weight: 700;
    font-size: 2rem;
    left: 0;
    top: 0px;
    height: 100%;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0;
        height: calc(100vh - 40px);

        @media (min-width: 768px) {
            height: calc(100vh - 140px);
        }

        @media (min-width: 1199.98px) {
            height: calc(100vh - 188px);
        }
    }
}


.inline-loader {
    display: block;
    margin: 20px auto;
    text-align: center;
}

i.fa {
    color: #292D32;
    font-size: 15px;
}

.flex-fill-none {
    flex: none;
}

.todoSidebar {
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    .dropDown{
        .menu-sub-dropdown{
            position: absolute !important;
            left: 0 !important;
            top: 100% !important;
            transform: none !important;
        }
    }
}

.viewSidebar{
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    .dropDown{
        .menu-sub-dropdown{
            position: absolute !important;
            left: 0 !important;
            top: 100% !important;
            transform: none !important;
        }
    }

}


.todoShow{
    + .todoSidebar{
        opacity: 1;
        visibility: visible;        
    }
}



//for the viewsidebar
.viewShow{
    ~ .viewSidebar{
        opacity: 1;
        visibility: visible;        
    }
}


.md-date-header {
    padding: 3px;
    margin: 4px;
    text-align: center;
    border-radius: 8px;
    line-height: 20px;
}

.md-date-header-day-name {
    font-size: 13px;
    font-weight: 600;
}

.md-date-header-day-nr {
    font-size: 14px;
}

.md-date-header-events-none {
    background: #ffffff;
}

.md-date-header-events-25 {
    background: #e9f5e4;
}

.md-date-header-events-50 {
    background: #d0e3c6;
}

.md-date-header-events-75 {
    background: #aac79c;
}

.mbsc-timeline-header-date-cont{
    > div {
        > .md-date-header{
            display: inline-flex;
            background: #DBE1EA;
            border-radius: 50px;    
            padding: 6px 12px;
            > div{
                font: 600 16px/1.25 'Source Sans Pro', sans-serif;
            }            
        }    
    }
}    

.mbsc-timeline-day-month{
    .mbsc-timeline-header-date-cont{
        text-align: center;
        > div {
            padding-left: 10px;
            padding-right: 10px;
            margin-left: 0;
            margin-right: 0;
            > div{
                font-size: 13px;
            }
        }
    }            
}

// task label

.md-timeline-template .mbsc-schedule-event.mbsc-ltr {
    height: auto !important;
}

.md-timeline-template-event-cont {
    background: rgba(255, 255, 255, .8);
    font-size: 15px;
    height: 32px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.md-timeline-template-event-cont .mbsc-icon {
    padding: 5px;
    box-sizing: content-box;
}

.mbsc-timeline-event-start .md-timeline-template-event,
.mbsc-timeline-event-start .md-timeline-template-event-cont,
.mbsc-timeline-event-start .md-timeline-template-event-cont .mbsc-icon {
    border-top-left-radius: 0.25em;
    border-bottom-left-radius: 0.25em;
}

.mbsc-timeline-event-end .md-timeline-template-event,
.mbsc-timeline-event-end .md-timeline-template-event-cont,
.mbsc-timeline-event-end .md-timeline-template-event-cont .mbsc-icon {
    border-top-right-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
}

.md-timeline-template-event-cont .mbsc-icon:before {
    color: #fff;
    font-size: 18px;
}

.md-timeline-template-time {
    margin: 0 10px;
}

.md-timeline-template-title {
    color: #666;
}

.md-timeline-template .mbsc-timeline-column,
.md-timeline-template .mbsc-timeline-header-column {
    min-width: 100px;
}

.md-timeline-template .mbsc-timeline-resource,
.md-timeline-template .mbsc-timeline-row {
    min-height: 100px;
}

.mbsc-ios.mbsc-schedule-event-background {
    opacity: .3;
    transition: opacity .15s ease-in-out;
}

.mbsc-ios.mbsc-schedule-event-hover .mbsc-schedule-event-background {
    opacity: .6;
}

.mbsc-timeline-header-date.mbsc-flex.mbsc-ios.mbsc-ltr {
    visibility: hidden;
}

.dynamically-color-and-invalidate-task{
    &:hover{
        .opacity-30{
            opacity: 0.6 !important;
            transition: opacity 0.15s ease-in-out;
        }
    }    
}

.demo-month-view {
    height: 100%;
}

.md-tooltip-detail .md-tooltip .mbsc-popup-content {
    padding: 0;
}

.md-tooltip-detail .md-tooltip {
    font-size: 15px;
    font-weight: 600;
}

.md-tooltip-detail .md-tooltip-header {
    padding: 12px 16px;
    color: #eee;
}

.md-tooltip-detail .md-tooltip-info {
    padding: 16px 16px 6px 16px;
    position: relative;
    line-height: 32px;
    &.paddLR24{
        padding-left: 24px;
        padding-right: 24px;
    }    
}

.md-tooltip-detail .md-tooltip-time,
.md-tooltip-detail .md-tooltip-status-button {
    float: right;
}

.md-tooltip-detail .md-tooltip-title {
    margin-bottom: 15px;
}

.md-tooltip-detail .md-tooltip-text {
    font-weight: 400;
}

.md-tooltip-detail .md-tooltip-info .mbsc-button {
    // font-size: 14px;
    margin: 0;
}

.md-tooltip-detail .md-tooltip-info .mbsc-button.mbsc-material {
    font-size: 12px;
}

.md-tooltip-detail .md-tooltip-view-button {
    position: absolute;
    bottom: 16px;
    left: 16px;
}

.md-tooltip-detail .md-tooltip-delete-button {
    position: absolute;
    bottom: 16px;
    right: 16px;
}

.md-tooltip-detail .description_light_height
{
    line-height: 1.5;
}

.custom-event-class a.text-decoration-underline, a.text-decoration-underline:hover {
    text-decoration: underline !important;
}

.custom-event-class .mbsc-schedule-event-range, .mbsc-schedule-event-title{
    color: #000 !important;
}


.md-lunch-break-class.mbsc-schedule-invalid {
    text-align: center;
    align-items: center;
    font-weight: bold;
    background: repeating-linear-gradient(-45deg, #f3f3f3, #f3f3f3 11px, #e5e5e5 11px, #e5e5e5 22px);
}



.mbsc-ios{
    &.mbsc-timeline-resource-header-cont,
    .mbsc-timeline-header-date,
    /*&.mbsc-timeline-resource-bg,*/
    &.mbsc-timeline-resource-empty{
        background-color: var(--kt-gray-new) !important;
    }
    .timelineUser{
        b{
            font: 400 16px/1.25 'Source Sans Pro', sans-serif;        
            color: var(--kt-text-dark);
            max-width: calc(100% - 40px);
        }    
    }
}

.emptyDiv{
    &:empty{
        display: none !important;
    }    
}


.mbsc-picker{
    .mbsc-popup{
        min-width: 360px;
        .mbsc-range-control-wrapper{
            padding: 0 16px;
        }
        .mbsc-calendar-controls{
            padding: 16px;
            min-height: 1px;
        }
        .mbsc-calendar-button{
            color: var(--kt-text-dark) !important;
            font: 600 18px/1.33 "Source Sans Pro", sans-serif;            
            padding: 0;
            span{
                padding: 0;
                font: inherit;
                + span{
                    padding-left: 4px;
                }
            }
        }

        .mbsc-calendar-week-days{
            border-top: 1px solid #DADCE0;
            border-bottom: 1px solid #DADCE0;
            min-height: 42px;
            align-items: center;            
            margin-bottom: 8px;
            padding: 0 10px;
            .mbsc-calendar-week-day{
                color: var(--kt-text-dark);
                font: 600 16px/1.5 "Source Sans Pro", sans-serif;                                            
            }
        }
        .mbsc-calendar-controls{
            > .mbsc-calendar-button,
            .mbsc-icon-button{
                width: 24px;
                height: 24px;
                border: 1px solid var(--kt-primary);
                background: url(icons/left-arrow.svg) no-repeat center center;
                -webkit-border-radius: 50%;
                border-radius: 50%;
                padding: 0;
                span{
                    &.mbsc-button-icon{
                        display: none;
                    }
                }
                &[aria-label="Next page"]{
                    background-image: url(icons/right-arrow.svg);
                    margin-left: 16px;
                }
            }
        }

        .mbsc-calendar-cell,
        .mbsc-calendar-day-inner{
            border: 0;
        }

        .mbsc-calendar-day-text{
            width: 32px !important;
            height: 32px !important;
            border: 1px solid var(--kt-primary);
            color: var(--kt-text-dark);
            font: 400 16px/1.875 "Source Sans Pro", sans-serif !important;
            margin: 8px 0 !important;
            box-sizing: border-box;
        }

        .mbsc-range-day{
            .mbsc-calendar-cell-text{
                color: #ffffff !important;
                border-color: #7EA8E7 !important;
            }    
            &::after{
                background: var(--kt-primary);
                height: auto;
                top: 4px;
                bottom: 4px;
                left: 0;
                right: 0;
                margin: 0;
            }
            &.mbsc-range-day-start{
                &::after{
                    left: 4px;
                    margin: 0;
                }
            }
            &.mbsc-range-day-end{
                &::after{
                    right: 4px;
                    margin: 0;
                }
            }            
        }
        
        .mbsc-calendar-day-outer{
            .mbsc-calendar-day-text{
                opacity: 1;
                color: #A4A6AA;
                border-color: #A4A6AA;
            }
        }
        .mbsc-calendar-cell{
            &:first-child::after{                
                left: 4px;
                border-top-left-radius: 2em;
                border-bottom-left-radius: 2em;
            }
            &:last-child::after{                
                right: 4px;
                border-top-right-radius: 2em;
                border-bottom-right-radius: 2em;                
            }           
        }

        .mbsc-hover{
            .mbsc-calendar-cell-text{
                background: var(--kt-primary);
                color: #ffffff;
            }
        }
        .mbsc-calendar-slide{
            padding: 0;
        }
        .mbsc-calendar-row{
            padding: 0 11px;
        }
        .mbsc-range-control-label{
            font: 600 16px/1.5 "Source Sans Pro", sans-serif !important;
        }
        .mbsc-range-control-value{
            font: 400 16px/1.5 "Source Sans Pro", sans-serif !important;
        }

        .mbsc-range-control-wrapper{
            .mbsc-segmented{
                margin: 16px 0 0;
            }
        }
        .mbsc-calendar-picker{
            .mbsc-calendar-cell-text{
                font-size: 16px;
            }
        }
        .mbsc-selected{
            .mbsc-calendar-cell-text{
                background: var(--kt-primary);
                color: #fff;
            }
        }

        .mbsc-scroller-pointer{
            .mbsc-scroller-wheel-item{
                font: 400 16px/1.5 "Source Sans Pro", sans-serif;   
                &.mbsc-selected{
                    color: var(--kt-primary);
                    font-weight: 600;
                }
            }
        }
        
        .mbsc-scroller-wheel-group-cont{
            z-index: 1;
            .mbsc-scroller-wheel-line{
                background: #f0f1f6;
                z-index: -1;
            }
        }    

    }
    .mbsc-datepicker-tab-calendar{
        + .mbsc-datepicker-tab-time{
            border-left: 1px solid #DADCE0;
        }
    }    
}

.notification-container{
    width: 420px !important;
    top: 5% !important;
    max-height: calc(100% - 10px) !important;
}

.notification-warning {
    background-color: #2f96b4 !important;
}

.mbsc-ios.mbsc-textfield-inner{
    z-index: 1 !important;
}
