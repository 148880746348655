@import '../../themes/ios';

$mbsc-ios-input-background: $mbsc-input-background-light !default;
$mbsc-ios-input-text: $mbsc-input-text-light !default;
$mbsc-ios-input-accent: $mbsc-input-accent-light !default;
$mbsc-ios-input-border: $mbsc-input-border-light !default;

$mbsc-ios-dark-input-background: $mbsc-input-background-dark !default;
$mbsc-ios-dark-input-text: $mbsc-input-text-dark !default;
$mbsc-ios-dark-input-accent: $mbsc-input-accent-dark !default;
$mbsc-ios-dark-input-border: $mbsc-input-border-dark !default;

$mbsc-ios-colors: map-merge(
  $mbsc-ios-colors,
  (
    // Colors map
    'input-background': $mbsc-ios-input-background,
    'input-text': $mbsc-ios-input-text,
    'input-accent': $mbsc-ios-input-accent,
    'input-border': $mbsc-ios-input-border
  )
);

$mbsc-ios-dark-colors: map-merge(
  $mbsc-ios-dark-colors,
  (
    // Colors map
    'input-background': $mbsc-ios-dark-input-background,
    'input-text': $mbsc-ios-dark-input-text,
    'input-accent': $mbsc-ios-dark-input-accent,
    'input-border': $mbsc-ios-dark-input-border
  )
);

@mixin mbsc-ios-input($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');

  $bg-param: map-get($params, 'input-background');
  $text-param: map-get($params, 'input-text');
  $brd-param: map-get($params, 'input-border');
  $err-param: map-get($params, 'form-error');

  $background: if($bg-param, $bg-param, $background);
  $text: if($text-param, $text-param, $text);
  $error: if($err-param, $err-param, $mbsc-ios-error);

  $is-dark: false;
  $border-color: '';
  $input-background: '';
  $input-background-popup: '';
  $input-tag-background: '';
  $textfield-text: $text;
  $textfield-icon-color: '';

  // Light background
  @if (lightness($background) > 50%) {
    $input-background: lighten($background, 6%);
    $input-background-popup: $input-background;
    $input-tag-background: darken($background, 10%);
    $textfield-icon-color: lighten($text, 54.9);
    $border-color: darken($background, 17%);
  }

  // Dark background
  @else {
    $is-dark: true;
    $input-background: lighten($background, 11%);
    $input-background-popup: lighten($background, 17%);
    $input-tag-background: lighten($background, 23%);
    $textfield-icon-color: $mbsc-ios-dark-text;
    $border-color: lighten($background, 20%);
  }

  $border-color: if($brd-param, $brd-param, $border-color);

  .mbsc-#{$theme} {
    /* Wrapper */

    &.mbsc-textfield-wrapper {
      background: none;
    }

    &.mbsc-textfield-wrapper-underline {
      background: $input-background;
    }

    /* Form element */

    &.mbsc-label,
    &.mbsc-textfield {
      color: $textfield-text;
    }

    &.mbsc-textfield-box,
    &.mbsc-textfield-outline {
      background: $input-background;
    }

    &.mbsc-textfield-outline {
      border-color: $border-color;
    }

    /* Icon */

    &.mbsc-textfield-icon,
    &.mbsc-select-icon {
      color: $textfield-icon-color;
    }

    /* Error */

    &.mbsc-textfield-outline.mbsc-error,
    &.mbsc-textfield-box.mbsc-error {
      border-color: $error;
    }

    &.mbsc-error-message {
      color: $error;
    }

    /* Select */

    &.mbsc-select {
      background: $input-background;
    }

    /* Textarea */

    &.mbsc-textarea-inner.mbsc-textfield-inner-box,
    &.mbsc-textarea-inner.mbsc-textfield-inner-outline {
      background: $input-background;
    }

    &.mbsc-textarea-inner.mbsc-textfield-inner-outline {
      border-color: $border-color;
    }

    &.mbsc-textarea-inner.mbsc-error {
      border-color: $error;
    }

    /* Inside popup */

    @if ($is-dark) {
      &.mbsc-popup &.mbsc-textfield-wrapper {
        background: none;
      }

      &.mbsc-popup &.mbsc-select,
      &.mbsc-popup &.mbsc-textfield-wrapper-underline,
      &.mbsc-popup &.mbsc-textfield-box,
      &.mbsc-popup &.mbsc-textfield-outline,
      &.mbsc-popup &.mbsc-textarea-inner.mbsc-textfield-inner-box,
      &.mbsc-popup &.mbsc-textarea-inner.mbsc-textfield-inner-outline {
        background: $input-background-popup;
      }
    }

    /* Input tags */

    &.mbsc-textfield-tag {
      background: $input-tag-background;
    }

    &.mbsc-textfield-tag-clear {
      color: $textfield-icon-color;
    }
  }
}
