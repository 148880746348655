@import '../../themes/ios';

$mbsc-ios-button-color: $mbsc-button-color-light !default;
$mbsc-ios-button-text: $mbsc-button-text-light !default;

$mbsc-ios-dark-button-color: $mbsc-button-color-dark !default;
$mbsc-ios-dark-button-text: $mbsc-button-text-dark !default;

$mbsc-ios-colors: map-merge(
  $mbsc-ios-colors,
  (
    'button-color': $mbsc-ios-button-color,
    'button-text': $mbsc-ios-button-text,
  )
);

$mbsc-ios-dark-colors: map-merge(
  $mbsc-ios-dark-colors,
  (
    'button-color': $mbsc-ios-dark-button-color,
    'button-text': $mbsc-ios-dark-button-text,
  )
);

@mixin mbsc-ios-button($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $button-background-param: map-get($params, 'button-color');
  $button-text-param: map-get($params, 'button-text');

  $white: #fff;
  $black: #000;
  $button-background: '';
  $background-contrast: '';

  @if (lightness($background) > 50%) {
    $button-background: lighten($background, 10%);
    $background-contrast: #000;
  } @else {
    $button-background: lighten($background, 23%);
    $background-contrast: #fff;
  }

  $button-background: if($button-background-param, $button-background-param, $button-background);
  $button-text: if($button-text-param, $button-text-param, $accent);

  $form-background: $background;
  // The $form-background is used for the active text color of outlined buttons.
  // Until we'll have the form component specify this, we'll default to the $background.
  // @if($button-background-param) {
  //   @if(lightness($button-background-param) > 50%) {
  //     $form-background: adjust-hue(darken(saturate($button-background-param, 19%), 12%), 240deg);
  //   }
  //   @else {
  //     $form-background: adjust-hue(lighten(desaturate($button-background-param, 19%), 10%), 240deg);
  //   }
  // }
  // @else {
  //   @if(lightness($background) > 50%) {
  //     $form-background: adjust-hue(darken(saturate($background, 19%), 2%), 240deg);
  //   }
  //   @else {
  //     $form-background: adjust-hue(lighten(desaturate($background, 19%), 10%), 240deg);
  //   }
  // }

  $form-selection: '';

  // Light button
  @if (lightness($accent) > 50%) {
    $form-selection: lighten(saturate($accent, 15%), 3%);
  }

  // Dark button
  @else {
    $form-selection: darken(desaturate($accent, 15%), 3%);
  }

  $form-selection: if($button-background-param, $button-background-param, $form-selection);
  $flat-color: if($button-background-param, $button-background-param, $accent);

  .mbsc-#{$theme} {
    &.mbsc-button-standard {
      background: $button-background;
      color: $button-text;
    }

    // &.mbsc-button:disabled {
    //   background: $disabled-background;
    //   color: $disabled-color;
    // }

    /* Flat buttons */
    &.mbsc-button-flat {
      color: $flat-color;
    }

    /* Outline buttons */
    &.mbsc-button-outline {
      border: 1px solid $form-selection;
      color: $form-selection;

      &.mbsc-active {
        background: $form-selection;
        color: $background;
      }
    }

    &.mbsc-button.mbsc-focus {
      background: rgba($background-contrast, 0.05);
      // box-shadow: 0 0 0 2px rgba($text, .3);
    }

    /* Predefined colors */
    &.mbsc-button-primary.mbsc-button-standard {
      background: $mbsc-ios-primary;
      color: $white;
    }

    &.mbsc-button-secondary.mbsc-button-standard {
      background: $mbsc-ios-secondary;
      color: $white;
    }

    &.mbsc-button-success.mbsc-button-standard {
      background: $mbsc-ios-success;
      color: $white;
    }

    &.mbsc-button-danger.mbsc-button-standard {
      background: $mbsc-ios-danger;
      color: $white;
    }

    &.mbsc-button-warning.mbsc-button-standard {
      background: $mbsc-ios-warning;
      color: $white;
    }

    &.mbsc-button-info.mbsc-button-standard {
      background: $mbsc-ios-info;
      color: $white;
    }

    &.mbsc-button-dark.mbsc-button-standard {
      background: $mbsc-ios-dark;
      color: $white;
    }

    &.mbsc-button-light.mbsc-button-standard {
      background: $mbsc-ios-light;
      color: $black;
    }

    &.mbsc-button-primary.mbsc-button-flat {
      color: $mbsc-ios-primary;
    }

    &.mbsc-button-secondary.mbsc-button-flat {
      color: $mbsc-ios-secondary;
    }

    &.mbsc-button-success.mbsc-button-flat {
      color: $mbsc-ios-success;
    }

    &.mbsc-button-danger.mbsc-button-flat {
      color: $mbsc-ios-danger;
    }

    &.mbsc-button-warning.mbsc-button-flat {
      color: $mbsc-ios-warning;
    }

    &.mbsc-button-info.mbsc-button-flat {
      color: $mbsc-ios-info;
    }

    &.mbsc-button-dark.mbsc-button-flat {
      color: $mbsc-ios-dark;
    }

    &.mbsc-button-light.mbsc-button-flat {
      color: darken($mbsc-ios-light, 20%);
    }

    &.mbsc-button-primary.mbsc-button-outline {
      border-color: $mbsc-ios-primary;
      color: $mbsc-ios-primary;

      &.mbsc-active {
        background: $mbsc-ios-primary;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-secondary.mbsc-button-outline {
      border-color: $mbsc-ios-secondary;
      color: $mbsc-ios-secondary;

      &.mbsc-active {
        background: $mbsc-ios-secondary;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-success.mbsc-button-outline {
      border-color: $mbsc-ios-success;
      color: $mbsc-ios-success;

      &.mbsc-active {
        background: $mbsc-ios-success;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-danger.mbsc-button-outline {
      border-color: $mbsc-ios-danger;
      color: $mbsc-ios-danger;

      &.mbsc-active {
        background: $mbsc-ios-danger;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-warning.mbsc-button-outline {
      border-color: $mbsc-ios-warning;
      color: $mbsc-ios-warning;

      &.mbsc-active {
        background: $mbsc-ios-warning;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-info.mbsc-button-outline {
      border-color: $mbsc-ios-info;
      color: $mbsc-ios-info;

      &.mbsc-active {
        background: $mbsc-ios-info;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-dark.mbsc-button-outline {
      border-color: $mbsc-ios-dark;
      color: $mbsc-ios-dark;

      &.mbsc-active {
        background: $mbsc-ios-dark;
        color: $mbsc-ios-light;
      }
    }

    &.mbsc-button-light.mbsc-button-outline {
      border-color: darken($mbsc-ios-light, 25%);
      color: darken($mbsc-ios-light, 25%);

      &.mbsc-active {
        background: darken($mbsc-ios-light, 25%);
        color: $mbsc-ios-light;
      }
    }
  }
}
