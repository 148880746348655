@import '../../../themes/common';

@if $mbsc-eventcalendar {
  .mbsc-schedule-grid-wrapper {
    overflow: hidden;
    position: relative;
  }

  .mbsc-timeline-resource-col,
  .mbsc-timeline-date-col,
  .mbsc-timeline-sidebar-col,
  .mbsc-schedule-time-col {
    flex: none;
    width: 4.25em;
    box-sizing: border-box;
  }

  /* Schedule grid */

  .mbsc-timeline-grid-scroll,
  .mbsc-schedule-grid-scroll {
    overflow-x: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  /* Borders */

  .mbsc-schedule-all-day-item::after,
  .mbsc-timeline-day-border::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
  }

  .mbsc-schedule-all-day-item.mbsc-ltr::after,
  .mbsc-timeline-day-border.mbsc-ltr::after {
    left: 100%;
    border-right-style: solid;
    border-right-width: 1px;
  }

  .mbsc-schedule-all-day-item.mbsc-rtl::after,
  .mbsc-timeline-day-border.mbsc-rtl::after {
    right: 100%;
    border-left-style: solid;
    border-left-width: 1px;
  }

  .mbsc-schedule-column.mbsc-ltr,
  .mbsc-timeline-slot-header.mbsc-rtl,
  .mbsc-timeline-header-date.mbsc-rtl,
  .mbsc-timeline-header-column.mbsc-rtl,
  .mbsc-timeline-resource.mbsc-rtl,
  .mbsc-timeline-resource-header-cont.mbsc-rtl,
  .mbsc-timeline-resource-footer-cont.mbsc-rtl,
  .mbsc-timeline-column.mbsc-rtl,
  .mbsc-timeline-sidebar-resource.mbsc-ltr,
  .mbsc-timeline-sidebar-header-cont.mbsc-ltr,
  .mbsc-timeline-sidebar-footer-cont.mbsc-ltr,
  .mbsc-timeline-footer-date.mbsc-rtl,
  .mbsc-timeline-row-date.mbsc-rtl {
    border-left-style: solid;
    border-left-width: 1px;
  }

  .mbsc-schedule-column.mbsc-rtl,
  .mbsc-timeline-slot-header.mbsc-ltr,
  .mbsc-timeline-header-date.mbsc-ltr,
  .mbsc-timeline-header-column.mbsc-ltr,
  .mbsc-timeline-resource.mbsc-ltr,
  .mbsc-timeline-resource-header-cont.mbsc-ltr,
  .mbsc-timeline-resource-footer-cont.mbsc-ltr,
  .mbsc-timeline-column.mbsc-ltr,
  .mbsc-timeline-sidebar-resource.mbsc-rtl,
  .mbsc-timeline-sidebar-header-cont.mbsc-rtl,
  .mbsc-timeline-sidebar-footer-cont.mbsc-rtl,
  .mbsc-timeline-footer-date.mbsc-ltr,
  .mbsc-timeline-row-date.mbsc-ltr {
    border-right-style: solid;
    border-right-width: 1px;
  }

  /* Schedule event */

  .mbsc-schedule-event:focus {
    outline: none;
  }

  .mbsc-schedule-event {
    position: absolute;
    padding: 1px 0;
    box-sizing: border-box;
    min-height: 0.75em;
    z-index: 1;
  }

  .mbsc-schedule-event.mbsc-ltr {
    left: 0;
  }

  .mbsc-schedule-event.mbsc-rtl {
    right: 0;
  }

  .mbsc-schedule-event-hover {
    cursor: pointer;
  }

  .mbsc-schedule-event-inner {
    position: relative;
    max-height: 100%;
    margin: 0 0.2em;
    line-height: 1.5em;
    font-size: 0.625em;
    padding: 0.5em;
    box-sizing: border-box;
    overflow: hidden;
  }

  .mbsc-schedule-event-more {
    .mbsc-schedule-event-inner {
      padding: 0.5em 0.2em;
      text-align: center;
    }
  }

  .mbsc-schedule-event-small-height.mbsc-schedule-event-inner {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1.2em;
    margin-top: -1px;
  }

  .mbsc-schedule-event-small-height.mbsc-schedule-event-inner .mbsc-schedule-event-title {
    font-size: 1em;
  }

  .mbsc-schedule-event-background {
    position: absolute;
    top: -0.5em;
    left: 1px;
    right: 1px;
    bottom: -0.5em;
    background: currentColor;
  }

  .mbsc-schedule-event-start {
    .mbsc-schedule-event-background {
      top: 1px;
    }
  }

  .mbsc-schedule-event-end {
    .mbsc-schedule-event-background {
      bottom: 1px;
    }
  }

  .mbsc-schedule-event-range {
    font-size: 0.8em;
  }

  .mbsc-schedule-event-all-day {
    position: relative;
    display: block;
    padding: 0 0.25em;
    margin: 0.125em 0;
    min-height: 0;
    box-sizing: border-box;
    z-index: 2;
  }

  .mbsc-schedule-event-all-day-placeholder {
    z-index: -1;
  }

  .mbsc-schedule-event-all-day-inner {
    position: relative;
    height: 1.6em;
    line-height: 1.6em;
    margin: 0;
    padding: 0 0.3em;
    font-size: 0.625em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .mbsc-schedule-event-all-day-background.mbsc-schedule-event-background {
    top: 0;
    bottom: 0;
  }

  /* Event Drag & drop */

  .mbsc-schedule-event-dragging {
    position: absolute;
    top: 0;
    z-index: 3;
  }

  .mbsc-schedule-event-inactive {
    opacity: 0.5;
  }

  .mbsc-schedule-event-hidden {
    visibility: hidden;
  }

  /* Event resize handles */

  .mbsc-schedule-event-resize {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    height: 0.25em;
    z-index: 1;
    cursor: ns-resize;
  }

  .mbsc-schedule-event-resize-start {
    top: 0;
  }

  .mbsc-schedule-event-resize-end {
    bottom: 0;
  }

  .mbsc-schedule-event-resize-start:after {
    top: -0.25em;
    right: 0.5em;
  }

  .mbsc-schedule-event-resize-end:after {
    bottom: -0.25em;
    left: 0.5em;
  }

  .mbsc-schedule-event-resize:after {
    content: '';
    position: absolute;
    margin: 1px 0;
    width: 0.5em;
    height: 0.5em;
    background: #fff;
    border-radius: 50%;
    border: 1px solid currentColor;
    box-sizing: border-box;
  }

  .mbsc-schedule-event-resize-start-touch:before,
  .mbsc-schedule-event-resize-end-touch:before {
    content: '';
    position: absolute;
    margin: 1px 0;
    width: 1.5em;
    height: 1em;
  }

  .mbsc-schedule-event-resize-start-touch:before {
    top: -0.5em;
    right: 0;
  }

  .mbsc-schedule-event-resize-end-touch:before {
    bottom: -0.5em;
    left: 0;
  }

  // .mbsc-schedule-event-active,
  .mbsc-schedule-event-dragging,
  .mbsc-schedule-event-hover {
    .mbsc-schedule-event-resize {
      display: block;
    }
  }

  .mbsc-schedule-drag-clone {
    max-width: 200px;
    max-height: 200px;
    opacity: 1;

    .mbsc-schedule-event-resize {
      display: none;
    }
  }

  /* Event buffer */

  .mbsc-schedule-event-buffer-background {
    opacity: 0.3;
    background: currentColor;
  }

  /* Blocking and colored date styles */

  .mbsc-schedule-invalid,
  .mbsc-schedule-color {
    position: absolute;
    top: -0.5em;
    bottom: -0.5em;
    left: 0;
    right: 0;
    box-sizing: content-box;
    z-index: 0;
    overflow: hidden;
  }

  .mbsc-schedule-invalid-all-day,
  .mbsc-schedule-color-all-day {
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .mbsc-schedule-invalid-start,
  .mbsc-schedule-color-start {
    padding-top: 0.5em;
    margin-top: -0.5em;
  }

  .mbsc-schedule-invalid-end,
  .mbsc-schedule-color-end {
    padding-bottom: 0.5em;
  }

  .mbsc-schedule-invalid-text,
  .mbsc-schedule-color-text {
    padding: 0.375em;
    font-size: 0.75em;
  }

  .mbsc-schedule-color-text {
    opacity: 0.8;
  }

  /* Time indicator */

  .mbsc-schedule-time-indicator {
    position: absolute;
    z-index: 2;
    pointer-events: none;
  }

  .mbsc-schedule-time-indicator-x {
    border-bottom: 1px solid;
  }

  .mbsc-schedule-time-indicator-x.mbsc-ltr {
    left: 4.25em;
    right: 0;
  }

  .mbsc-schedule-time-indicator-x.mbsc-rtl {
    right: 4.25em;
    left: 0;
  }

  .mbsc-schedule-time-indicator-y {
    border-left: 1px solid;
    top: 2em;
    bottom: 0;
    margin-top: -0.25em;

    &.mbsc-ltr {
      margin-left: -1px;
    }

    &.mbsc-rtl {
      margin-right: -1px;
    }
  }

  .mbsc-schedule-time-indicator-day {
    position: absolute;
    width: 14.285714%;
  }

  .mbsc-schedule-time-indicator-day:before {
    content: '';
    position: absolute;
    width: 0.4375em;
    height: 0.4375em;
    margin-top: -0.1875em;
    margin-left: -0.1875em;
    margin-right: -0.1875em;
    border-radius: 50%;
    z-index: 1;
  }

  .mbsc-schedule-time-indicator-day.mbsc-ltr:before {
    left: 0;
  }

  .mbsc-schedule-time-indicator-day.mbsc-rtl:before {
    right: 0;
  }

  .mbsc-schedule-time-indicator-time {
    position: absolute;
    top: -1em;
    font-size: 0.625em;
    line-height: 2em;
    margin-top: 1px;
    padding: 0 0.8em;
    white-space: nowrap;
  }

  .mbsc-schedule-time-indicator-time-y {
    top: -1.8em;
    padding: 0 0.5em;
    margin-left: -0.5em;
    margin-right: -0.5em;
    line-height: 1.6em;
  }

  .mbsc-schedule-time-indicator-time-x.mbsc-ltr,
  .mbsc-schedule-time-indicator-time-y.mbsc-rtl {
    right: 100%;
  }

  .mbsc-schedule-time-indicator-time-x.mbsc-rtl,
  .mbsc-schedule-time-indicator-time-y.mbsc-ltr {
    left: 100%;
  }

  /* Cursor indicator */

  .mbsc-schedule-cursor-time {
    position: absolute;
    z-index: 3;
    font-size: 0.625em;
    line-height: 2em;
    white-space: nowrap;
    box-sizing: border-box;
  }

  .mbsc-schedule-cursor-time-x {
    margin-top: -1em;

    &.mbsc-ltr {
      text-align: right;
    }

    &.mbsc-rtl {
      text-align: left;
    }
  }

  .mbsc-schedule-cursor-time-y {
    padding: 0 0.5em;
    top: 0.8em;
  }

  @supports (overflow: clip) {
    .mbsc-schedule-event-inner {
      position: sticky;
      top: 0;
    }
  }
}
