@import '../../../themes/common';

@if $mbsc-eventcalendar {
  .mbsc-timeline {
    overflow: hidden;
    position: relative;
    z-index: 0;

    &.mbsc-ltr {
      margin-right: -1px;
    }

    &.mbsc-rtl {
      margin-left: -1px;
    }
  }

  /* Resource column */

  .mbsc-timeline-resource-col,
  .mbsc-timeline-sidebar-col {
    width: 12em;
  }

  .mbsc-timeline-resources,
  .mbsc-timeline-sidebar {
    position: absolute;
    top: 0;
    bottom: 15px;
    z-index: 5;
    overflow: hidden;
  }

  .mbsc-timeline-resources.mbsc-ltr,
  .mbsc-timeline-sidebar.mbsc-rtl {
    left: 0;
  }

  .mbsc-timeline-resources.mbsc-rtl,
  .mbsc-timeline-sidebar.mbsc-ltr {
    right: 0;
  }

  .mbsc-timeline-resource-header-cont,
  .mbsc-timeline-resource-footer-cont,
  .mbsc-timeline-sidebar-header-cont,
  .mbsc-timeline-sidebar-footer-cont {
    position: relative;
    z-index: 5;
    overflow: hidden;
    pointer-events: auto;
  }

  .mbsc-timeline-resource,
  .mbsc-timeline-sidebar-resource {
    box-sizing: border-box;
    overflow: hidden;
  }

  /* Hierarchy */

  .mbsc-timeline-parent {
    background: rgba(#e6e6e6, 0.25);
  }

  .mbsc-timeline-resource-icon {
    font-size: 0.875em;
    font-weight: 600;
    margin: 0.357143em 0;
    cursor: pointer;
    box-sizing: content-box;
  }

  .mbsc-timeline-resource-icon.mbsc-ltr {
    margin-left: 0.5em;
  }

  .mbsc-timeline-resource-icon.mbsc-rtl {
    margin-right: 0.5em;
  }

  .mbsc-timeline-resource-header,
  .mbsc-timeline-resource-footer,
  .mbsc-timeline-resource-title,
  .mbsc-timeline-sidebar-header,
  .mbsc-timeline-sidebar-resource-title,
  .mbsc-timeline-sidebar-footer {
    font-size: 0.875em;
    font-weight: 600;
    padding: 0.5em;
  }

  /* Header and footer */

  .mbsc-timeline-header,
  .mbsc-timeline-footer {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 5;
    overflow: hidden;
  }

  .mbsc-timeline-header {
    top: 0;
  }

  .mbsc-timeline-footer {
    bottom: 0;
    margin-top: -1px;
  }

  .mbsc-timeline-header-bg,
  .mbsc-timeline-footer-bg {
    position: relative;
  }

  .mbsc-timeline-day {
    position: relative;
    min-width: 9em;
    box-sizing: border-box;
  }

  .mbsc-timeline-day-month {
    min-width: 0;
  }

  // .mbsc-timeline-day:last-child {

  //   // border: 0;
  //   // margin: 0;
  //   &::after {
  //     border: 0;
  //   }

  //   .mbsc-timeline-header-column:last-child,
  //   .mbsc-timeline-header-date {
  //     border-left: 0;
  //     border-right: 0;
  //   }
  // }

  .mbsc-timeline-header-column.mbsc-timeline-no-border,
  .mbsc-timeline-column.mbsc-timeline-no-border {
    border-left: 0;
    border-right: 0;
  }

  .mbsc-timeline-month {
    min-width: 0;
    position: relative;
  }

  .mbsc-timeline-header-month,
  .mbsc-timeline-header-week {
    // height: 1.875em;
    min-height: 1.625em;
    box-sizing: border-box;
  }

  .mbsc-timeline-footer-date,
  .mbsc-timeline-header-date {
    min-height: 1.625em;
    box-sizing: border-box;
  }

  .mbsc-timeline-header-month-last.mbsc-ltr,
  .mbsc-timeline-header-week-last.mbsc-ltr,
  .mbsc-timeline-footer-week-last.mbsc-ltr {
    border-right-width: 1px;
    border-right-style: solid;
  }

  .mbsc-timeline-header-month-last.mbsc-rtl,
  .mbsc-timeline-header-week-last.mbsc-rtl {
    border-left-width: 1px;
    border-left-style: solid;
  }

  .mbsc-timeline-header-month-text,
  .mbsc-timeline-header-date-text,
  .mbsc-timeline-header-week-text,
  .mbsc-timeline-footer-month-text,
  .mbsc-timeline-footer-week-text {
    font-size: 0.875em; // 14px
    padding: 0 0.285714em;
    line-height: 1.785715em; // 25px
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .mbsc-timeline-header-month-text {
    font-weight: bold;
  }

  .mbsc-timeline-header-date-cont,
  .mbsc-timeline-header-week-cont,
  .mbsc-timeline-header-month-cont,
  .mbsc-timeline-header-year-cont,
  .mbsc-timeline-header-date-text,
  .mbsc-timeline-header-month-text-last,
  .mbsc-timeline-header-week-text-last {
    max-width: 100%;
    overflow: hidden;
  }

  .mbsc-timeline-header-month-cont,
  .mbsc-timeline-header-week-cont {
    white-space: nowrap;
  }

  .mbsc-timeline-header-active {
    position: relative;
    z-index: 0;
    padding: 0 0.714286em;

    &::after {
      content: '';
      position: absolute;
      top: 0.142857em;
      left: 0.142857em;
      right: 0.142857em;
      bottom: 0.142857em;
      border-radius: 0.75em;
      z-index: -1;
    }
  }

  .mbsc-timeline-header-column,
  .mbsc-timeline-footer-column {
    min-height: 1.625em;
    overflow: hidden;
  }

  .mbsc-timeline-header-time,
  .mbsc-timeline-footer-time {
    padding: 0 0.5em;
    font-size: 0.625em;
    min-width: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 2.6em;
  }

  .mbsc-timeline .mbsc-timeline-time-indicator-cont {
    // Stronger rule is needed for box-sizing, if placed inside mbsc-grid
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100%;
    overflow: hidden;
    padding-top: 2em;
    margin-top: -2em;
    pointer-events: none;
    box-sizing: content-box;
  }

  /* Sticky header */

  .mbsc-timeline-header-sticky {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 6;
    pointer-events: none;

    .mbsc-timeline-header-date {
      border-left: 0;
      border-right: 0;
    }
  }

  /* Sticky footer */

  .mbsc-timeline-footer-sticky {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 6;
    pointer-events: none;
  }

  /* Grid */

  .mbsc-timeline-column,
  .mbsc-timeline-header-column {
    width: 4.625em;
    box-sizing: border-box;
    position: relative;
  }

  .mbsc-timeline-grid-scroll {
    overflow-y: auto;
    // Needed for iOS Safari to don't change the z-index stacking context
    -webkit-overflow-scrolling: auto;
  }

  .mbsc-timeline-grid {
    position: relative;
    overflow: hidden;
  }

  .mbsc-timeline-row {
    height: 3.25em; // 52px
    position: relative;
    box-sizing: border-box;
  }

  .mbsc-timeline-row-fixed {
    position: sticky;
    z-index: 3;
  }

  .mbsc-timeline-hidden {
    visibility: hidden;
    position: absolute;
    left: 0;
    right: 0;
    height: 0;
    overflow: hidden;
  }

  .mbsc-timeline-row-group {
    position: relative;
  }

  .mbsc-timeline-row-group::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 1;
  }

  .mbsc-timeline-row-group:last-child::after {
    display: none;
  }

  .mbsc-timeline-row-date {
    box-sizing: border-box;

    .mbsc-timeline-header-date-text {
      white-space: normal;
      line-height: 1.285715em;
      padding-top: 0.285715em;
      padding-bottom: 0.285715em;
    }
  }

  .mbsc-timeline-row-resource-col {
    overflow: hidden;
  }

  .mbsc-timeline-row-date-col {
    width: 5.5em;
  }

  .mbsc-timeline-date-col {
    width: 8.75em;
  }

  .mbsc-timeline-row-gutter {
    height: 1em;
  }

  .mbsc-timeline-resource,
  .mbsc-timeline-resource-header-cont,
  .mbsc-timeline-sidebar-resource,
  .mbsc-timeline-sidebar-header-cont,
  .mbsc-timeline-header-date,
  .mbsc-timeline-header-month,
  .mbsc-timeline-header-week,
  .mbsc-timeline-header-column,
  .mbsc-timeline-row,
  .mbsc-timeline-row-date,
  .mbsc-timeline-row-group::after {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .mbsc-timeline-footer-date,
  .mbsc-timeline-footer-week,
  .mbsc-timeline-footer-column,
  .mbsc-timeline-resource-footer-cont,
  .mbsc-timeline-sidebar-footer-cont {
    border-top-style: solid;
    border-top-width: 1px;
  }

  /* Events */

  .mbsc-timeline-events {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 1em;
  }

  .mbsc-timeline-event {
    z-index: 2;
    min-width: 0.75em;
    padding: 6px 0 0 0;

    &.mbsc-schedule-event-all-day {
      padding-left: 0.25em;
      padding-right: 0.25em;
    }

    .mbsc-schedule-event-all-day-inner {
      font-size: 0.75em;
      height: 1.5em;
      line-height: 1.5em;
      padding: 0 0.333334em;
    }

    .mbsc-schedule-event-title,
    .mbsc-schedule-event-range {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .mbsc-timeline-event-background {
    margin-top: 6px;
    top: 0;
    bottom: 0;
  }

  .mbsc-timeline-event-resize {
    width: 0.25em;
    top: 6px;
    bottom: 0;
    right: auto;
    left: auto;
    cursor: ew-resize;

    &.mbsc-schedule-event-resize-start.mbsc-ltr,
    &.mbsc-schedule-event-resize-end.mbsc-rtl {
      height: auto;
      left: 0;
    }

    &.mbsc-schedule-event-resize-end.mbsc-ltr,
    &.mbsc-schedule-event-resize-start.mbsc-rtl {
      height: auto;
      right: 0;
    }

    &.mbsc-schedule-event-resize-end:after,
    &.mbsc-schedule-event-resize-start:after {
      top: 50%;
      margin-top: -0.25em;
    }

    &.mbsc-schedule-event-resize-end:after {
      left: -0.125em;
    }

    &.mbsc-schedule-event-resize-start:after {
      right: -0.125em;
    }

    &.mbsc-schedule-event-resize-start-touch:before,
    &.mbsc-schedule-event-resize-end-touch:before {
      height: 1.5em;
      top: 50%;
      left: 50%;
      margin: -0.75em 0 0 -0.75em;
    }
  }

  .mbsc-timeline-event-listing {
    position: absolute;
    margin: 0;

    &.mbsc-timeline-event-dragging {
      position: absolute;
      top: 0;
    }
  }

  .mbsc-timeline-event-slot {
    position: relative;
    left: 0;
    right: 0;
    margin: 0 2px;
  }

  /* Event buffer */

  .mbsc-timeline-event-buffer {
    position: absolute;
    margin-top: 6px;
    top: 0;
    bottom: 0;
    box-sizing: content-box;
    z-index: -1;
  }

  .mbsc-timeline-event-buffer-after.mbsc-rtl,
  .mbsc-timeline-event-buffer-before.mbsc-ltr {
    right: 100%;
    margin-right: -0.25em;
    padding-right: 0.25em;
    border-top-left-radius: 0.25em;
    border-bottom-left-radius: 0.25em;
  }

  .mbsc-timeline-event-buffer-before.mbsc-rtl,
  .mbsc-timeline-event-buffer-after.mbsc-ltr {
    left: 100%;
    margin-left: -0.25em;
    padding-left: 0.25em;
    border-top-right-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
  }

  /* Invalid, color */

  .mbsc-timeline-color,
  .mbsc-timeline-invalid {
    top: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
  }

  /* No time displayed */

  .mbsc-timeline-header-column.mbsc-timeline-no-height,
  .mbsc-timeline-footer-column.mbsc-timeline-no-height {
    border: 0;
    height: 0;
    min-height: 0;
  }

  /* Connections */

  .mbsc-connections {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
  }

  .mbsc-connection {
    stroke-width: 1.5px;
    fill: none;
    pointer-events: visibleStroke;
  }

  /* Slots */

  .mbsc-timeline-slots {
    height: 1.625em;
    box-sizing: border-box;
    border-bottom: 1px solid;
  }

  .mbsc-timeline-slot {
    position: relative;
    width: 8em;
    box-sizing: border-box;
  }

  .mbsc-timeline-slot-title {
    font-size: 0.875em;
    font-weight: 600;
    line-height: 1.785715em; // 25px
    padding: 0 0.285714em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Sticky positioning */

  // @supports (position:sticky) {
  .mbsc-has-sticky {
    /* Needed for Safari, otherwise the scrollbar appears behind the sticky elements */
    .mbsc-timeline-grid-scroll.mbsc-ltr {
      position: relative;
    }

    .mbsc-timeline-header,
    .mbsc-timeline-footer {
      position: sticky;
      left: auto;
      right: auto;
      overflow: visible;
    }

    .mbsc-timeline-resources.mbsc-ltr,
    .mbsc-timeline-sidebar.mbsc-ltr {
      position: sticky;
      top: auto;
      bottom: auto;
      overflow: visible;
    }
  }

  @supports (overflow: clip) {
    .mbsc-timeline-grid {
      overflow: clip;
    }

    .mbsc-timeline .mbsc-schedule-event-inner {
      display: inline-block;
      max-width: 100%;
      vertical-align: top;
    }

    .mbsc-timeline.mbsc-ltr .mbsc-schedule-event-inner {
      left: 192px;
    }

    .mbsc-timeline.mbsc-rtl .mbsc-schedule-event-inner {
      right: 192px;
    }

    .mbsc-timeline-no-resource.mbsc-ltr .mbsc-schedule-event-inner {
      left: 140px;
    }

    .mbsc-timeline-no-resource.mbsc-rtl .mbsc-schedule-event-inner {
      right: 140px;
    }

    .mbsc-timeline-event-slot .mbsc-schedule-event-inner {
      position: relative;
      display: block;
    }

    .mbsc-timeline .mbsc-timeline-event-slot .mbsc-schedule-event-inner,
    .mbsc-timeline.mbsc-timeline-no-rows .mbsc-schedule-event-inner {
      left: 0;
      right: 0;
    }
  }
}
