@import '../../themes/ios';

@mixin mbsc-ios-eventcalendar($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $background-param: map-get($params, 'calendar-background');
  $text-param: map-get($params, 'calendar-text');
  $accent-param: map-get($params, 'calendar-accent');
  $border-param: map-get($params, 'calendar-border');
  $event-param: map-get($params, 'calendar-event');

  $background: if($background-param, $background-param, $background);
  $text: if($text-param, $text-param, if($background-param, get-contrast-color($background), $text));
  $accent: if($accent-param, $accent-param, $accent);
  $event: if($event-param, $event-param, #5ac8fa);

  $is-dark: false;
  $background-alt: '';
  $background-contrast: '';
  $background-header: '';
  $border-color: '';
  $title-color: '';
  $schedule-time-color: '';
  $cell-disabled-bg: '';
  $cell-disabled-color: '';

  // Light background
  @if (lightness($background) > 50%) {
    $background-alt: lighten($background, 3%);
    $background-contrast: #000;
    $background-header: $background;
    $border-color: darken($background, 17%);
    $schedule-time-color: lighten($mbsc-ios-text, 54.9);
    $title-color: adjust-hue(lighten(saturate($text, 2%), 44%), 240deg);
    $cell-disabled-bg: darken($background-alt, 6.27);
    $cell-disabled-color: darken($cell-disabled-bg, 40.39);
  }

  // Dark background
  @else {
    $is-dark: true;
    $background-alt: $background;
    $background-contrast: #fff;
    $background-header: lighten($background, 11%);
    $border-color: lighten($background, 20%);
    $schedule-time-color: $mbsc-ios-dark-text;
    $title-color: adjust-hue(darken(desaturate($text, 2%), 44%), 240deg);
    $cell-disabled-bg: lighten($background-alt, 10);
    $cell-disabled-color: lighten($cell-disabled-bg, 37.25);
  }

  $border-color: if($border-param, $border-param, $border-color);

  .mbsc-#{$theme} {
    /* Calendar view */
    &.mbsc-eventcalendar {
      .mbsc-calendar-header,
      .mbsc-calendar-week-days {
        background: $background-header;
      }

      .mbsc-calendar-day.mbsc-disabled {
        background: $cell-disabled-bg;
      }
    }

    /* Agenda view */

    &.mbsc-event-list-empty {
      color: $title-color;
    }

    &.mbsc-event-day.mbsc-list-header {
      background: $background-alt;
      border-color: $border-color;
      color: $text;
    }

    &.mbsc-event.mbsc-list-item {
      background: $background-alt;
      color: $text;
    }

    &.mbsc-event.mbsc-list-item:before,
    &.mbsc-event.mbsc-list-item:after {
      border-color: $border-color;
    }

    &.mbsc-event.mbsc-list-item.mbsc-hover:before {
      background: rgba($background-contrast, 0.05);
    }

    &.mbsc-event.mbsc-list-item.mbsc-focus .mbsc-list-item-background,
    &.mbsc-event.mbsc-list-item.mbsc-selected .mbsc-list-item-background {
      background: rgba($background-contrast, 0.15);
    }

    &.mbsc-event-color {
      background: $event;
    }

    /* Popover events */

    @if ($is-dark) {
      &.mbsc-popover-list &.mbsc-event {
        background: lighten($background, 17%);
      }
    }

    /* Schedule view */

    /* TODO: refactor this without cascade */
    &.mbsc-eventcalendar-schedule .mbsc-calendar-day {
      background: $background-header;
    }

    &.mbsc-schedule-wrapper {
      background: $background-header;
    }

    /* Header */

    &.mbsc-schedule-header,
    &.mbsc-schedule-header-item {
      border-color: $border-color;
    }

    &.mbsc-schedule-header-day-today {
      color: $accent;
    }

    &.mbsc-schedule-header-day.mbsc-hover {
      background: rgba($accent, 0.3);
    }

    &.mbsc-schedule-header-dayname-curr,
    &.mbsc-schedule-header-day.mbsc-selected {
      color: $accent;
    }

    &.mbsc-schedule-header-day.mbsc-selected {
      background: $accent;
      color: $background-alt;
    }

    &.mbsc-schedule-header-dayname-curr {
      color: $text;
    }

    &.mbsc-schedule-date-header {
      background: $background-header;
      border-color: $border-color;
    }

    /* Grid & All-day row */
    &.mbsc-schedule-grid-wrapper {
      background: $background-alt;
    }

    &.mbsc-schedule-all-day-wrapper {
      border-color: $border-color;
      background: $background-alt;
    }

    &.mbsc-schedule-all-day-text,
    &.mbsc-schedule-timezone-label {
      color: $schedule-time-color;
    }

    &.mbsc-timeline-day::after,
    &.mbsc-timeline-slots,
    &.mbsc-timeline-slot-header,
    &.mbsc-timeline-header-month,
    &.mbsc-timeline-header-week,
    &.mbsc-timeline-footer-week,
    &.mbsc-timeline-header-date,
    &.mbsc-timeline-header-column,
    &.mbsc-timeline-header,
    &.mbsc-timeline-footer-date,
    &.mbsc-timeline-footer,
    &.mbsc-timeline-footer-column,
    &.mbsc-timeline-resource,
    &.mbsc-timeline-resource-header-cont,
    &.mbsc-timeline-resource-footer-cont,
    &.mbsc-timeline-sidebar-resource,
    &.mbsc-timeline-sidebar-header-cont,
    &.mbsc-timeline-sidebar-footer-cont,
    &.mbsc-timeline-column,
    &.mbsc-timeline-row,
    &.mbsc-schedule-resource-group,
    &.mbsc-schedule-resource,
    &.mbsc-schedule-all-day-item::after,
    &.mbsc-schedule-column,
    &.mbsc-schedule-item,
    &.mbsc-timeline-row-group::after,
    &.mbsc-timeline-row-date {
      border-color: $border-color;
    }

    &.mbsc-timeline-row-fixed {
      box-shadow: 0 1px 0 0 $border-color;
    }

    &.mbsc-timeline-header-column,
    &.mbsc-schedule-cursor-time,
    &.mbsc-schedule-time {
      color: $schedule-time-color;
    }

    /* Event style */

    &.mbsc-schedule-event {
      color: $event;
    }

    &.mbsc-schedule-event:after {
      background: rgba($background-alt, 0.9);
    }

    &.mbsc-schedule-event-title,
    &.mbsc-schedule-event-range {
      color: $background-contrast;
    }

    &.mbsc-schedule-event-active,
    &.mbsc-schedule-event-dragging {
      .mbsc-schedule-event-inner {
        color: get-contrast-color($event);
      }

      .mbsc-schedule-event-title,
      .mbsc-schedule-event-range {
        color: inherit;
      }
    }

    &.mbsc-schedule-time-indicator {
      border-color: $accent;
    }

    &.mbsc-schedule-time-indicator-day:before {
      background: $accent;
    }

    &.mbsc-schedule-time-indicator-time {
      background: rgba($background-alt, 0.8);
    }

    &.mbsc-schedule-time-indicator-time {
      color: $accent;
    }

    &.mbsc-schedule-cursor-time {
      background: rgba($background-alt, 0.8);
      color: $accent;
    }

    /* Invalid */

    &.mbsc-schedule-invalid {
      background: rgba($cell-disabled-bg, 0.75);
      color: $cell-disabled-color;
    }

    /* Timeline */

    &.mbsc-timeline-header-bg,
    &.mbsc-timeline-header-text,
    &.mbsc-timeline-footer-bg,
    &.mbsc-timeline-resource-bg,
    &.mbsc-timeline-resource-header-cont,
    &.mbsc-timeline-resource-footer-cont,
    &.mbsc-timeline-row-fixed,
    &.mbsc-timeline-sidebar-header-cont,
    &.mbsc-timeline-sidebar-footer-cont {
      background: $background-alt;
    }

    &.mbsc-timeline-header-active {
      color: get-contrast-color($accent);

      &::after {
        background-color: $accent;
      }
    }

    /* Connections */

    &.mbsc-connection {
      stroke: rgba($text, 0.7);
    }

    &.mbsc-connection-arrow {
      fill: $text;
    }
  }
}
