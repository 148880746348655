@import './datetime.ios.colors';

@if ($mbsc-ios-theme and $mbsc-datepicker) {
  .mbsc-ios {
    &.mbsc-datetime-year-wheel .mbsc-scroller-wheel-item {
      min-width: 3.8em;
    }

    &.mbsc-datetime-month-wheel .mbsc-scroller-wheel-item {
      text-align: left;
    }

    &.mbsc-datetime-day-wheel .mbsc-scroller-wheel-item,
    &.mbsc-datetime-hour-wheel .mbsc-scroller-wheel-item,
    &.mbsc-datetime-date-wheel .mbsc-scroller-wheel-item {
      text-align: right;
    }
  }
}
