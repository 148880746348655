@import './notification.ios.colors';

@if ($mbsc-ios-theme and $mbsc-forms) {
  .mbsc-ios {
    .mbsc-toast-message {
      line-height: 1.857143em; // 26px / 14px based
      border-radius: 1.785715em; // 25px / 14px based
    }

    .mbsc-alert-title {
      margin: 0.277778em 0;
      font-size: 1.125em;
      text-align: center;
    }

    .mbsc-alert-message {
      margin: 0.357143em 0;
      font-size: 0.875em;
      text-align: center;
    }

    &.mbsc-prompt-input.mbsc-font {
      margin-top: 1em;
      border-radius: 0.5em;
      border-width: 1px;
      border-style: solid;
    }

    &.mbsc-prompt-input::after,
    &.mbsc-prompt-input::before {
      display: none;
    }

    &.mbsc-prompt-input .mbsc-textfield {
      height: 1.75em;
      line-height: 1.75em;
      padding: 0 0.25em;
    }

    &.mbsc-prompt-input .mbsc-label {
      height: 1.75em;
      line-height: 1.75em;
      padding-left: 0.25em;
    }

    &.mbsc-primary .mbsc-toast-background {
      background: $mbsc-ios-primary;
      color: get-contrast-color($mbsc-ios-primary);
    }

    &.mbsc-secondary .mbsc-toast-background {
      background: $mbsc-ios-secondary;
      color: get-contrast-color($mbsc-ios-secondary);
    }

    &.mbsc-success .mbsc-toast-background {
      background: $mbsc-ios-success;
      color: get-contrast-color($mbsc-ios-success);
    }

    &.mbsc-danger .mbsc-toast-background {
      background: $mbsc-ios-danger;
      color: get-contrast-color($mbsc-ios-danger);
    }

    &.mbsc-warning .mbsc-toast-background {
      background: $mbsc-ios-warning;
      color: get-contrast-color($mbsc-ios-warning);
    }

    &.mbsc-info .mbsc-toast-background {
      background: $mbsc-ios-info;
      color: get-contrast-color($mbsc-ios-info);
    }
  }

  @include mbsc-ios-notifications('ios', $mbsc-ios-colors);
  @include mbsc-ios-notifications('ios-dark', $mbsc-ios-dark-colors);
}
