@import '../../themes/ios';

@mixin mbsc-ios-popup($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $is-dark: false;
  $button-hover: '';
  $buttons-background: '';
  $popup-border: '';
  $popup-background: '';

  @if (lightness($background) > 50%) {
    $button-hover: darken($background, 5%);
    $buttons-background: $background; // darken($background, 3%);
    $popup-border: darken($background, 17%);
    $popup-background: adjust-hue(darken(saturate($background, 24%), 1%), 240deg);
  } @else {
    $is-dark: true;
    $button-hover: lighten($background, 14%);
    $buttons-background: lighten($background, 16%);
    $popup-border: lighten($background, 20%);
    $popup-background: lighten($background, 11%);
  }

  .mbsc-#{$theme} {
    &.mbsc-popup-arrow {
      background: $popup-background;
      // border-color: $popup-border;
      box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
    }

    &.mbsc-popup-top,
    &.mbsc-popup-bottom {
      border-color: $popup-border;
    }

    &.mbsc-popup-body {
      background: $popup-background;
      // border-color: $popup-border;
      color: $text;
    }

    &.mbsc-popup-body-round,
    &.mbsc-popup-body-center,
    &.mbsc-popup-body-anchored {
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    }

    &.mbsc-popup-header-center {
      border-color: $popup-border;
    }

    &.mbsc-popup-buttons,
    &.mbsc-popup-header-no-buttons {
      border-color: $popup-border;
    }

    &.mbsc-popup-buttons-bottom,
    &.mbsc-popup-buttons-top {
      background: $buttons-background;
    }

    @if ($is-dark) {
      &.mbsc-popup-buttons-anchored {
        background: $buttons-background;
      }
    }

    &.mbsc-popup-button-flex.mbsc-button-flat.mbsc-font {
      border-color: $popup-border;
    }

    &.mbsc-popup-button-flex.mbsc-font.mbsc-hover,
    &.mbsc-popup-button-flex.mbsc-font.mbsc-focus {
      background: $button-hover;
    }

    &.mbsc-popup-button-flex.mbsc-font.mbsc-active {
      background: $popup-border;
    }

    &.mbsc-popup-button-flex.mbsc-button.mbsc-disabled {
      color: rgba($accent, 0.2);
    }
  }
}
