@import './input.ios';
@import './input.material';
@import './input.windows';

@if $mbsc-input {
  // Dummy animation to detect initial autofill in webkit browsers
  @keyframes autofill {
    from {
      opacity: 1;
    }

    to {
      opacity: 1;
    }
  }

  /* Wrapper */

  .mbsc-textfield-wrapper {
    position: relative;
    display: block;
    margin: 0;
    z-index: 0;
    user-select: none;
  }

  .mbsc-textfield-wrapper-inline {
    display: flex;
    // align-items: center;
  }

  .mbsc-form-grid .mbsc-textfield-wrapper-box,
  .mbsc-form-grid .mbsc-textfield-wrapper-outline {
    margin: 0.75em 1em;
  }

  /* Inner wrapper */

  .mbsc-textfield-inner {
    box-sizing: border-box;
    position: relative;
    display: block;
  }

  .mbsc-textfield-inner-inline {
    flex: 1 auto;
  }

  /* Form element */

  .mbsc-textfield {
    display: block;
    width: 100%;
    font-size: 1em;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-family: inherit;
    box-sizing: border-box;
    appearance: none;
    -webkit-appearance: none;
  }

  .mbsc-textfield:-webkit-autofill {
    animation-name: autofill;
  }

  .mbsc-textfield::-webkit-inner-spin-button {
    height: 2em;
    align-self: center;
  }

  .mbsc-textfield::-moz-placeholder {
    opacity: 0.5;
    color: inherit;
  }

  .mbsc-textfield::-webkit-input-placeholder {
    opacity: 0.5;
    color: inherit;
  }

  /* Floating label */

  .mbsc-textfield-floating:-ms-input-placeholder {
    color: transparent;
  }

  .mbsc-textfield-floating::-moz-placeholder {
    opacity: 0;
    transition: opacity 0.2s;
  }

  .mbsc-textfield-floating::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 0.2s;
  }

  .mbsc-textfield-floating::-webkit-datetime-edit {
    color: transparent;
  }

  .mbsc-textfield-floating-active:-ms-input-placeholder {
    color: inherit;
  }

  .mbsc-textfield-floating-active::-moz-placeholder {
    opacity: 0.5;
  }

  .mbsc-textfield-floating-active::-webkit-input-placeholder {
    opacity: 0.5;
  }

  .mbsc-textfield-floating-active::-webkit-datetime-edit {
    color: inherit;
  }

  .mbsc-textfield-floating .mbsc-textfield-tags-placeholder {
    opacity: 0;
    transition: opacity 0.2s;
  }

  .mbsc-textfield-floating-active .mbsc-textfield-tags-placeholder {
    opacity: 0.5;
  }

  .mbsc-textfield-fieldset {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0 1em;
    pointer-events: none;
  }

  /* Icon */

  .mbsc-textfield-icon {
    position: absolute;
    top: 0;
    text-align: center;
  }

  .mbsc-textfield-icon-left {
    left: 0;
  }

  .mbsc-textfield-icon-right {
    right: 0;
  }

  /* Label */

  .mbsc-label {
    position: absolute;
    z-index: 1;
    top: 0;
    line-height: 1em;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
  }

  .mbsc-label-inline {
    position: static;
    overflow: hidden;
    flex: 0 0 auto;
    width: 30%;
    max-width: 12.5em;
    // box-sizing: content-box;
    box-sizing: border-box;
    pointer-events: auto;
  }

  .mbsc-label-floating-animate {
    transition: transform 0.2s;
  }

  .mbsc-label-floating.mbsc-ltr {
    transform-origin: top left;
  }

  .mbsc-label-floating.mbsc-rtl {
    transform-origin: top right;
  }

  /* Error message */

  .mbsc-error-message {
    position: absolute;
    top: 100%;
    font-size: 0.75em;
    line-height: 1.5em;
  }

  /* File input */

  .mbsc-textfield-file {
    position: absolute;
    left: 0;
    opacity: 0;
  }

  /* Select */

  .mbsc-select {
    cursor: pointer;
  }

  .mbsc-select-icon {
    position: absolute;
    text-align: center;
    pointer-events: none;
  }

  /* Textarea */

  .mbsc-textarea {
    resize: none;
    overflow: hidden;
    line-height: 1.5em;
  }

  /* Password toggle */

  .mbsc-toggle-icon:hover {
    cursor: pointer;
  }

  /* Input tags */

  .mbsc-textfield.mbsc-textfield-hidden.mbsc-textarea {
    padding: 0;
    width: 100%;
    height: 100%;
    border: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0;
  }

  .mbsc-textfield.mbsc-textfield-tags.mbsc-textarea {
    display: block;
    overflow: auto;
    height: auto;
    max-height: 5.5em;
  }

  .mbsc-textfield-tag {
    display: inline-block;
    margin: 0.25em 0;
    line-height: normal;
  }

  .mbsc-textfield-tag.mbsc-ltr {
    margin-right: 0.5em;
    padding-left: 0.75em;
  }

  .mbsc-textfield-tag.mbsc-rtl {
    margin-left: 0.5em;
    padding-right: 0.75em;
  }

  .mbsc-textfield-tag-text {
    font-size: 0.875em;
    line-height: 1.857143em; // 26px
  }

  .mbsc-textfield-tag-clear.mbsc-icon {
    width: 1.625em;
    height: 1.625em;
    vertical-align: top;
    border: 0.25em solid transparent;
    box-sizing: border-box;
    cursor: pointer;
  }

  .mbsc-textfield-tags-placeholder {
    opacity: 0.5;
    line-height: 2.125em;
  }
}
