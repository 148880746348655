@import './form-controls.ios';
@import './form-controls.material';
@import './form-controls.windows';

@if $mbsc-forms {
  .mbsc-form-control-label {
    display: block;
  }

  .mbsc-form-control-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 0;
    opacity: 0;
    margin: 0;
    z-index: 3;
  }
}
