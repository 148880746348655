@import './scroller.material';
@import './scroller.ios';
@import './scroller.windows';

@if $mbsc-scroller {
  .mbsc-scroller {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    text-align: center;
    user-select: none;
  }

  .mbsc-scroller-wheel-group-cont {
    position: relative;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
  }

  .mbsc-scroller-wheel-group-cont:first-child:last-child {
    display: block;
  }

  .mbsc-scroller-wheel-group {
    margin: 0 auto;
    position: relative;
    justify-content: center;
  }

  .mbsc-scroller-wheel-group-3d {
    perspective: 100em;
  }

  .mbsc-scroller-wheel-wrapper {
    display: block;
    max-width: 100%;
    position: relative;
    touch-action: none;
    /* Temp */
    min-width: 80px;
  }

  .mbsc-scroller-pointer .mbsc-scroller-wheel-wrapper-0:last-child {
    flex: 1 1 auto; // Expand single wheel in desktop mode
  }

  .mbsc-scroller-wheel-line {
    display: none;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    pointer-events: none;
    transform: translateY(-50%);
  }

  .mbsc-scroller-wheel-overlay {
    display: none;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }

  .mbsc-scroller-wheel {
    display: block;
    overflow: hidden;
    /* Fixes Firefox rendering issues */
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    margin: -1px 0;
  }

  .mbsc-scroller-wheel-cont {
    position: relative;
    z-index: 1;
    top: 50%;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    transform: translateY(-50%);
  }

  .mbsc-scroller-wheel-cont-3d {
    overflow: hidden;
    border: 0;
  }

  .mbsc-scroller-wheel-cont-3d > div {
    position: relative;
    top: -1px;
  }

  .mbsc-scroller-wheel-wrapper-3d,
  .mbsc-scroller-wheel-3d {
    transform-style: preserve-3d;
  }

  .mbsc-scroller-items-3d {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform-style: preserve-3d;
    transform: translateY(-50%);
  }

  .mbsc-scroller .mbsc-scroller-wheel,
  .mbsc-scroller .mbsc-scroller-wheel-cont {
    box-sizing: content-box;
  }

  .mbsc-scroller-wheel-item {
    display: block;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mbsc-scroller-wheel-item:focus,
  .mbsc-scroller-wheel-header:focus {
    outline: 0;
  }

  .mbsc-scroller-wheel-item-3d {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .mbsc-scroller-wheel-item.mbsc-disabled {
    opacity: 0.3;
  }

  .mbsc-scroller-wheel-header {
    display: block; // Needed by Angular
    padding: 0 0.5em;

    &.mbsc-ltr {
      text-align: left;
    }

    &.mbsc-rtl {
      text-align: right;
    }
  }

  .mbsc-scroller-bar-cont {
    position: absolute;
    z-index: 4;
    top: 0;
    bottom: 0;
    width: 10px;
    opacity: 0;
    background: rgba(0, 0, 0, 0.05);
    transform: translateZ(0);
    transition: opacity 0.2s;

    &.mbsc-ltr {
      right: 0;
    }

    &.mbsc-rtl {
      left: 0;
    }
  }

  .mbsc-scroller-bar-hidden {
    display: none;
  }

  .mbsc-scroller-bar-hover,
  .mbsc-scroller-bar-started {
    opacity: 1;
  }

  .mbsc-scroller-bar {
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    // height: 100%;

    &::after {
      content: '';
      position: absolute;
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 3px;
    }
  }
}
