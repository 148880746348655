@import './eventcalendar.ios';
@import './eventcalendar.material';
@import './eventcalendar.windows';

@if $mbsc-eventcalendar {
  .mbsc-eventcalendar {
    height: 100%;
    overflow: hidden;
  }

  /* Event list */

  .mbsc-event-list {
    position: relative;
    z-index: 0;
    margin-top: -1px;
  }

  .mbsc-event-list-scroll {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mbsc-event-list-empty {
    text-align: center;
    margin: 3em;
    font-size: 1.5em;
  }

  .mbsc-event-day {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  .mbsc-event-color,
  .mbsc-event-time {
    flex: 0 0 auto;
  }

  .mbsc-event-content {
    overflow: hidden;
  }

  .mbsc-event-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Popover list */

  .mbsc-calendar-popup .mbsc-popup-content {
    overscroll-behavior: contain;
  }

  .mbsc-popover-list {
    width: 24em;
    max-width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }

  .mbsc-popover-hidden {
    visibility: hidden;
  }

  // Covers the entire calendar during drag, so it show the move cursor everywhere,
  // and will prevent hover on other elements
  .mbsc-calendar-dragging {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    cursor: move;
  }

  /* External drag element */

  .mbsc-draggable {
    user-select: none;
  }

  .mbsc-drag-clone {
    position: fixed;
    display: table;
    z-index: 99998;
    margin: 15px 0 0 0 !important;
    box-shadow: 0 0 transparent, 0 0 transparent, 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    cursor: move;

    &:after {
      content: '';
      position: absolute;
      top: -50px;
      bottom: -50px;
      left: -50px;
      right: -50px;
    }

    .mbsc-calendar-label-resize {
      display: none;
    }
  }

  /* Fake scroll rules */
  .mbsc-schedule-fake-scroll-x,
  .mbsc-schedule-fake-scroll-y {
    visibility: hidden;
    flex: none;
  }

  .mbsc-schedule-fake-scroll-x {
    overflow-x: scroll;
    width: 0;
  }

  .mbsc-schedule-fake-scroll-y {
    overflow-y: scroll;
    max-height: 20px;
  }
}
