@import './list.ios';
@import './list.material';
@import './list.windows';

@if $mbsc-eventcalendar {
  .mbsc-list {
    display: block;
  }

  .mbsc-list-header {
    display: flex;
  }

  .mbsc-list-item {
    position: relative;
    z-index: 2;
    list-style: none;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
    line-height: 1.25em;
  }

  .mbsc-list-item-actionable {
    cursor: pointer;
  }

  .mbsc-list-item:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    transition: background-color 0.2s ease-out;
  }

  .mbsc-list-item:focus {
    outline: 0;
  }

  .mbsc-list-item::-moz-focus-inner {
    border: 0;
  }
}
