@import '../../themes/ios';

@mixin mbsc-ios-notifications($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $border-color: '';

  // Light background
  @if (lightness($background) > 50%) {
    $border-color: darken($background, 17%);
  }

  // Dark background
  @else {
    $border-color: lighten($background, 20%);
  }

  .mbsc-#{$theme} {
    .mbsc-toast-background {
      background: rgba($text, 0.8);
      color: get-contrast-color($text);
    }

    &.mbsc-prompt-input {
      border-color: $border-color;
    }

    &.mbsc-color-none .mbsc-snackbar-button.mbsc-button {
      color: $accent;
    }
  }
}
