@import './button.ios';
@import './button.material';
@import './button.windows';

@if $mbsc-button {
  .mbsc-button {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: middle;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    font-size: 1em;
    box-sizing: border-box;
    touch-action: manipulation;
    user-select: none;
    white-space: nowrap;
    cursor: pointer;
  }

  .mbsc-button:disabled,
  .mbsc-button.mbsc-disabled {
    cursor: not-allowed;
  }

  .mbsc-button:focus {
    outline: 0;
  }

  .mbsc-button::-moz-focus-inner {
    border: 0;
  }

  .mbsc-icon-button {
    width: 2.5em;
    height: 2.5em;
    padding: 0.5em;
    flex: 0 0 auto;
  }

  .mbsc-button-icon > *,
  .mbsc-button-icon > * > * {
    // For custom icon markup
    height: 100%;
  }

  .mbsc-button-icon-end {
    // Needed by javascript/jquery component, where end icon is before the button text in the markup
    order: 1;
  }

  .mbsc-button-group,
  .mbsc-button-group-block,
  .mbsc-button-group-justified {
    // Prevent margin collision
    border: 1px solid transparent;
  }

  .mbsc-button-group-block .mbsc-button,
  .mbsc-button-block {
    display: block;
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mbsc-button-group-justified,
  .mbsc-button-group-justified mbsc-button {
    display: flex;
    flex-wrap: wrap;
  }

  .mbsc-button-group-justified .mbsc-button,
  .mbsc-button-group-justified mbsc-button {
    flex: 1 auto;
  }
}
