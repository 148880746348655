@import '../../themes/ios';

@mixin mbsc-ios-calendar-view($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $background-param: map-get($params, 'calendar-background');
  $text-param: map-get($params, 'calendar-text');
  $accent-param: map-get($params, 'calendar-accent');
  $border-param: map-get($params, 'calendar-border');
  $mark-param: map-get($params, 'calendar-mark');
  $event-param: map-get($params, 'calendar-event');

  $background: if($background-param, $background-param, $background);
  $text: if($text-param, $text-param, if($background-param, get-contrast-color($background), $text));
  $accent: if($accent-param, $accent-param, $accent);
  $event: if($event-param, $event-param, #5ac8fa);

  $background-alt: '';
  $border-color: '';
  $popup-background: '';
  $popup-cell: '';
  $hover: '';

  @if (lightness($background) > 50%) {
    $background-alt: lighten($background, 3%);
    $border-color: darken($background, 17%);
    $popup-background: $background-alt;
    $popup-cell: $background-alt;
    $hover: darken($background, 10%);
  } @else {
    $background-alt: $background;
    $border-color: lighten($background, 20%);
    $popup-background: lighten($background, 16%);
    $popup-cell: lighten($background, 11%);
    $hover: lighten($background, 17%);
  }

  $highlight: '';
  $highlight-contrast: '';

  @if (lightness($background) > 50%) {
    $highlight: lighten(desaturate($accent, 14%), 39%);
    $highlight-contrast: #000;
  } @else {
    $highlight: darken(desaturate($accent, 14%), 39%);
    $highlight-contrast: #fff;
  }

  $border-color: if($border-param, $border-param, $border-color);
  $mark: if($mark-param, $mark-param, $border-color);

  .mbsc-#{$theme} {
    &.mbsc-calendar {
      background: $background-alt;
      color: $text;
    }

    &.mbsc-calendar-wrapper {
      border-color: $border-color;
    }

    &.mbsc-calendar-header {
      border-color: $border-color;
    }

    &.mbsc-calendar-button.mbsc-button {
      color: $accent;
    }

    &.mbsc-calendar-cell {
      background: $background-alt;
      border-color: $border-color;
      color: $text;
    }

    &.mbsc-calendar-day:after {
      border-color: $border-color;
    }

    &.mbsc-calendar-week-nr,
    &.mbsc-calendar-today {
      color: $accent;
    }

    &.mbsc-hover .mbsc-calendar-cell-text {
      background-color: rgba($accent, 0.3);
    }

    /* range highlight and selection */

    &.mbsc-range-day::after {
      background-color: $highlight;
    }

    &.mbsc-range-day .mbsc-calendar-cell-text {
      color: $highlight-contrast;
    }

    &.mbsc-range-hover::before {
      border-color: $hover;
    }

    &.mbsc-selected .mbsc-calendar-cell-text {
      border-color: $accent;
      background: $accent;
      color: get-contrast-color($accent);
    }

    &.mbsc-focus .mbsc-calendar-cell-text {
      box-shadow: 0 0 0 2px rgba($text, 0.5);
    }

    &.mbsc-focus .mbsc-calendar-day-text {
      box-shadow: none;
      border-color: rgba($text, 0.5);
    }

    &.mbsc-calendar-mark {
      background: $mark;
    }

    &.mbsc-calendar-label {
      color: $event;
    }

    &.mbsc-calendar-label-text {
      color: get-contrast-color($background);
    }

    &.mbsc-calendar-label-active,
    &.mbsc-calendar-label-dragging {
      .mbsc-calendar-label-inner {
        color: get-contrast-color($event);
      }

      .mbsc-calendar-label-text {
        color: inherit;
      }
    }

    &.mbsc-calendar-text-more .mbsc-calendar-label-text {
      color: $text;
    }

    /* Picker */
    &.mbsc-calendar-popup {
      .mbsc-popup-arrow,
      .mbsc-popup-body {
        background: $popup-background;
      }

      .mbsc-calendar-cell {
        background: $popup-cell;
      }
    }

    /* Multi month grid view */

    &.mbsc-calendar-grid {
      border-color: $border-color;
    }

    &.mbsc-calendar-month-title {
      color: $accent;
    }
  }
}
