@import '../../themes/ios';

@mixin mbsc-ios-scroller($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $item-color: hsl(hue($text), saturation($text), 62%);

  $background-alt: '';
  $background-top-bottom: '';
  $item-3d: '';
  $overlay: '';
  $scroller-background: '';
  $scroller-selected-line: '';

  @if (lightness($background) > 50%) {
    $background-alt: lighten($background, 3%);
    $background-top-bottom: adjust-hue(darken(saturate($background, 12%), 13%), 216deg);
    $item-3d: darken($background, 33%);
    $overlay: $background-alt;
    $scroller-background: $background-alt;
    $scroller-selected-line: #000;
  } @else {
    $background-alt: lighten($background, 11%);
    $background-top-bottom: lighten($background, 11%);
    $item-3d: lighten($background, 40%);
    $overlay: $background-alt;
    $scroller-background: $background-alt;
    $scroller-selected-line: #fff;
  }

  .mbsc-#{$theme} {
    &.mbsc-scroller {
      background: $scroller-background;
    }

    &.mbsc-scroller-top,
    &.mbsc-scroller-bottom {
      background: $background-top-bottom;
    }

    &.mbsc-scroller-wheel-overlay {
      background: linear-gradient($overlay, rgba($overlay, 0) 52%, rgba($overlay, 0) 48%, $overlay);
    }

    &.mbsc-scroller-wheel-overlay-top,
    &.mbsc-scroller-wheel-overlay-bottom {
      background: linear-gradient(
        $background-top-bottom,
        rgba($background-top-bottom, 0) 52%,
        rgba($background-top-bottom, 0) 48%,
        $background-top-bottom
      );
    }

    &.mbsc-scroller-wheel-line {
      background: rgba($scroller-selected-line, 0.1);
    }

    &.mbsc-scroller-wheel-item {
      color: $item-color;
    }

    &.mbsc-scroller-wheel-item.mbsc-active,
    &.mbsc-scroller-wheel-item.mbsc-hover,
    &.mbsc-scroller-wheel-item.mbsc-focus,
    &.mbsc-scroller-wheel-header.mbsc-focus {
      background: rgba($accent, 0.15);
    }

    &.mbsc-scroller-wheel-item-2d,
    &.mbsc-scroller-wheel-item.mbsc-selected {
      color: $text;
    }

    /* 3D */

    &.mbsc-scroller-wheel-cont-3d {
      background: $scroller-background;
    }

    &.mbsc-scroller-wheel-item-3d {
      color: $item-3d;
    }

    &.mbsc-scroller-wheel-header,
    &.mbsc-scroller-wheel-item-3d.mbsc-wheel-item-multi {
      color: $text;
    }

    &.mbsc-scroller-wheel-item-3d.mbsc-wheel-item-multi.mbsc-selected-3d {
      color: $accent;
    }

    &.mbsc-scroller-wheel-cont-top.mbsc-scroller-wheel-cont-3d,
    &.mbsc-scroller-wheel-cont-bottom.mbsc-scroller-wheel-cont-3d {
      background: $background-top-bottom;
    }

    &.mbsc-scroller-wheel-cont-inline.mbsc-scroller-wheel-cont-3d {
      background: $background-alt;
    }

    /* Desktop style */

    &.mbsc-scroller-pointer {
      &.mbsc-scroller {
        background: $scroller-background;
      }

      &.mbsc-scroller-inline {
        background: $background-alt;
      }

      .mbsc-scroller-wheel-item {
        color: $text;
      }

      .mbsc-scroller-wheel-item.mbsc-selected {
        color: $accent;
      }
    }
  }
}
