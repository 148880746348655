@import './calendar-view.ios.colors';

@if ($mbsc-ios-theme and $mbsc-calendar-view) {
  .mbsc-ios {
    &.mbsc-calendar-controls {
      /* IE11 needs px size here to avoid subpixel values */
      padding: 2px;
      /* padding: .125em; */
    }

    &.mbsc-calendar-cell {
      border-top-style: solid;
      border-top-width: 1px;
    }

    &.mbsc-calendar-cell-text {
      height: 2em;
      padding: 0 0.5em;
      line-height: 2em;
      border-radius: 2em;
    }

    &.mbsc-calendar-week-day {
      height: 1.7em;
      line-height: 1.7em;
      font-size: 0.625em;
    }

    &.mbsc-calendar-week-nr {
      width: 2em;
      height: auto;
      font-size: 0.75em;
      font-weight: bold;
      line-height: 3em;
    }

    &.mbsc-calendar-day-text {
      width: 1.625em;
      height: 1.625em;
      margin: 0.1875em;
      padding: 0;
      line-height: 1.625em;
      border: 2px solid transparent;
      border-radius: 2em;
    }

    /* Range Highlight */

    &.mbsc-range-day .mbsc-calendar-cell-inner {
      z-index: 1;
    }

    &.mbsc-range-day::after,
    &.mbsc-range-hover::before {
      content: '';
      height: 1.875em;
      position: absolute;
      left: -1px;
      right: -1px;
      top: 0.25em;
      z-index: 0;
      box-sizing: border-box;
    }

    &.mbsc-range-hover::before {
      border-top: 2px dashed;
      border-bottom: 2px dashed;
    }

    &.mbsc-range-day-start.mbsc-ltr::after,
    &.mbsc-range-day-end.mbsc-rtl::after,
    &.mbsc-range-hover-start.mbsc-ltr::before,
    &.mbsc-range-hover-end.mbsc-rtl::before {
      left: 50%;
      margin-left: -0.9375em;
      border-radius: 2em 0 0 2em;
    }

    &.mbsc-range-day-end.mbsc-ltr::after,
    &.mbsc-range-day-start.mbsc-rtl::after,
    &.mbsc-range-hover-end.mbsc-ltr::before,
    &.mbsc-range-hover-start.mbsc-rtl::before {
      right: 50%;
      margin-right: -0.9375em;
      border-radius: 0 2em 2em 0;
    }

    &.mbsc-range-day-start.mbsc-range-day-end::after,
    &.mbsc-range-hover-start.mbsc-range-hover-end::before {
      display: none;
    }

    /* Marks */

    &.mbsc-calendar-day-marked {
      padding-bottom: 0.4375em;
    }

    &.mbsc-calendar-marks {
      margin-top: -0.0625em;
    }

    &.mbsc-calendar-mark {
      width: 0.375em;
      height: 0.375em;
      border-radius: 0.375em;
      margin: 0 0.0625em;
    }

    /* Colors */

    &.mbsc-calendar-day-colors .mbsc-calendar-day-text {
      background-clip: padding-box;
    }

    &.mbsc-calendar-day-colors.mbsc-hover .mbsc-calendar-cell-text {
      background-clip: border-box;
    }

    /* Labels */

    &.mbsc-calendar-text:before {
      border-radius: 0.4em;
    }

    &.mbsc-calendar-label {
      font-weight: 600;
    }

    &.mbsc-calendar-label-background {
      margin: 0 -0.1em;
      background: currentColor;
      opacity: 0.3;
      transition: opacity 0.15s ease-in-out;
    }

    &.mbsc-calendar-label-start.mbsc-ltr .mbsc-calendar-label-background,
    &.mbsc-calendar-label-end.mbsc-rtl .mbsc-calendar-label-background {
      margin-left: 0;
      border-top-left-radius: 0.4em;
      border-bottom-left-radius: 0.4em;
    }

    &.mbsc-calendar-label-end.mbsc-ltr .mbsc-calendar-label-background,
    &.mbsc-calendar-label-start.mbsc-rtl .mbsc-calendar-label-background {
      margin-right: 0;
      border-top-right-radius: 0.4em;
      border-bottom-right-radius: 0.4em;
    }

    &.mbsc-calendar-label-hover .mbsc-calendar-label-background {
      opacity: 0.6;
    }

    &.mbsc-calendar-label.mbsc-calendar-label-hover:before {
      background: none;
    }

    &.mbsc-calendar-label-dragging .mbsc-calendar-label-background,
    &.mbsc-calendar-label-active .mbsc-calendar-label-background {
      opacity: 0.9;
    }

    /* Desktop styling */

    &.mbsc-calendar-height-md {
      .mbsc-calendar-day:after {
        position: absolute;
        top: 0;
        right: 100%;
        bottom: 0;
        z-index: 1;
        margin-right: -1px;
        border-left-width: 1px;
        border-left-style: solid;
        content: '';
      }
    }

    &.mbsc-calendar-width-md {
      .mbsc-calendar-title {
        font-size: 1.5em;
        line-height: 1.666667em;
        padding: 0 0.166667em;
      }

      // .mbsc-calendar-year {
      //   font-weight: 200;
      // }

      .mbsc-calendar-week-day {
        height: 2.5em;
        padding: 0 0.5em;
        line-height: 2.5em;
        font-size: 1em;
        border-left: 1px solid transparent;
      }

      .mbsc-calendar-week-nr {
        padding: 0;
        font-size: 0.75em;
      }

      .mbsc-calendar-day-inner {
        min-height: 2.5em;
      }

      .mbsc-calendar-day-labels .mbsc-calendar-day-inner {
        min-height: 4.75em;
      }

      .mbsc-calendar-marks {
        padding: 0 0.75em;
      }

      .mbsc-calendar-day-text {
        width: 1.375em;
        height: 1.375em;
        line-height: 1.375em;
      }

      .mbsc-calendar-text {
        height: 1.8em;
        line-height: 1.8em;
        margin: 0 0.5em 0.2em 0.6em;
      }

      .mbsc-calendar-label {
        padding: 0 0.4em;
      }

      .mbsc-calendar-label-text {
        font-size: 1.2em;
      }

      .mbsc-calendar-label-background {
        margin: 0 -0.4em;
      }
    }

    &.mbsc-calendar-height-md.mbsc-calendar-width-md {
      .mbsc-calendar-week-day.mbsc-ltr,
      .mbsc-calendar-day.mbsc-ltr,
      .mbsc-calendar-marks.mbsc-ltr {
        text-align: right;
      }

      .mbsc-calendar-week-day.mbsc-rtl,
      .mbsc-calendar-day.mbsc-rtl,
      .mbsc-calendar-marks.mbsc-rtl {
        text-align: left;
      }
    }

    /* Multi month grid view */

    &.mbsc-calendar-grid-view .mbsc-calendar-title {
      font-size: 1.5em;
      line-height: 1.666667em;
      padding: 0 0.166667em;
    }

    &.mbsc-calendar-grid {
      border-top: 1px solid;
    }

    &.mbsc-calendar-grid .mbsc-calendar-grid-item .mbsc-calendar-week-days {
      background: none;
    }

    &.mbsc-calendar-grid .mbsc-calendar-cell {
      border: 0;
    }
  }

  @include mbsc-ios-calendar-view('ios', $mbsc-ios-colors);
  @include mbsc-ios-calendar-view('ios-dark', $mbsc-ios-dark-colors);
}
