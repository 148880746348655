/* Specific css start */
#kt_toolbar {
    display: none;
}

body{
    overflow: hidden;
}

.header-fixed {
    &.toolbar-fixed {
        .wrapper {
            @media (max-width: 1199.98px) {
                padding-top: calc(5px + var(--kt-toolbar-height-tablet-and-mobile))
            }
        }
    }
}

.header-tablet-and-mobile-fixed {
    &.toolbar-tablet-and-mobile-fixed {
        .header {
            @media (max-width: 1199.98px) {
                -webkit-box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
                box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
                height: 55px;
            }
        }
    }
}

/* Specific css end */

html {
    body {
        font-family: 'Source Sans Pro', sans-serif;
    }
}

.fontIner {
    font-family: 'Source Sans Pro', sans-serif;
}

.fs-40 {
    font-size: 2.5rem;
}

#kt_content_container {
    &.container.container-fluid {
        width: 100%;
        max-width: 100%;
    }
}

.column-gap-15{
    column-gap:15px;
    row-gap:15px;
}

.aside-dark .menu .menu-item.show>.menu-link {
    background-color: rgba(255, 255, 255, 0.19);
}

.aside-dark .menu .menu-item.here>.menu-link {
    background-color: rgba(255, 255, 255, 0.19);
}

.aside-dark .menu .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here),
.aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) {
    background-color: rgba(255, 255, 255, 0.19);
}



/* To set header fixed css start */
#kt_wrapper {
    height: 100vh;
    overflow: hidden;

    #kt_content {
        height: calc(100% - 90px);

        #kt_post {
            height: 100%;

            #kt_content_container {
                height: 100%;

                >.d-flex {
                    height: 100%;

                    .mbsc-schedule-event-hover {
                        cursor: default !important;
                        .hash-serviceCall{
                            cursor: pointer;
                        }
                    }   

                    .pScreenmain{
                        height: calc(100% - 66px);
                        overflow-y: auto; 
                    }
                    .planningScreen {
                        .mbsc-schedule-event-hover{
                            .eventHeader,
                            .eventBody{
                                .opacity-100{
                                    opacity: 0.8 !important;
                                }
                            }
                        }


                        .mbsc-timeline-grid-scroll{
                            &::-webkit-scrollbar:horizontal,
                            &::-webkit-scrollbar{
                                height: 22px !important;
                            }                           
                            &::-webkit-scrollbar-thumb:horizontal{
                                background-color: #dadce0 !important;
                            } 
                            &::-webkit-scrollbar-thumb:vertical{
                                background-color: transparent !important;
                            }
                        }

                        &.monthView{
                            .mbsc-timeline-column,
                            .mbsc-timeline-header-column{
                                min-width: 105px;
                            }
                            .mbsc-timeline-events{
                                > .mbsc-schedule-event{
                                }
                            }
                            .mbsc-timeline-resource{
                                display: flex;
                                align-items: center;
                            }
                            .position-static.custom-event-class{
                                position: relative !important;
                                left: auto !important;
                                right: auto !important;
                                top: 9px !important;
                            }
                            .mbsc-grid{
                             &:not(.projectScreen)  {
                                .mbsc-schedule-event-title{
                                    padding: 0 0 0.5rem !important;
                                    text-align: center;
                                }
                             }
                            }
                            .mbsc-timeline-header-date-cont{
                                > .d-flex{
                                    > .md-date-header{
                                        margin-left: auto;
                                        margin-right: auto;
                                    }
                                }
                            }
                        }
                        &.dayView{
                            .mbsc-timeline-time-indicator-cont{
                                + .mbsc-flex-none{
                                    + .mbsc-flex-none{
                                        flex: 1 1 auto;
                                    }
                                }
                            } 
                            .mbsc-timeline-day{
                                &.mbsc-flex-none{
                                    flex: 1 1 auto !important;
                                }

                            }    
                        }
                        .lh-32px{
                            line-height: 2;
                        }
                        .mt-n3px{
                            margin-top: -3px;
                        }
                        .mt-n2px{
                            margin-top: -2px;
                        }

                        .hashTask{
                            .collapsDatas {
                                .brtr{
                                    &.h-32px{
                                        min-height: 32px !important;
                                    }
                                }                                
                                .eventHeader{
                                    &.min-h-32px{
                                        min-height: 32px !important;
                                    }
                                }
                            }                            
                        }
                        .collapsDatas {
                            .show-less-time{
                                display: flex !important;
                            }
                            .brtr{
                                &.h-32px{
                                    min-height: 36px !important;
                                }
                            }
                            .eventHeader{
                                &.min-h-32px{
                                    min-height: 36px !important;
                                }
                            }
                        }    

                        &.yearView{

                            .collapsDatas {

                                .eventHeader{
                                    > .position-relative{
                                    }
                                }
                                a{
                                    &.min-w-50px{
                                    }
                                }
                            }

                            .mbsc-timeline-header-sticky{
                                .mbsc-timeline-header-text{
                                    min-width: 139px;
                                }
                            } 

                            .mbsc-timeline-column,
                            .mbsc-timeline-header-column{
                                min-width: 140px;
                            }
                            .mbsc-timeline-events{
                                > .mbsc-schedule-event{
                                }
                            }
                            .mbsc-timeline-resource{
                                display: flex;
                                align-items: center;
                            }
                            .position-static.custom-event-class{
                                position: relative !important;
                                left: auto !important;
                                right: auto !important;
                                top: 9px !important;
                            }
                            .mbsc-grid{
                                &:not(.projectScreen){
                                    .mbsc-schedule-event-title{
                                        padding: 0 0 0.5rem !important;
                                        text-align: center;
                                    }
                                }
                            }
                            .mbsc-timeline-header-date-cont{
                                > .d-flex{
                                    > .md-date-header{
                                        margin-left: auto;
                                        margin-right: auto;
                                    }
                                }
                            }
                        }

                        .picRight{
                        }

                        .mbsc-ios{
                            &.mbsc-calendar-controls{
                                padding: 0 1.25rem 12px;
                                border-bottom: 1px solid #DADCE0;
                                .pickerMain{
                                    > div{
                                        margin-top: 12px;
                                    }
                                }
                            }
                            &.mbsc-eventcalendar{
                                .mbsc-calendar-header,
                                .mbsc-calendar-week-days{
                                    background-color: transparent;
                                }
                            }
                            &.mbsc-button{
                                padding: 0;
                            }
                        }
                        .md-custom-range-view-controls{
                            right: 1.25rem !important;
                        }

                        /* Css added to fix project screen save layout popup issue start 08-08-24 */
                        height: calc(100% - 66px);
                        overflow-y: auto;
                        >div {
                            height: 100%;

                            >div {
                                height: 100%;

                                >div {
                                    height: 100%;

                                    >div {
                                        height: 100%;
                                    }
                                }
                            }
                        }
                        /* Css added to fix project screen save layout popup issue end 08-08-24 */

                        &.pScreenmain{    
                            >div {
                                height: 100%;

                                >div {
                                    height: 100%;

                                    >div {
                                        height: 100%;

                                        >div {
                                            height: 100%;
                                        }
                                    }
                                }
                            }

                            .mbsc-timeline-row{
                                height: 5em;
                            }

                        }
                        .mbsc-eventcalendar {
                            height: 100%;                            
                        }

                        .LayoutBtns{
                            position: relative;
                            .menu{                                
                                -webkit-transform: translateX(-25px);
                                transform: translateX(-25px);
                            }       
                        }
                    }
                }
            }
        }
    }
}


.projectScreen{
    .mbsc-eventcalendar {
        max-height: calc(100vh - 66px);
    }
}

/* To set header fixed css end */



.shadowEffect {
    background: -webkit-gradient(linear, left bottom, left top, from(#FFFFFF), color-stop(124.32%, rgba(255, 255, 255, 0)));
    background: -o-linear-gradient(bottom, #FFFFFF 0%, rgba(255, 255, 255, 0) 124.32%);
    background: linear-gradient(360deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 124.32%);
    pointer-events: none;
}

.fs-100{
    font-size: 100%;
}
.fs-16{
    font-size: 16px;
}
.fs-18{
    font-size: 18px;
}
.text-toggle-hover{
    position: relative;
    small{
        transition: 100ms all;
        &.hoverSmall{
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            opacity: 0;
        }
    }
    &:hover{
        small{
            opacity: 0;
            &.hoverSmall{
                opacity: 1;
            }
        }
    }
}

.aside {
    @media (min-width: 992px) {
        width: 250px;
    }

    .aside-logo {
        background-color: var(--kt-primary) !important;
        font: 700 18px/1.2 'Source Sans Pro', sans-serif;
        text-transform: uppercase;
        height: 64px;
        padding: 0 10px;

        @media (min-width: 1400px) {}
    }

    .aside-menu {
        @media (min-width: 992px) {
            width: 250px;
        }

        .menu {
            .menu-item {
                + .menu-item{
                    margin-top: 12px;
                }
                .menu-item {
                    margin-top: 0;
                }
                .menu-link {
                    font: 400 16px/2.5 'Source Sans Pro', sans-serif;
                    padding: 0 20px;
                    color: #ffffff;
                    .menu-arrow{
                        &:after{
                            background: #ffffff;
                            mask-image: url(icons/down-arrow.svg) !important;
                            transform: none !important;
                            width: 12px;
                            height: 8px;
                            -webkit-mask-size: contain !important;
                            mask-size: contain !important;
                        }
                    }
                    .menu-icon {
                        color: #ffffff;
                        min-width: 32px;
                        width: auto;
                        margin: 0;
                        .svg-icon {
                            color: #ffffff;

                            svg {
                                width: 1.54rem;
                                height: 1.54rem;

                                @media (min-width: 1400px) {
                                    width: 1.85rem;
                                    height: 1.85rem;
                                }
                            }
                        }
                    }

                    .menu-title {
                        color: #ffffff;
                    }

                    &.active {
                        background-color: rgba(255, 255, 255, 0.19) !important;
                        position: relative;
                        &::before{
                            position: absolute;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            width: 4px;
                            background-color: #ffffff;
                            content: '';
                        }
                        .menu-icon {
                            color: #ffffff;

                            .svg-icon {
                                color: #ffffff;
                            }
                        }

                        .menu-title {
                            color: #ffffff;
                        }
                    }
                }

                &.show {
                    .menu-icon {
                        color: #ffffff;

                        .svg-icon {
                            color: #ffffff !important;
                        }
                    }
                    .menu-arrow{
                        &:after{
                            -webkit-transform: rotate(180deg) !important;
                            transform: rotate(180deg) !important;
                        }
                    }
                }

                .menu-sub {
                    .menu-link {
                        font-size: 14px;

                        &:hover {
                            .menu-bullet {
                                .bullet {
                                    background-color: #ffffff !important;
                                }
                            }
                        }

                        &.active {
                            .menu-bullet {
                                .bullet {
                                    background-color: #ffffff !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .aside-footer {
        padding: 0 0 50px;
        .menu-arrow{
            margin-right: 10px;
        }
        .infoBtn{
            background-image: url(../sass/icons/Info-white.svg);
        }
        .d-flex{
            > .h-40px{
                &:has(.infoBtn){
                    + .menu-item{
                        .menu-arrow{
                            margin-right: 5px;
                        }
                    }
                }
            }
        }            

        >div {
            &+div {
                margin-top: 20px;
                padding: 0 20px;
            }

            a {
                font: 400 16px/1.2 'Source Sans Pro', sans-serif;
                color: #ffffff;
                cursor: pointer;
                display: flex;
                align-items: center;

                .svg-icon {
                    width: auto;
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    color: #ffffff;
                    min-width: 32px;
                    margin: 0;
                }

                &:hover {
                    color: #ffffff;

                    .svg-icon {
                        color: #ffffff;
                    }
                }
            }
        }
        .menu-link{
            &::before{
                display: none;
            }
        }
    }

}

.aside-enabled {
    &.aside-fixed {
        .wrapper {
            @media (min-width: 1200px) {
                padding-left: 250px;
            }

            @media (max-width: 1199.98px) {
                padding-left: 0;
            }
        }

        &.header-fixed {
            .header {
                height: 70px;

                @media (min-width: 1200px) {
                    left: 210px;
                    -webkit-box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
                    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
                }

                @media (max-width: 1199.98px) {
                    left: 0;
                }
            }
        }

        &.toolbar-fixed {
            .toolbar {
                @media (min-width: 1200px) {
                    left: 230px;
                    top: 90px;
                }
            }
        }
    }
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}
.fs-14 {
    font-size: 14px;
}
.fs-15{
    font-size: 15px;
}

.mt-n1px{
    margin-top: -1px;
}

@media (min-width: 1400px) {
    .border-xxl-start {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .border-xxl-end {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .border-xxl-top {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .border-xxl-bottom {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }
}

@media (min-width: 1200px) {
    .border-xl-start {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .border-xl-end {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .border-xl-top {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .border-xl-bottom {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }
}

@media (min-width: 992px) {
    .border-lg-start {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .border-lg-end {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .border-lg-top {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .border-lg-bottom {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }
}

@media (min-width: 768px) {
    .border-md-start {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .border-md-end {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .border-md-top {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .border-md-bottom {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }
}

@media (min-width: 576px) {
    .border-sm-start {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .border-sm-end {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .border-sm-top {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .border-sm-bottom {
        border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }
}


@media (max-width: 767px) {
    .fs-40 {
        font-size: 2.5rem;
    }
}


#kt_header {
    #kt_header_nav {
        @media (max-width: 1199.98px) {
            -webkit-box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
            overflow: auto;
        }

        #kt_header_menu {
            @media (max-width: 1199.98px) {
                padding: 0.8rem 15px;
                overflow: auto;
                width: 100%;
                min-height: 52px;

                >.menu {
                    white-space: nowrap;
                    overflow: auto;

                    @media (max-width: 767px) {
                        margin-right: 110px;
                    }
                }
            }
        }
    }

    .crBtn {
        @media (max-width: 767px) {
            margin: 0.8rem 15px 0 0;
        }
    }


}

.flex-1 {
    flex: 1;
}

.row-gap-5 {
    row-gap: 5px;
}

.dropDown {
    position: relative;

    .filtClose {
        position: absolute;
        right: 0;
        top: 0;
        height: 33px;
        width: 20px;
        z-index: 1;

        span {
            float: left;
            width: 12px;
            height: 12px;
        }

        @media (max-width: 767px) {
            height: 33px;
        }
    }

    +.dropDown {
        .btn {
            // &.border-themelight.show,
            // &.border-themelight:hover{
            //     background-color: #DCDDFF;
            // }
            border-top: 1px solid #bcb9b9;
            /* border-top: 1px solid grey; */
            padding: 7px 7px 0 7px;

        }
    }
}

.planningFilter {
    .dropDown {
        +.dropDown {
            .btn {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }
}


.fw-800 {
    font-weight: 800 !important;
}


.border-color75 {
    &.border-2 {
        border-width: 2px !important;
    }

    &:checked {
        border-width: 0px !important;
    }
}


.text-gray-900 {
    &::placeholder {
        color: currentColor;
        opacity: 1;
        /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: currentColor;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: currentColor;
    }
}


.placeholderGray{
    &::placeholder {
        color: #5C5C71;
        opacity: 1;
        /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #5C5C71;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #5C5C71;
    }
}


.mbsc-ios {
    &.mbsc-calendar-button {
        &.mbsc-button {
            color: #000000;
        }
    }
}

.tabCss {
    .nav-item {
        .nav-link {
            border: 0 !important;
            position: relative;

            &:after {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 3px;
                content: '';
                border-radius: 500rem;
            }

            &.active {
                color: #000000 !important;

                &::after {
                    background-color: #000000;
                }

                .svg-icon {
                    color: var(--kt-text-primary) !important;
                }
            }
        }
    }
}

/* .mbsc-calendar-picker-wrapper {
    height: 12em;
} */

.mbsc-ios .mbsc-popup-center {
    min-width: 30rem;
}

.mbsc-calendar-picker {
    .mbsc-calendar-cell-text {
        font-size: 12px;
    }
}

.customSelect {
    >div {
        margin-right: 1rem;
        box-shadow: none !important;

        >div {
            >div {
                >div:first-of-type {
                    border: 0 !important;
                    box-shadow: none !important;
                    margin: 0 5px;

                    svg {
                        width: 100%;
                        height: 100%;

                        path {
                            fill: #999999;
                        }
                    }

                    >div:last-child {
                        >div {
                            width: 20px !important;
                            height: 20px !important;
                        }
                    }
                }

                >div:last-child {
                    >div {
                        >div {
                            display: flex;
                            align-items: center;
                            font-weight: 500;
                            font-size: 0.95rem;
                        }
                    }
                }
            }
        }
    }
    > button{
        margin-right: 1rem;
    }
    button {
        // background-color: var(--kt-prlight);
        border: 1px solid var(--kt-primary);
        color: var(--kt-primary);
        font-size: 16px;
        border-radius: 50px;
        box-shadow: none;
        font-weight: 400;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.8rem 0 13px;

        span {
            line-height: 1;

            +span {
                margin: 0;
            }
        }

        +div {
            box-shadow: var(--kt-menu-dropdown-box-shadow);
            font-size: 0.85rem;

            input[type="checkbox"] {
                width: 15px;
                height: 15px;
                border: var(--kt-form-check-input-border);
                background-color: var(--kt-form-check-input-bg);
                border-radius: 5px;
                appearance: none;

                &:checked {
                    background-image: var(--kt-form-check-input-checked-bg-image);
                    background-size: 70% 70%;
                    background-color: var(--kt-form-check-input-checked-bg-color);
                    border-color: var(--kt-form-check-input-checked-border-color);
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
        }
    }

    button {
        &.form-control {
            >label {
                &.mbsc-ios {
                    margin: 0;
                    background-color: transparent !important;
                }

                &::after {
                    display: none;
                }
            }

            input {
                font-size: 16px;
                font-weight: 400;
                padding: 0;
                color: var(--kt-primary);
                height: 32px;
                border: 0;
                &::placeholder {
                    color: var(--kt-primary) !important;
                    opacity: 1 !important;
                }
            }
        }
    }
}


.table tr:first-child,
.table th:first-child,
.table td:first-child {
    padding-left: 0.75rem;
}

.tooltip {
    position: absolute;
    pointer-events: none;
}

.employee-shifts-popup {
    .mbsc-font {
        font-family: inherit;
    }

    .mbsc-popup-body {
        background-color: #ffffff;
        // overflow: inherit;
    }

    .mbsc-popup{
        width: 550px !important;
    }

    .mbsc-popup-header {
        padding: 20px 24px;
        text-align: left;
        border-color: #eff2f5;
        color: var(--kt-text-dark);
        font: 600 18px/1.333 'Source Sans Pro', sans-serif;
        border-bottom: 1px solid #dadce0;
    }

    .mbsc-ios .mbsc-textfield-wrapper-underline{
        overflow: inherit;
    }

    .mbsc-alert-content {
        padding: 3rem 0;
        max-width: 100%;

        .mbsc-alert-message {
            font-size: 16px;
        }
    }

    .mbsc-popup-content{
        overflow: visible;
        flex: inherit;
    }
    .md-tooltip-info{
        padding-top: 16px;
        padding-bottom: 7px;
        > .position-absolute{
            bottom: -100px;
        }
    }

    .mbsc-form-group{
        margin: 0 0 17px;
        > label{
            &.form-control{
                background-color: transparent;
            }
        }
    }

    .mbsc-popup-center {
        min-width: 30rem;
    }

    .mbsc-popup-content {

        .md-tooltip-header {
            padding: 0.5rem 1.5rem;

            span {
                padding: 0 !important;

                b {
                    font-weight: 600;
                }
            }
        }
    }

    .mbsc-popup-arrow {
        width: 1em;
        height: 1em;
        margin-left: -0.5em;
    }

    .mbsc-popup-arrow-wrapper-bottom,
    .mbsc-popup-arrow-wrapper-top {
        height: 1.7em;
    }

    .md-tooltip-title {
        +.md-tooltip-title {
            margin-top: 0.2rem;
        }
    }

    .mbsc-form-group:empty {
        display: none;
    }

    .mbsc-form-group {
        >label {
            padding: 0;
            margin: 0;
            border: 0;
            &::after {
                opacity: 0;
                display: none;
            }
        }

        .mbsc-label-inline {
            padding: 0 0 4px;
            font: 600 16px/1.5 'Source Sans Pro', sans-serif;
        }

        .mbsc-textfield {
            background-color: var(--kt-input-solid-bg);
            color: var(--kt-input-solid-color);
            font-size: 0.95rem;
            padding: 0 1rem;
            height: 40px;
            border-radius: 0.475rem;
        }

        select {
            &.mbsc-textfield {}
        }

        .mbsc-icon {
            svg {
                width: 65%;
                height: 65%;
            }
        }
    }

    .mbsc-popup-buttons {
        border-top: 1px solid #A4A6AA;
        padding: 15px 24px;
        justify-content: flex-end;

        .mbsc-popup-button {
            border: 0 !important;
            flex: none !important;
            padding: 0;
            font-size: 12px;
            height: 36px;
            border-radius: 500rem !important;
            padding: 0 1rem;
            font-weight: 600;
            min-width: 70px;

            &+.mbsc-popup-button {
                margin-left: 0.75rem;
            }

            &.mbsc-popup-button-close {
                color: var(--kt-dark);
                background-color: var(--kt-dark-light);

                &:hover {
                    color: var(--kt-dark-inverse);
                    background-color: var(--kt-dark);
                }
            }

            &.mbsc-popup-button-primary {
                color: var(--kt-primary-inverse);
                background-color: var(--kt-primary);

                &:hover {
                    color: var(--kt-primary-inverse);
                    background-color: var(--kt-primary-active);
                }
            }
        }
    }

    .mbsc-select-icon {
        &.mbsc-ltr {
            right: 0;
            top: 0;
            height: 100%;
            color: var(--kt-text-dark);
            display: flex;
            align-items: center;
        }
    }

    .mbsc-scroller-pointer {
        .mbsc-wheel-item-checkmark {
            &.mbsc-ltr {
                padding: 0 0 0 2rem;
                font-size: 12px;
            }
        }
    }


    .mbsc-popup-buttons{
        justify-content: flex-end !important;
    }

}

.mbsc-popup-body{
    .mbsc-popup-buttons {
        border-top: 1px solid #A4A6AA;
        padding: 0 24px !important;
        height: 66px;
        justify-content: center;
        align-items: center;
        .mbsc-popup-button{
            flex: none !important;
            &.mbsc-popup-button-primary{
                background-color: var(--kt-primary);
                color: var(--kt-primary-inverse);
                &:hover{
                    background-color: var(--kt-primary-active);
                    color: var(--kt-primary-inverse);
                }
            }
        }
    }
}


.rotateButton{
    .svg-icon{
        svg{
            -webkit-transform: rotate(-180deg) translateY(25%);
            transform: rotate(-180deg) translateY(25%);
        }
    }
}    

.accordion{
    .md-tooltip-header{
        > button{
            margin-right: 24px;
            &:not(.collapsed){
                .svg-icon{
                    svg{
                        -webkit-transform: rotate(-180deg) translateY(25%);
                        transform: rotate(-180deg) translateY(25%);
                    }
                }
                ~ .colorDiv{
                    opacity: 0.30 !important;
                }
            }
        }
        > div {
            > a{
                > .svg-icon{
                    svg{
                        margin-top: -1px;
                    }
                }
            }
        }
    }
    .accordion-collapse{
        border-bottom: 1px solid #DADCE0;
    }
    > .fs-16{
        &:last-child{
            .accordion-collapse{
            }
        }
    }
}

.cusTooltip {
    .mbsc-popup-content {
        padding: 1rem;
        background-color: #404854;
    }

    .mbsc-popup-arrow {
        background-color: #404854;
    }
}

.py-5px{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
.py-6px{
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}
.me-8px{
    margin-right: 8px !important;
}
.pe-8px{
    padding-right: 8px !important;
}
.pe-5px{
    padding-right: 5px !important;
}
.pb-15px{
    padding-bottom: 15px !important;
}
.pt-4px{
    padding-top: 4px !important;
}
.pt-6px{
    padding-top: 6px !important;
}
.pt-8px{
    padding-top: 8px !important;
}
.pt-1px{
    padding-top: 1px !important;
}
.top-1px{
    top: 1px !important;
}
.mb-16px{
    margin-bottom: 16px !important;
}
.mb-36px{
    margin-bottom: 36px !important;
}
.mb-20px{
    margin-bottom: 20px !important;
}
.mb-22px{
    margin-bottom: 22px !important;
}
.mb-23px{
    margin-bottom: 23px !important;
}
.mb-24px{
    margin-bottom: 24px !important;
}
.mb-25px{
    margin-bottom: 25px !important;
}
.lh-24px{
    line-height: 24px !important;
}
.lh-29px{
    line-height: 29px !important;
}
.mb-48px{
    margin-bottom: 48px !important;
}
.mb-4px{
    margin-bottom: 4px;
}
.lh-24px{
    line-height: 24px;
}
.row-24{
    margin: 0 -12px;
    > .col{
        padding-left: 12px;
        padding-right: 12px;
    }
}

.mainTootltiphover{
    > .tooltip{
        pointer-events: none;
    }
    &:hover{
        > .tooltip{
            opacity: 1;
        }
    }
}

.triggerTooltip{
    .tooltip{
        -webkit-transform: translateX(-56%);
        transform: translateX(-56%);
        left: 50%;
        pointer-events: none;
    }
    &:hover{
        .tooltip{
            opacity: 1;
        }
        + .tooltip{
            opacity: 0 !important;
        }
    }
}

.tooltip{
    .tooltip-inner{
        background-color: #ffffff;
        -webkit-box-shadow: 0px 8px 16px rgba(9, 24, 63, 0.25);box-shadow: 0px 8px 16px rgba(9, 24, 63, 0.25);border-radius: 4px;
        padding: 18px 16px;
        color: #181833 !important;
        font: 400 16px/1.25 'Source Sans Pro' !important;
        .md-tooltip-title{
            color: #181833 !important;
            font: 400 16px/1.25 'Source Sans Pro' !important;
            + .md-tooltip-title{
                margin-top: 10px;
            }
        }
    }

    .tooltip-arrow::before{
        border-width: 11px;
    }

    &.bs-tooltip-start .tooltip-arrow,
    &.bs-tooltip-auto[data-popper-placement^=start] .tooltip-arrow{
        right: 0 !important;
        left: auto !important;
        top: 50% !important;
        -webkit-transform: translateY(-100%) !important;
        transform: translateY(-100%) !important;
    }

    &.bs-tooltip-start .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^=start] .tooltip-arrow::before{
        border-left-color: #ffffff;        
    }

    &.bs-tooltip-end .tooltip-arrow,
    &.bs-tooltip-auto[data-popper-placement^=end] .tooltip-arrow{
        left: 0 !important;
        top: 50% !important;
        -webkit-transform: translateY(-100%) !important;
        transform: translateY(-100%) !important;
    }

    &.bs-tooltip-end .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^=end] .tooltip-arrow::before{
        border-right-color: #ffffff;        
    }    

    &.bs-tooltip-top .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before{
        border-top-color: #ffffff;        
    }

    &.bs-tooltip-bottom .tooltip-arrow::before,
    &.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before{
        border-bottom-color: #ffffff;        
    }

    &.tooltipBottom{    
        margin-bottom: 7px;
        .tooltip-arrow{          
            top: auto !important;
            bottom: 0 !important;
            -webkit-transform: translate(-50%, 0) rotateX(165deg) !important;        
            transform: translate(-50%, 0) rotateX(165deg) !important
        }    
    }

}

.viewShow{float: left;}

.capacityCard,
.planing_main {
    &.viewShow,
    &.todoShow {
        width: calc(100% - 725px) !important;
        .mbsc-timeline-grid-scroll {                        
          }                
          .mbsc-timeline-grid-scroll::-webkit-scrollbar {
          }    
          .mbsc-timeline-grid-scroll::-webkit-scrollbar-thumb {
          }

    }
    @media (min-width: 1800px) {}
}

.todoSidebar {
    width: 724.99px !important;
    z-index: 9999;
    .dropDown{
        &.me-4{
            margin-right: 0.5rem !important;
        }
        .btn{
            &.h-32px{
                height: auto !important;
                min-height: 36px;
            }
        }
        .filtClose{
            &.h-30px{
                height: 34px !important;
            }
        }
    }
}

body{
    .layoutPopup{
        .mbsc-popup{
            width: 500px !important;
            max-width: 500px !important;
            margin-top: 3.5rem;
        }
    }
}



.plannPopup {
    .mbsc-popup{
        width: 766px !important;
        max-width: 766px !important;
    }
    .mbsc-popup-body{
        border-radius: 4px !important;
    }
    .md-tooltip-header{
        border: 0;
        border-radius: 4px 4px 0 0;
        > .position-absolute{
            border-radius: 4px 4px 0 0;
        }
    }
    .mbsc-popup-content {
        background-color: #ffffff;
    }

    .md-tooltip-info{
        padding-bottom: 24px;
        padding-top: 22px;
        .mb-7{
            + .col-12{
                + .mb-1{
                    margin-top: -1rem;
                    + .mb-1{
                        margin-top: -1rem;
                    }
                }
            }
        }

    }
}
.valignTop{
    vertical-align: top;
}

.todoPopop {
    .mbsc-popup-content {
        background-color: #ffffff;
    }
    .mbsc-popup-body-round{
        border-radius: 4px !important;
    }
    .cusCard{
        padding-top: 48px;
    }

    .mbsc-popup  {
        min-width: 675px;
    }
}

.notePopop {
    .mbsc-popup {
        max-height: 800px !important;
    }
}

.searchBox {
    padding-right: 1.1538rem !important;

}

.grayTrans {
    background-color: rgb(162, 162, 162, 0.20);
}

.placeholder-primary {
    &::placeholder {
        color: var(--kt-prlight-inverse) !important;
        opacity: 1 !important;
    }

    input {
        &::placeholder {
            color: var(--kt-prlight-inverse) !important;
            opacity: 1 !important;
        }
    }
}

.placeholder-primary {
    &:-ms-input-placeholder {
        color: var(--kt-prlight-inverse) !important;
        opacity: 1 !important;
    }

    input {
        &:-ms-input-placeholder {
            color: var(--kt-prlight-inverse) !important;
            opacity: 1 !important;
        }
    }
}

.placeholder-primary {
    &::-ms-input-placeholder {
        color: var(--kt-prlight-inverse) !important;
        opacity: 1 !important;
    }

    input {
        &::-ms-input-placeholder {
            color: var(--kt-prlight-inverse) !important;
            opacity: 1 !important;
        }
    }
}

.employee-shifts-popup.mbsc-textfield {
    background-color: #DCDDFF
}

.nav-link {
    font-weight: bolder;
    font-size: 15px;
}

pre {
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.fullHeightmodal {
    .modal-content {
        height: calc(100vh - 3.75rem);

        .scroll-y {
            max-height: calc(100vh - 25rem);
        }
    }
}

.nodata {

    .mbsc-timeline-resource,
    .mbsc-ios .mbsc-timeline-grid {
        display: none;
    }
}

.btn {
    &.btn-outline {
        &.btn-outline-warning {
            border-color: var(--kt-warning);
        }
    }
}

.cusCard {

    &.mbsc-drag-clone {
        position: fixed !important;
    }
}

.seletPicker {
    >label {
        height: 100%;

        .mbsc-textfield-inner {
            height: 100%;
        }

        &.mbsc-ios {
            background-color: transparent !important;
            margin: 0;
        }

        &::after {
            display: none;
        }

        input {
            height: 100% !important;
            color: inherit !important;
            font: inherit;
            font-weight: 500 !important;
            font-size: 1.075rem !important;

            @media (min-width: 4800px) {
                font-size: 14px !important;
            }


        }
    }

    .mbsc-popup-buttons-bottom {
        background-color: var(--kt-primary) !important;
    }

}

.styledPicker {
    .mbsc-scroller-bar-cont {
        visibility: hidden;
    }
}

.mbsc-timeline-grid-scroll {
    &::-webkit-scrollbar:horizontal,
    &::-webkit-scrollbar {
        height: 14px;
    }     
}


[data-theme="dark"] {
    .mbsc-popup-content {
        .text-dark {
            color: #1B1A16 !important;

            &.bg-gray-400 {
                color: #ffffff !important;
            }
        }

        .mbsc-textfield {
            background-color: #F0F1F6 !important;
            color: #5e6278 !important;
        }
    }

    .filterRight {
        .bg-gray-400 {
            background-color: #F0F1F6 !important;
        }
    }

    #kt_header {
        background-color: #ffffff !important;
        color: #1B1A16 !important;

        .bg-gray-400 {
            background-color: #F0F1F6 !important;
        }
    }
}


.addUserselect{
    > div{
        > button{
            width: 150px;
            border: 1px solid var(--kt-form-select-border-color);
            box-shadow: none !important;
            -webkit-border-radius: 0.31rem;
            border-radius: 0.31rem;
            height: 40px;
            padding: 0 2rem 0 1rem !important;
            background-repeat: no-repeat;
            background-size: 12px auto;
            background-position: calc(100% - 10px) center;
            background-image: var(--kt-form-select-indicator);            
            span + span{
                display: none;
            }
        }
    }
}

.form-select{
    background-size: 12px auto;
    background-position: calc(100% - 10px) center;
    padding-right: 30px !important;
    &.coloredSelect,
    &.bg-primary{
        background-image: url(icons/drop-arrow-white.svg);
        option{
            background-color: #ffffff;
            color: var(--kt-text-dark);
        }
    }
    &.pe-20px{
        padding-right: 20px !important;
    }
    &.pe-0{
        padding-right: 0 !important;
    }
    &.status-form-select{
        padding-right: 12px !important;
        background-position: right center;
    }    
}


.mbsc-ios.mbsc-popup-body{
    background-color: #ffffff;
    .mbsc-alert-content{
        margin: 2rem auto;
        font-size: 16px;
    }
}

.rounded-2px{
    border-radius: 2px !important;
}
.rounded-3px{
    border-radius: 3px !important;
}

.rounded-6px{
    border-radius: 6px !important;
}
.fs-12px{
    font-size: 12px;
}
.fs-36px{font-size: 36px;}
.fs-20px{font-size: 20px;}
.fs-28px{font-size: 28px;}
.lh-12{line-height: 1.2;}
.lh-1{line-height: 1;}
.rounded-8px{
    border-radius: 8px !important;
}
.rounded-4px{
    border-radius: 4px !important;
}
.p-2px{
    padding: 2px !important;
}

.availableTable{
    th{
        font-size: 16px;
        line-height: 1.2;
        font-weight: 600;
        padding: 16px 12px;
    }
    td{
       padding: 15px 12px; 
    }
    th,
    td{
        &:last-child{
        padding-right: 32px;
        }
        &:first-child{
        padding-left: 32px;
        }        
    }  
    .form-control{
        border-color: #D1D2D4;
    }  
    tr{
        &:nth-child(odd){
            &:not(.spaceTr){
                td{
                    background-color: #f5f8fa !important;
                    &:first-child{
                        border-radius: 8px 0 0 8px;
                    }
                    &:last-child{
                        border-radius: 0 8px 8px 0;
                    }                    
                }
            }     
        }
    }
}

.fs-24px{font-size: 24px;}
.form-check-input{
    &.form-check-input-green-red{
        background-color: #F1416C;
        border-color: #F1416C;
        &:checked{
            background-color: #49C481;
            border-color: #49C481;
        }
    }
}    

.showLess{
    .cusCard{
        .md-tooltip-info{
            display: none;
        }
    }    
}

.cusCard {
    color: var(--kt-text-dark);
    font: 600 16px/1.5 "Source Sans Pro", sans-serif;
    .md-tooltip-info{
        > div{
            padding: 16px 0;
            margin: 0;
            > span{
                display: flex;
            }
            + div{
                border-top: 1px solid #DADCE0;
            }
        }
    }
}


.segmenthasBorder{
    .mbsc-segmented-item{
        + .mbsc-segmented-item{
            border-left: 1px solid var(--kt-primary);
        }
    }
}

.mbsc-ios{
    &.mbsc-popup-button-flex{
        &.mbsc-font.mbsc-button-flat{
            border-radius: 4px !important;
            font: 400 16px/1.2 "Source Sans Pro", sans-serif !important;
            height: 32px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0 16px !important;
            border: 1px solid var(--kt-primary) !important;
            &.mbsc-popup-button-close{
                background-color: transparent;
                color: var(--kt-primary);
                &:hover{
                background-color: var(--kt-primary);
                color: #ffffff;
                }
            }
            + .mbsc-font.mbsc-button-flat{
                margin-left: 16px;
            }
            &.border-btnbggray{
                border-color: var(--kt-btnbggray) !important;
            }           
            &.btnNew{
                &::before{
                    background-color: var(--kt-primary);
                    display: inline-block !important;
                    content: '';
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                    -webkit-mask: url('icons/plus.svg') no-repeat center center / cover;
                    mask: url('icons/plus.svg') no-repeat center center / cover;
                }
            }
            
        }

    }
}

.btn{
    &.btn-outline{
        &.btn-outline-primary{
            &.show,
            &:hover{
                color: #ffffff !important;
                .svg-icon{
                    color: #ffffff !important;
                }
                & ~ .filtClose{
                    .svg-icon{
                        color: #ffffff !important;
                    }
                }
            }
        }
    }
}




.grayBorder{border-color: #DADCE0 !important;}

body .apexcharts-text,
body .apexcharts-title-text,
body .apexcharts-legend-text{font-family: inherit !important;}
.pagination{
    .page-item{
        .page-link{
            font-size: 16px;
            &:focus{
                background-color: #ffffff !important;
                color: var(--kt-primary) !important;
            }
            &:hover{
                color: #ffffff !important;
                background-color: var(--kt-primary) !important;
            }
            span{
                &[aria-hidden="true"]{
                    font-size: 20px;
                }
            }
        }
        &.disabled{
            .page-link{
                color: #d1d1d1;
            }
        }
    }
}

.p-main{
    .viewSidebar,
    .activitySidebar{
        display: none;
        .splash-screen{
            top: 0;
            height: 400%;
            bottom: 0;
            z-index: 2 !important;
            background-color: rgba(0, 0, 0, 0.15) !important;
            > span{
                width: 3rem;
                height: 3rem;
            }
        }
    }    
    .todoShow{
        + .activitySidebar{
            display: block;
        }
    }
    .viewShow{
        ~ .viewSidebar {
            display: block;
        }  
    }
}

.viewSidebar  {width: 724.99px !important;}

.seletPicker{
    .mbsc-textfield{
        border-color: #A4A6AA;
    }
}


.form-switch{
    .form-check-input{
        &:focus{
        }
    }
}
.mbsc-has-sticky{
    .mbsc-timeline-grid-scroll{
        &.mbsc-ltr{
        }
    }
}

.filTop{
    padding-top: 16px;
    padding-bottom: 16px;
}

.btn.btn-outline-primary:focus:not(.btn-active),
.btn.btn-outline-primary:hover:not(.btn-active),
.btn.btn-outline-primary:active:not(.btn-active){
    color: #ffffff !important;
    .svg-icon{
        color: #ffffff !important;
    }
}

//current-change-START
.mbsc-calendar-wrapper-fixed{
    z-index: 9;
}

.bg-white{
    .mbsc-segmented{
        background-color: #ffffff !important;
    }
}

.paddLR8{
    padding-left: 8px;
    padding-right: 8px;
}
.paddLR24{
    padding-left: 24px !important;
    padding-right: 24px !important;
}
.paddA24{
    padding: 24px !important;
}
.pb-4px{
    padding-bottom: 4px !important;
}
.px-8px{
    padding-left: 8px !important;
    padding-right: 8px !important;
}
.px-12px{
    padding-left: 12px !important;
    padding-right: 12px !important;
}
.mar-e-8px{
    margin-right: 8px;
}

.row-5{
    margin: 0 -5px;
    .col{
        padding: 0 5px 10px;
    }
}


.mbsc-font{
font: inherit;
}
.custom-event-class{
    .fs-16{
        font-size: 16px;
    }
    .fs-12{
        font-size: 12px;
    }
    .fs-13{
        font-size: 13px;
    }
}

.text-justify{
    text-align: justify;
}

.mbsc-schedule-event-hover{
    .custom-event-class{
        .opacity-60{
            opacity: 0.90 !important;
        }
        .opacity-30{
            opacity: 0.60 !important;
        }
    }
}

.text-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mbsc-popup-wrapper{
    background-color: rgba(0,0,0,0.2);
}

.mbsc-timeline-resource-col{
    width: 200px;
}
.min-h-inherit{
    min-height: inherit;
}

.mbsc-ios.mbsc-segmented-selectbox-inner{
    height: 101%;
}
.bg-white{
    &.rounded-pill{
        .mbsc-segmented-item{
            vertical-align: middle;
        }
    }

}
.py-8px{
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}
.py-4px{
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}
.customCheck{
    + a{
        max-width: 420px !important;
    }
    + span{
        width: 525px !important;
    }
}

.employee-shifts-popup{
    .customCheck{
        + span{
            width: 315px !important;
        }
    }
}

.resize-none{
    resize: none !important;
}

.mbsc-popup-body{
    transform: none !important;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title{
    display: none
}

.commonMidpopup{
    .min-h-65px{
        + .md-tooltip-header{
            z-index: 2 !important;
        }
    }
    .md-tooltip-info{
        line-height: 32px;
    }
    &.assignPopup{
        .mbsc-popup-content {
        }
        .mbsc-popup-buttons{
            justify-content: end;
        }
    }
}

.plannPopup{
    .md-tooltip-header{
        border-bottom: 1px solid #DADCE0;
    }
    .mbsc-popup-buttons{
        border-color: #DADCE0;
    }
    .row{
        > .col{
            > span,
            > strong{
                line-height: 24px;
                display: inline-flex;
            }
            &.flex-column{
                > span,
                > strong{
                    width: 100%;
                }
            }
        }
    }

}

.remove_primary{
    background-image: none !important;
}

.mwviewTooltip{
    .md-tooltip-info{
        + .md-tooltip-info{
            border-top: 1px solid #DADCE0;
            margin-top: 0.3rem;
        }
    }
    .md-tooltip-view-button{
        &.border-primary{
            &:hover{
                background-color: var(--kt-primary) !important;
                color: #ffffff !important;
                opacity: 1;
                .svg-icon{
                    color: #ffffff !important;
                }
            }
        }
    }
}

.mbsc-ios.mbsc-popup-arrow{
    background-color: #ffffff;
}

input-wrapper {
    margin-bottom: 0.5em;
  }

  .input-wrapper.with-suffix {
    position: relative;
    max-width: 150px;
    overflow: hidden;
  }

  .input-wrapper.with-suffix input {
    margin: 0;
    width: 100%;
    outline: 0;
    padding-left: 4px;
    padding-right: 16px;
  }

  .input-wrapper.with-suffix .suffix {
    position: absolute;
    padding-left: 6px;
    top: 2px;
    pointer-events: none;
    width: 100%;
  }

  .input-wrapper.with-suffix .suffix span {
    user-select: none;
    pointer-events: none;
  }

  .input-wrapper.with-suffix .suffix .filler {
    display: inline-block;
    white-space: pre; /* Allow more than two whitespaces to be rendered */
    color: rgba(0, 0, 0, 0.0);
    background: rgba(255, 0, 0, 0.3);
    max-width: calc(100% - 16px);
  }


.height100{
    height: 100vh;
}

// @import "custom-large";
.primeDiv{
    .basic-multi-select{
        > .select__control{
            > .select__value-container{
                .select__multi-value{
                    + div:not(.select__multi-value){
                    }
                }
            }
        }
    }

    .basic-multi-select-register{
        > .select__control{
            > .select__value-container{
                .select__multi-value{
                    + div:not(.select__multi-value){
                        position: absolute;
                        right: 0.5rem;
                    }
                }
            }
        }
    }
}
.select__menu{
    > div{
        -webkit-box-shadow: 0px 8px 16px rgba(6, 12, 28, 0.14);box-shadow: 0px 8px 16px rgba(6, 12, 28, 0.14);border-radius: 4px;
        > div{
            font-size: 16px;
            font-weight: 400;
            color: var(--kt-dark);
            line-height: 1.5;
        }
    }
}


.setCenter{
    > .table{
        > tbody{
            > tr{
                > td:first-child{
                    .d-flex{
                        > h4{
                            margin-bottom: 0;
                        }
                    }
                }
                > td:not(:first-child){
                    text-align: center;
                    > div.d-flex{
                        display: inline-flex !important;
                        &.col-md-8{
                            width: auto;
                            vertical-align: middle;
                            .col-lg-8{
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}


.select2-container{    
    .select2-selection--multiple{
        border-color: #A4A6AA !important;
        padding: 0 !important;
    }
    .select2-selection{
        background: #ffffff url(icons/general-drop-arrow.svg) no-repeat calc(100% - 8px) 12px /12px auto;
    }
        
    .select2-search--inline{
        .select2-search__field{
            margin: 0 !important;
            height: 32px !important;
            padding-top: 0.3rem !important;
            padding-left: calc(0.75rem + 2px) !important;
            font-size: 16px !important;
            color: var(--kt-dark) !important;
            font-weight: 400 !important;
            font-family: "Source Sans Pro", sans-serif !important;
            &::placeholder {
                color: var(--kt-dark) !important;
                opacity: 1;             
            }    
            &:-ms-input-placeholder {                
                color: var(--kt-dark) !important;
            }        
            &::-ms-input-placeholder {             
                color: var(--kt-dark) !important;
            }            
        }     
    }

    .select2-selection__choice{
        vertical-align: top !important;
        margin-top: 0 !important;
        background: #EAEDF2 !important;
        border-radius: 3px !important;
        min-height: 24px;        
        border: 0 !important;
        margin: 3px 0 3px 4px !important;
        padding: 0 25px 0 0 !important;

        .select2-selection__choice__display{
            padding: 0 0 0 8px !important;
            font-size: 16px !important;
            font-weight: 400 !important;
            line-height: 24px !important;
            color: var(--kt-dark) !important;
        }

        .select2-selection__choice__remove{
            left: auto !important;
            right: 0 !important;
            border: 0 !important;            
            width: 24px !important;
            height: 24px !important;
            background: url(icons/close-icon.svg) no-repeat center center;
            > span{
                display: none !important;
            }
        }
    }

}

.select2-container{
    .select2-dropdown{
        box-shadow: 0px 8px 16px rgba(6, 12, 28, 0.14) !important;
        border-radius: 4px !important;
        border: 0 !important;
        margin-top: 5px;
        > span{
            box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),0 4px 11px hsla(0, 0%, 0%, 0.1);
            padding: 5px 0;
            min-height: 30px;
        }
        .select2-results__option{
            font-size: 16px !important;
            padding: 8px 12px !important;
            font-weight: 400 !important;
            color: var(--kt-dark) !important;
            line-height: 1.5 !important;            
        }
        .select2-results__option--highlighted{
            background-color: #DEEBFF !important;
        }
        .select2-results__option--selected{           
           display: none !important;
        }
    }
}
    



.basic-multi-select{
    &.statusSelect{
        > .select__control{
            border: 0;
            min-height: 24px;
            .select__value-container{
                .select__placeholder{
                    padding: 0;
                }
            }
        }
    }
    &.value400{
        .select__multi-value{
            max-width: 400px !important;
        }        
    }
    &.value306{
        .select__multi-value{
            max-width: 300px !important;
        }
    }    
    &.hideseachTxt{
        .select__multi-value{
            + input{
                position: absolute;    
            }    
        }
    }
    &.min-w-1px{
        > .select__control{
            > .select__value-container{
                min-width: 1px;
            }
        } 
    }
    > .select__control{
        border: 1px solid #A4A6AA;
        min-height: 32px;
        line-height: 1;
        align-items: flex-start;
        > .select__value-container{
            padding: 0;
            padding:0;
            font-size: 16px;
            font-weight: 400;
            color: var(--kt-dark);
            min-height: 30px;
            min-width: 100px;
            padding-right: 1rem;
            > div:last-child:not(.select__placeholder){
                padding-left: 0.75rem;                
            }
            .select__placeholder{
                color: var(--kt-dark);
                padding-left: 0.75rem;
                + div{
                    padding-left: 0.75rem;
                }
            }
            .select__multi-value{
                margin: 0;
                background: #EAEDF2;
                border-radius: 3px;
                min-height: 24px;
                align-items: center;
                // max-width: 100px;
                margin: 3px 0 3px 4px;
                max-width: 90%;                
                + div:not(.select__multi-value){
                    // position: absolute;
                    // right: 0.5rem;
                }
                > .select__multi-value__label{
                    padding: 0 0 0 8px;
                    font-size: 100%;
                    font-weight: 400;
                    line-height: 24px;
                    color: var(--kt-dark);
                }
                > .select__multi-value__remove{
                    min-height: 24px;
                    min-width: 24px;
                    background: url(icons/close-icon.svg) no-repeat center center;
                    svg{
                        opacity: 0;
                    }
                }
            }
        }
        > .select__indicators{
            background: url(icons/general-drop-arrow.svg) no-repeat 0 center /12px auto;
            width: 20px;
            height: 30px;
            *{
                display: none;
            }
            .select__indicator-separator{
                display: none;
            }
            .select__dropdown-indicator{
                padding: 0
            }
        }
    }
    &.disablecursor{
        pointer-events: all;
        *{
            pointer-events: none;
        }
        .select__control{
            background-color: var(--kt-input-disabled-bg) !important;        
        }            
        * {
            cursor: not-allowed;

        }
    }   
    
    &.h-40px{     
        > .select__control{
            min-height: 40px;            
            > .select__indicators,
              .select__value-container{
                min-height: 40px;
            }
        }
    }

}

.mbsc-ios.mbsc-textfield-inner.mbsc-disabled{
    opacity: 1;
    .mbsc-disabled{
        background-color: var(--kt-input-disabled-bg) !important;
        user-select: none;
    }        
}


.mbsc-disabled{
    cursor: not-allowed;
}

.basic-multi-select-register{
    > .select__control{
        border: 1px solid #20519D;
        border-radius: 0.77rem;
        min-height: 42px;
        line-height: 1;
        align-items: flex-start;
        > .select__value-container{
            padding: 0;
            padding:0;
            font-size: 16px;
            font-weight: 400;
            color: var(--kt-dark);
            min-height: 30px;
            min-width: 100px;
            padding-right: 1rem;
            > div:last-child:not(.select__placeholder){
                padding-left: 0.75rem;
                min-height: 37px;
            }
            .select__placeholder{
                color: var(--kt-dark);
                padding-left: 0.75rem;
                + div{
                    padding-left: 0.75rem;
                }
            }
            .select__multi-value{
                margin: 0;
                background: #EAEDF2;
                border-radius: 3px;
                min-height: 30px;
                align-items: center;
                margin: 0px 0 0px 4px;
                + div:not(.select__multi-value){

                }
                > .select__multi-value__label{
                    padding: 0 0 0 8px;
                    font-size: 100%;
                    font-weight: 400;
                    line-height: 24px;
                    color: var(--kt-dark);
                }
                > .select__multi-value__remove{
                    min-height: 24px;
                    min-width: 24px;
                    background: url(icons/close-icon.svg) no-repeat center center;
                    svg{
                        opacity: 0;
                    }
                }
            }
        }
        > .select__indicators{
            background: url(icons/general-drop-arrow.svg) no-repeat 0 center /12px auto;
            width: 20px;
            height: 41px;
            *{
                display: none;
            }
            .select__indicator-separator{
                display: none;
            }
            .select__dropdown-indicator{
                padding: 0
            }
        }
    }
}


.haseDatepicker{
    label{
        &.mbsc-textfield-wrapper{
            &::after{
                display: none;
            }
        }
    }
    .mbsc-textfield{
        height: 32px;
        padding: 0 20px 0 0;
        color: var(--kt-primary);
        font-size: 16px;
        font-weight: 600;
        text-align: right;
        width: 175px;
    }
}

.switchBtns{
    label:not(.mbsc-segmented-item-selected){
        .mbsc-ios.mbsc-segmented-button.mbsc-button{
            color: var(--kt-primary);
        }
    }
    label{
        &.mbsc-segmented-item{
            + .mbsc-segmented-item{
                border-left: 1px solid var(--kt-primary);
            }
        }
    }
}

.card{
    .card-header{
        .card-title{
            &.fs-18{
                font-size: 18px;
            }
        }
    }
}

.haveSkill{
    > tr{
        > td:nth-child(2){
            font-weight: 600;
        }
    }
}
.hasComa:not(:last-child){
    &::after{
        content: ',';
        margin-right: 5px;
    }
}
.refreshPopup{
    .mbsc-ios.mbsc-form-control-wrapper:after{
        display: none;
    }
}

.syncProgress{
    .card-body{
        padding: 0;
    }
    .apexcharts-canvas{
        margin: auto;
    }
}


.select__menu-list {
    max-height: 170px !important;
  }

  .fv-plugins-message-container{
    .fv-help-block {
        font-size: 14px;
    }
  }

div{
    &.form-control{
        .mbsc-textfield{
            border-radius: 0.31rem;
            font-size: 16px !important;
        }
    }
}
#kt_general_setting_details{
    .mbsc-textfield,
    .form-select,
    .form-control{
        &:focus{
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(22, 67, 135, 0.25) !important;
        }
    }
}

.creatDatepicker{
    .mbsc-ios{
        &.mbsc-textfield{
            border: 1px solid #A4A6AA;
            height: 32px;
            border-radius: 4px;
            padding-left: 0.75rem;
        }
        &.mbsc-form-control-wrapper.mbsc-ltr:after{
            display: none;
        }
        &.mbsc-form-control-wrapper{
            margin: 0;
            overflow: inherit;
        }
    }
}    

.projectScreen{

    .md-timeline-template-event{
        .brtr{
            border-radius: 4px 4px 0 0;
        }
        .brbr{
        }
    }
    .mbsc-timeline.mbsc-ltr .mbsc-schedule-event-inner{
        left: 550px;
    }
    .mbsc-timeline-resource-title{
        font-size: 16px !important;
        font-weight: 600 !important;
        padding: 0;
    }
    .mbsc-timeline-resource-header{
        padding: 0;
        height: 100%;
        .md-resource-header-template-title{
            line-height: 1.2;
            height: 100%;
            display: flex;
            .md-resource-header-template-seats{
                display: flex;
                align-items: center;
                justify-content: center;
                color: #181833;
                font-weight: 600;
                font-size: 16px;
            }           
            > div:not(.md-resource-header-template-name){
                width: 105px;
                height: 100%;
                border-left: 1px solid #ccc;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #181833;
                font-weight: 600;
                font-size: 16px;
                padding: 0 10px;
            }
            .md-resource-header-template-date{
                width: 130px !important;
            }
            .md-resource-header-template-status{
                width: 150px !important;
            }
            .md-resource-header-template-name{
                width: auto;
                flex: 1;
            }
        }
    }

    .mbsc-timeline-resources{
        .md-resource-header-template-name{

            .md-rect-bg-task,
            .md-rect-bg-phase,
            .md-rect-bg-project,
            .md-rect-bg-company{
                > span{
                    color:  #181833;
                    display: inline-flex;
                    align-items: flex-start;
                    min-height: 20px;
                    font-size: 14px;        
                    &:before{
                        content: '';
                        margin-right: 6px;
                        width: 16px;
                        height: 16px;
                        min-width: 16px;
                        min-height: 16px;                        
                    } 
                    > small{
                        font-size: 100%;
                    }           
                }
            }

            .md-rect-bg-company{                
                font-weight: 600;
                .md-rect-bg-company-span{
                    font-size: 16px;
                    &::before{
                        background: url(icons/timline-company-icon.svg) no-repeat center center / cover;                        
                    }
                }
            }
            .md-rect-bg-project{                
                font-weight: 600;
                .md-rect-bg-project-span{
                    &::before{
                        background: url(icons/timline-project-icon.svg) no-repeat center center / cover;
                        content: '';
                        width: 14px !important;                        
                        min-width: 14px !important;                        
                    }
                }                
            }
            .md-rect-bg-phase{                
                font-weight: 600;
                font-style: italic;
                .md-rect-bg-phase-span{
                    &::before{
                        background: url(icons/timline-phase-icon.svg) no-repeat center center / cover;
                    }
                }                
            }
            .md-rect-bg-task{                
                font-weight: 400;      
                span{
                    &::before{
                        display: none;
                    }
                }          
            }    
        }                        
        .mbsc-timeline-resource{
            position: relative;
            z-index: 1;
            background-color: #ffffff;
            overflow: auto;
        }

        .falseRow,
        .md-rect-bg-company,
        .md-rect-bg-project,
        .md-rect-bg-phase{
            &::before{
                position: absolute;
                inset: 0;
                content: '';              
                 z-index: -1;
            }
        }

        .md-rect-bg-project{
            &::before{
                background: rgba(230,230,230,0.25);               
            }
        }

        .md-rect-bg-phase{
            &::before{
                background: rgba(230,230,230,0.25);
            }
        }
        .falseRow,
        .md-rect-bg-company{
            &::before{
                background: rgba(230,230,230,0.25);
                opacity: 1;
            }
        }
        

        .mbsc-timeline-resource-icon{            
                &.mbsc-icon{
                    &.mbsc-ios{
                        & + .mbsc-timeline-resource-title{
                            .md-rect-bg-project{
                                &::before{
                                    opacity: 1;
                                }
                            }
                            .md-rect-bg-phase{
                                &::before{
                                    opacity: 1;
                                }                                
                            }
                            .md-rect-bg-company{
                                &::before{
                                    opacity: 1;
                                }                                
                            }
                        }
                    }
                }
        }


    }

    .mbsc-timeline-grid{
        .mbsc-timeline-row{
            &.md-rect-bg-project,
            &.md-rect-bg-phase{
                background: rgba(230,230,230,0.25);
            }
        }        
    }
    .md-rect-bg-company-timeline{
        background: #c7d5f0;
        z-index: 1;
    }
    .md-rect-bg-project-timeline{
        background: #dae4f9;
        z-index: 1;
    }
    .md-rect-bg-phase-timeline{
        background: #fcf6f6;
        z-index: 1;
    }
    .mbsc-ios.mbsc-timeline-column{
        z-index: 1;
    }
    .mbsc-schedule-invalid-text{
        font-size: 20px;
        color: black;
        text-align: left;
        align-items: center;
        font-weight: bold;
        z-index: 999999;
        margin-left: 3%;
    }
    .mbsc-timeline-resource{
        > .mbsc-timeline-resource-icon{
            background: url(icons/right-arrow-new.svg) no-repeat center center / 20px auto;            
            margin: 12px 5px 0 20px;
            width: 20px;
            height: 20px;
            transition: 300ms all;
            &.openArrow{
                transform: rotate(90deg);
                -webkit-transform: rotate(90deg);                
            }
        }
    }
    .mbsc-timeline-resource{
        height: auto;
        min-height: 100px;
    }

    .mbsc-timeline-resource{
        &:not(.mbsc-timeline-parent){
            &:has(.md-rect-bg-company){
                padding-left: 1.75em !important;
            }
            &:has(.md-rect-bg-phase){
                .md-rect-bg-phase{
                    padding-left: 25px;
                }
            }
            &:has(.md-rect-bg-project){
                .md-rect-bg-project{
                    padding-left: 25px;
                }
            }
            &:has(.md-rect-bg-task){
                .md-rect-bg-task{
                    padding-left: 25px;
                }
            }
        }
    }

    .mbsc-timeline-resource{
        &:has(.md-rect-bg-company){
        }
        &:has(.md-rect-bg-phase){
        }
        &:has(.md-rect-bg-project){
        }
        &:has(.md-rect-bg-task){
        }


    }

    .mbsc-timeline-resource-col{
        width: 550px !important;
    }

    .mbsc-timeline-resource, .mbsc-timeline-row{
        height: 3.25em;
    }
    .mbsc-timeline-resource-title{
        line-height: 1.2;
        padding-right: 0;
        height: 100%;
        width: 100%;
    }

    .mbsc-timeline-resource{
        align-items: flex-start !important;
    }

    .picRight{
        .btn{
            &[value="false"]{
                background-color: transparent !important;
                color: var(--kt-primary) !important;
                &:active{
                    color: var(--kt-primary) !important;
                }
                &:hover{
                    background-color: var(--kt-primary) !important;
                    color: #ffffff !important;
                }
            }
            &[value="true"]{
                background-color: var(--kt-primary) !important;
                color: #ffffff !important;
            }
        }
    }


}

.isoffline {
    cursor: not-allowed !important;
    opacity: 0.5;

    a{
        pointer-events: none;
    }
}

.disablecursor {
    cursor: not-allowed;
}

.isofflinebutton {
    opacity: 0.5 !important;
}


.isdisable {
    pointer-events: none;
}

#jsd-widget{
    bottom: 4px !important;
    right: 85px !important;
}

.form-control{
    .bg-transparent[disabled],
    &:disabled{
        background-color: var(--kt-input-disabled-bg) !important;
        border-color: var(--kt-input-disabled-border-color) !important;
        color: var(--kt-input-disabled-color) !important;
        cursor: not-allowed;
    }
}

.select__option--is-disabled{
  color: #919196 !important; /* Change this to the desired color */
}


tr:has(.user_inactive) td:not(:last-child){
    cursor: not-allowed;
    opacity: 0.5;
}

.user_inactive .form-check-input {
    background:#c7c6c6 !important
}

.ck.ck-balloon-panel {
    z-index: 10054 !important;
}

.bg-red{
    background-color: #ff0000;
}

.text-danger-light{
    color: #ff9fb6;
}

//card css

.form-container {
    width: 400px;
    font-size: 16px;
  }

  .form-container label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #32325d;
  }

  .form-container{
    .switchBtns{
        .mbsc-segmented-item{
            margin-bottom: 0;
        }
    }
   }

  .form-container .card-row {
    margin-bottom: 10px;
  }

  .form-container .split-card-container {
    display: flex;
    align-items: center;
  }

  .form-container .split-card-container .card-element-container {
    flex: 1;
    border: 1px solid var(--kt-input-border-color);
    border-radius: 4px;
    background-color: #fff;
  }

  .form-container .split-card-container .card-element-container .StripeElement {
    height: 32px;
    padding: 8px 13px;
    border: none;
    outline: none;
    font-size: 16px;
    &.border-1{
        border: 1px solid var(--kt-primary);
    }
  }

  .elementNew{
    .StripeElement{
        height: 40px !important;
        padding: 10px 1rem 0 !important;        
    }
  }

  .assignedResource{
    img{
        border: 1px solid #F5CFCF;
        vertical-align: top;
        &:not(:last-child){
            margin-right: -5px;
        }
    }
  }

  .assiresTooltip{
    .tooltip-inner{
        padding: 12px !important;
        min-width: 200px
    }
    &.min-w-250{
        .tooltip-inner{         
            min-width: 250px
        }        
    }
  }

.collapsDatas{
    .condProgress{
        &:hover{
            + .mbsc-schedule-event-inner{
                > .tooltip{
                    opacity: 1;
                    visibility: visible;
                    pointer-events: auto;
                }
            }
        }
    }
    .mbsc-schedule-event-inner{
        overflow: visible;
        > .tooltip{
            opacity: 0;
            visibility: hidden;
            transform: none;
            pointer-events: none;
        }
    }
    .eventBody{
        padding: 0 !important;
        .h-82px{
            height: auto !important;
            .hourDiv {
                margin-bottom: 0 !important;
            }
        }
    }
    .brtr + div,
    .brtr + div + div,
    .assignedResource,
    .hourDiv > small,
    .seDates{
        display: none !important;
    }
    .progress{
        &.h-8px{
            height: 4px !important;
        }
    }

    .condProgress{
        display: flex !important;
        z-index: 11;
    }

}


//new added for resources
.projectScreen{
    .md-resource-header-template-cont{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        display: flex;
        line-height: 1.2;
        height: 100%;
        h3{
            margin-bottom: 0;
        }
        .md-resource-header-template-name{
            flex: 1;
        }
        > div:not(.md-resource-header-template-name){
            width: 105px;
            min-width: 105px;
            border-left: 1px solid #ccc;
            padding-left: 10px;
            padding-right: 10px;
        }
        .md-resource-header-template-name{
            padding-top: 13px;
            overflow: auto;
        }
        .md-resource-header-template-date{            
            width: 130px !important;
            padding: 15px 5px 0 !important;
        }
        .md-resource-header-template-hours {
            padding-top: 15px;
        }
        .md-resource-header-template-status{
            padding-top: 7px;
            width: 150px !important;
            .basic-multi-select{
                > .select__control{
                    border: 0;
                    background: transparent;
                    > .select__value-container{
                        min-width: 1px;
                        padding-right: 0;
                        .select__input-container{
                            padding-left: 0;
                        }
                        .select__multi-value{
                            background: transparent;
                            max-width: 75%;
                            padding: 0;
                            .select__multi-value__remove{
                                display: none !important;
                            }
                        }
                    }
                } 
            }
        }
        .md-resource-header-template-seats{
            padding-top: 8px;
            text-align: center;
        }
    }
    .mbsc-timeline-day{
        min-width: 140px;
    }
    .mbsc-timeline-header-week-text{
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        .md-date-header{
            margin: 0;
            padding: 0;
        }
        .md-date-header-day-name{
            font: 600 16px/1.25 "Source Sans Pro", sans-serif;
            color: #000000;
            display: inline-flex;
            background: #DBE1EA;
            border-radius: 50px;
            padding: 6px 12px;
        }
    }
}


.mbsc-timeline-resource-empty .mbsc-timeline-resource-header{
    padding: 0;
    height: 100%;
    .md-resource-header-template-title{
        font-size: 16px;
        font-weight: 600;
        height: 100%;
        line-height: 80px;
        .md-resource-header-template-seats{
            width: 105px;
        }
        .md-resource-header-template-name{
            padding-left: 20px;
        }
    }
}

.md-resource-header-template .mbsc-timeline-resource-col {
    width: 230px;
}

.md-resource-header-template .mbsc-timeline-resource-header,
.md-resource-header-template .mbsc-timeline-resource-title {
    padding: 0;
}

.md-resource-header-template .mbsc-timeline-resource-title {
    height: 100%;
}

.md-resource-header-template-name {
    height: 100%;
    display: inline-block;
    padding: 0 5px;
}

.md-resource-header-template-seats {
    border-left: 1px solid #ccc;
    width: 105px;
    height: 100%;
    float: right;
    padding: 0 5px;
}

.md-resource-header-template-title {
    font-weight: 600;
    line-height: 56px;
}

@supports (overflow:clip) {
    .md-resource-header-template.mbsc-ltr .mbsc-schedule-event-inner {
        left: 230px;
    }
    .md-resource-header-template.mbsc-rtl .mbsc-schedule-event-inner {
        right: 230px;
    }
}

.tooltip{
    -webkit-transform: translate(-110%,-110%);
    transform: translate(-110%,-110%);
}


.btn.btn-outline-primary.focus-none-outline-primary:focus:not(.btn-outline-primary-active){
    color: var(--kt-primary) !important;
}
.focus-none-outline-primary{    
    &:not(.btn-outline-primary-active){
        &:focus{
            background-color: transparent !important;
            color: var(--kt-primary) !important;
        }
    }
}

.btn-outline-primary-active{
    background-color: var(--kt-primary) !important;
    color: #fff !important;
}

.todoSidebar {
    .page-item{
        margin-right: 0.1rem;
    }
}

.viewSidebar{
    .page-item{
        margin-right: 0.1rem;
    }
}

.row16{
    margin: 0 -8px;
    .col{
        padding-left: 8px;
        padding-right: 8px;
    }
}

.row24{
    margin: 0 -12px;
    .col{
        padding-left: 12px;
        padding-right: 12px;
    }
}
.row40{
    margin: 0 -20px;
    > .col{
        padding-left: 20px;
        padding-right: 20px;
        .row40{
            > .col{
                &:nth-child(odd){
                    padding-right: 12px;
                }
                &:nth-child(even){
                    padding-left: 12px;
                }                
            }
        }
    }
}

.newPopup{
    .md-tooltip-info{
        padding: 0;
        .stepsWrapper{
            padding: 16px 25px;
        }    
    }
    .accRow{
        margin: 0 -3px 8px;
    }
    .borderCol {
        width: calc(100% + 3px) !important;
        margin-left: -3px !important;
    }
    .accordion{
        margin-top: -8px;
    }
}

.popup640{
    .mbsc-popup-center{
        width: 49.23rem !important;
        max-width: 49.23rem !important;
        height: auto !important;
        max-height: 100% !important;
    }
    .paddA24{
        max-height: 365px;
        overflow-y: auto;
    }
}

.popup1000{
    .mbsc-popup-center{
        width: 77rem !important;
        max-width: 77rem !important;
        height: auto !important;
        max-height: 100% !important;
    }
    .stepsWrapper{
        max-height: 680px;
        height: 100vh;
        overflow-y: auto;
    }
}

.accHeader{
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.gap-24px{
    gap: 24px;
}

body .mbsc-popup-wrapper{
    pointer-events: auto;
    z-index: 999999;
}

.scrollNone{
    .mbsc-popup-content{
        overflow: hidden;
    }
}

.removeTxtbox{
    .select__value-container{
        .select__multi-value{
            max-width: 87%
        }
        .select__input-container{
            max-width: 9%;
        }
    }
}

.yearView, .monthView{
    .mbsc-ios{
        &.mbsc-schedule-event{
            pointer-events: none;
            *{
                pointer-events: auto;
            }
        }
    }
}

.z-fi9{
    z-index: 99999 !important;
}

.rounded-50{
    border-radius: 50px;
}
.max-w-900px{
    max-width: 900px;
}

.notifyAlert{
    margin: 23px 23px;
    + .notifyHeight{
        height: calc(100% - 82px) !important;     
        .mbsc-eventcalendar{
            max-height: calc(100vh - 150px) !important;
        }
    }
}
.notifyAlert + .container  > .py-6:first-child,
.notifyAlert + .py-6,
.notifyAlert + .dashMain   {
    padding-top: 0 !important;
}


body:has(.notifyAlert) #kt_content_container{
    overflow: hidden !important;
}

.fs-10px{
    font-size: 10px;
}
.btn{
    &.btn-btnGray{
        &:hover{
            color: #ffffff !important;
            .svg-icon{
                color: #ffffff !important;
            }
        }
    }
}

.dropdown{
    .transDropdown{
        padding: 0 !important;
        background-color: transparent !important;
        color: var(--kt-text-gray-900) !important;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        &::after{
            border: 0 !important;
            background: var(--kt-text-gray-900);
            mask-image: url(icons/down-arrow.svg) !important;
            transform: none !important;
            width: 10px;
            height: 6px;
            mask-repeat: no-repeat;
            -webkit-mask-size: contain !important;
            mask-size: contain !important;            
            vertical-align: middle;
            margin: 0 0 0 5px;
        }
    }
}  

.dropdown {
    .no-license {
        color: red !important; /* Set the background color to red */
        pointer-events: none !important; /* Disable mouse events */
    }
}

div:where(.swal2-container){
    z-index: 99999 !important;
}

.no-license {
    color: red !important;
}

.default-cursor{
    cursor: default !important;
}

.mbsc-calendar-cell.mbsc-disabled, .mbsc-calendar-cell.mbsc-disabled *{
    cursor: not-allowed !important;
}

body{
    [data-amplify-authenticator]{
        [data-amplify-container]{
            width: 400px;
            [data-amplify-router]{
                border: 0;       
                background-color: transparent;
                box-shadow: none;
                [data-amplify-form]{
                    padding: 0;
                }
                .amplify-flex{
                    gap: 0;
                    .amplify-alert{
                     margin: -2.5rem 0 4rem;   
                     .amplify-alert__icon{
                        padding-right: 0.5rem;
                        .amplify-icon{
                            line-height: 0.5;
                            vertical-align: middle;
                        }
                     }   
                     .amplify-alert__dismiss{
                        margin-left: 0.5rem;
                        background: #e9cccc !important;
                     }
                    }
                }
                .amplify-label{
                    font: 600 16px/1.2 "Inter", sans-serif;
                    color: var(--kt-text-dark) !important;
                    margin-bottom: 0.5rem;
                } 
                .amplify-textfield{
                    + .amplify-textfield{
                        margin-top: 2rem;
                        margin-bottom: calc(2.25rem + 24px);
                    }
                }
                [data-amplify-router-content]{
                    position: relative;
                }
                .forgotLink{
                    text-decoration: underline;
                    font-size: 16px;
                    color: var(--kt-text-primary) !important;
                    padding: 0;
                    position: absolute;
                    right: 0;
                    border: 0;
                    margin-top: -97.5px;
                    background-color: transparent !important;
                }                
                [data-amplify-qrcode]{                    
                    margin: 0 auto 1rem;
                }                

                .amplify-passwordfield{
                    .amplify-input{
                        border-right: 0;
                        border-radius: 0.425rem 0 0 0.425rem !important;
                    }    
                    .amplify-field-group__outer-end{
                        .amplify-button{
                            background: #FFFFFF !important;
                            border-color: #20519D;
                            color: var(--kt-text-primary) !important;
                            border-left: 0 !important;
                            border-radius: 0 0.425rem 0.425rem 0 !important;
                        }
                    }
                }     
                .amplify-input{
                    height: 45px;                    
                    box-shadow: none !important;
                    border: 1px solid #20519D;
                    border-radius: 0.425rem !important;
                    padding: 0 1.5rem;
                    background-color: #ffffff;       
                    font-size: 1.075rem;             
                    color: var(--kt-input-color);
                    font-family: "Source Sans Pro", sans-serif;
                    font-weight: 400;
                }  
                .loginMicro{
                    &::before{
                        width: 24px;
                        height: 24px;
                        content: '';
                        background: url(icons/microsoft.svg) no-repeat center center / cover;
                        margin-right: 0.5rem;
                    }
                    font-size: 1.075rem;
                }
                .amplify-button{
                    outline:  none !important;
                    box-shadow: none !important;
                }
                .amplify-button:not(.forgotLink):not(.loginMicro):not(.amplify-alert__dismiss){
                    height: 45px;
                    background-color: var(--kt-primary);
                    color: #ffffff;
                    font: 600 1.075rem/1.2 "Inter", sans-serif;                    
                    -webkit-border-radius: 6px;
                    border-radius: 6px;
                    &:hover{
                        background-color: var(--kt-primary-active);
                    }
                }
                [data-amplify-authenticator-setup-totp],
                [data-amplify-authenticator-forcenewpassword],
                [data-amplify-authenticator-confirmresetpassword],
                [data-amplify-authenticator-confirmsignin],
                [data-amplify-authenticator-forgotpassword]{
                    .amplify-flex{
                        + .amplify-flex{
                            margin-top: 2rem;
                        }
                    }
                    .amplify-heading{
                        font-size: 1.75rem;
                        color: var(--kt-text-dark);
                        font-weight: 600;
                        font-family: "Source Sans Pro", sans-serif;
                        text-align: center;
                        margin-bottom: 2.25rem;
                    }
                    .amplify-textfield{
                        .amplify-label{
                            display: none;
                        }
                    }
                    .amplify-button--small{
                        display: inline-flex !important;
                        width: auto !important;
                        background-color: transparent !important;
                        text-decoration: underline;
                        height: auto !important;
                        margin-top: 1.25rem !important;
                        padding: 0;
                        color: var(--bs-link-color) !important;
                        font-family: "Source Sans Pro", sans-serif;
                        font-weight: 400 !important;
                        font-size: 1.25rem !important;
                    }
                }
                [data-amplify-authenticator-forcenewpassword]{
                    .amplify-textfield{
                        + .amplify-textfield{
                            margin-bottom: 2rem;
                        }
                    }    
                }
                [data-amplify-authenticator-confirmresetpassword]{
                    .amplify-textfield{
                        + .amplify-textfield{
                            margin-top: 2rem;
                            margin-bottom: 0;
                            + .amplify-textfield{
                                margin-top: 2rem;
                            }
                        }
                    }                    
                }


            }    
        }        
    }
}    
.setPointerarrow,
.assiresTooltip{    
    .tooltip-arrow{
        left: 50% !important;
        transform: translate(-50%, 0) !important;
        -webkit-transform: translate(-50%, 0) !important;
    }
}

.disabled-filter-month-year-view{
    pointer-events: none !important;
    opacity: 0.5 !important;

}


.viewSetting{
    .max-w-360px{
        max-width: 360px;
    }
}    

    .form-check-outline-primary-all,
    .form-check-outline-primary{
        > label{
            position: relative;
            &::before{
                position: absolute;
                border-radius: 50%;
                content: '';
                background-color: var(--kt-primary);
                width: 8px;
                height: 8px;
                inset: 8px 0 0 -21px;
                -webkit-transform: scale(0);
                transform: scale(0);                
                -webkit-transition: 300ms transform;
                transition: 300ms transform;
            }
        }
        .form-check-input[type="checkbox"]{
           border: 1px solid var(--kt-primary); 
           background-color: #ffffff;
           width: 21px;
           height: 21px;
           border-radius: 6px;           
           &:checked{
            background-color: var(--kt-primary);
           }
           &:not(:checked){
                &:focus{
                    border-color: var(--kt-primary);
                    background-color: #ffffff;
                }
           }           
        }
        .form-check-input[type="radio"]{
            width: 21px;
            height: 21px;
            border: 1px solid var(--kt-primary);
            background-color: #ffffff !important;
            &:checked ~ label::before{
                -webkit-transform: scale(1);
                transform: scale(1);
            }
            &:not(:checked){
                &:focus{
                    border-color: var(--kt-primary);
                    background-color: #ffffff;
                }
            }
        }
        &.form-check-outline-primary-small{
            .form-check-input[type="radio"]{
               width: 18px;    
               height: 18px;
            }
            > label{
                &::before{
                    width: 8px;
                    height: 8px;
                    inset: 7px 0 0 -19px;
                }
            }
        }
    }


.hideShowDiv{
    .form-check-input[type="radio"]{        
        &:checked ~ .radio-hidden-div{
            display: flex !important;
        }
    }   
}

.radio-hidden-div{
    display: none !important;
}

.onHoverSpan {
    opacity: 0;
}

.onDragHover {
        .onHoverSpan {
            opacity: 1;
        }
}


body{
    &:has(.show.menu-dropdown){
        .hideonHover{
            opacity: 0 !important;
        }
    }
}

.show{
    &.menu-dropdown{
        .cusTooltip{
            opacity:  0 !important;
        }
    }
}

.hideRow1{
    .colorLine1,
    .eventHeader{
        display: none !important;
    }
}

.hideRow2{    
    .colorLine2,
    .seDates{
        display: none !important;
    }
}
.hideRow3{
    .colorLine3,
    .hoursWrapper{
        display: none !important;
    }
}
.showRow2,
.showRow1{
    &:not(.showRow3){        
        .show-less-time{
            display: flex !important;
            height: 5px !important;
        }
    }
}

.showRow2:not(.showRow1):not(.showRow3){
    .colorLine2{
        top: 0 !important;
    }
}

.showRow1{
    &.showRow2{
        .colorLine2{
            &.d-none{
                + .colorLine3 {
                    display: block !important
                }
            }
        }
    }
}

.showRow2{
    &.showRow3:not(.showRow1){
        .colorLine2{
            top: 6px !important;
        }        
    }
}

.showRow1,
.showRow2{
    &.hideRow3{
        .eventBody{
            padding-bottom: 5px !important;
        }
    }
}

.showStatusColumn,
.showHoursColumn {
    .mbsc-timeline-resource-col{
        width: 600px !important;
    }
    .mbsc-schedule-event-inner{
        left: 600px !important;
    }
        
}


.showStatusColumn{
    &.showHoursColumn{
        .mbsc-timeline-resource-col{
            width: 750px !important;
        }
        .mbsc-schedule-event-inner{
            left: 750px !important;
        }        
    }   
}

.identifier .form-select {

  &:disabled {
    color: var(--kt-form-select-disabled-color) !important;
    background-color: var(--kt-form-select-disabled-bg) !important;
    border-color: var(--kt-form-select-disabled-border-color) !important;
  }
}


.hideRow1{
    &.showRow2{
        &.hideRow3{
            &.h-135px{
                height: 32px !important;
                .h-103px{
                    height: auto !important;
                    padding-bottom: 0 !important;
                }
            }
        }
    }
}


.showRow1{
    &.hideRow2{
        &.hideRow3{
            &.h-135px{
                height: 32px !important;
                .eventBody{
                    &.h-103px{
                        display: none !important;
                    }    
                }
            }
        }
    }
}

.hideRow1{
    &.hideRow2{
        &.showRow3{
            &.h-135px{
                height: 65px !important;
                .eventBody{
                    &.h-103px{
                        height: 65px !important;
                        padding-bottom: 0 !important;
                    }
                }    
            }            

        }
    }
}


.showRow1{
    &.hideRow2{
        &.showRow3{
            &.h-135px{
                height: 104px !important;
                .eventBody{
                    &.h-103px{
                        height: 72px !important;
                    }
                }    
            }
        }
    }
}


.hideRow1{
    &.showRow2{
        &.showRow3{
            &.h-135px{
                height: 104px !important;
                .colorLine2{
                    top: 0 !important;
                }
            }
        }
    }
}

.showRow1{
    &.showRow2{
        &.hideRow3{
            &.h-135px{
                height: 64px !important;
                .eventBody{
                    &.h-103px{
                        height: auto !important;
                        padding-bottom: 0 !important;
                    }    
                }                
            }
        }
    }
}

.noColumnSelected{ 
    .mbsc-timeline-resource-col{
        width: 320px !important;
    }
    .mbsc-schedule-event-inner{
        left: 320px !important;
    }        
}

.oneColumnSelected{ 
    .mbsc-timeline-resource-col{
        width: 420px !important;
    }
    .mbsc-schedule-event-inner{
        left: 420px !important;
    }        
}
.threeColumnSelected{ 
    .mbsc-timeline-resource-col{
        width: 600px !important;
    }
    .mbsc-schedule-event-inner{
        left: 600px !important;
    }        
}
.fourColumnSelected{ 
    .mbsc-timeline-resource-col{
        width: 750px !important;
    }
    .mbsc-schedule-event-inner{
        left: 750px !important;
    }        
}

.picRight{
    > .btn-outline-primary:has(:not(.text-white)){        
        &:focus{
            background-color: #ffffff;
            color: var(--kt-primary) !important;
            border-color:  var(--kt-primary) !important;
            .svg-icon{
                color: var(--kt-primary) !important;
            }
            .text-white{
                color: #ffffff !important;
            }
            &:hover{
                background-color: var(--kt-primary) !important;
                color: #ffffff !important;
                border-color:  var(--kt-primary) !important;
                .svg-icon{
                    color: #ffffff !important;
                }                
            }
        }
    }
}

.solid-border-bottom{
    border-bottom: 1px solid #E4E4E4 !important;
}

body{
    [class*=ag-theme-]{
        font-family: "Source Sans Pro", sans-serif;
        color: var(--kt-dark);
    }
    .ag-root-wrapper{
        border: 0;
    }
    .ag-header, .ag-advanced-filter-header{
        background-color: #E8ECF3;
        border-bottom-color: #E2E2E2;
    }
    .ag-row{
        border-bottom-color: #E2E2E2;
    }
    .ag-theme-quartz .ag-header-row{        
        font-size: 12px;
        .ag-header-cell-text{
            font-weight: 600;
        }
    }
    .ag-center-cols-viewport {
        min-height: unset !important;
    }

    .ag-ltr{
        .ag-header{
            max-height: 40px;
            min-height: 40px !important;
        }
    }

    .ag-header-row{
        &.ag-header-row-column{
            max-height: 40px;
            .ag-header-cell{
                max-height: 40px;
            }
        }
    }
    .ag-pinned-left-header,
    .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell){
        border-right: 0;
    }
    .ag-pinned-right-header,
    .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell){
        border-left: 0;
    }
    .ag-pinned-left-header,
    .ag-pinned-left-cols-container{
        -webkit-box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.05);
        box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.05);        
        z-index: 1;
    }
    .ag-pinned-right-header,
    .ag-pinned-right-cols-container{
        z-index: 1;
        -webkit-box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.05);
        box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.05);                
    }

    .ag-ltr .ag-cell{
        background-color: #FEFCF6;
        font-size: 12px;
    }
    .ag-theme-quartz .ag-group-expanded .ag-icon:hover, 
    .ag-theme-quartz .ag-group-contracted .ag-icon:hover{
        background-color: transparent;
        border: 0;
        box-shadow: none;
    }
    .ag-row-group{
        .ag-cell{
            background-color: #ffffff;
            font-weight: 600;
        }
    }
    .ag-header-cell, .ag-header-group-cell,    
    .ag-cell, .ag-full-width-row .ag-cell-wrapper.ag-row-group{
        padding-left: 12px;
        padding-right: 12px;
    }
    .ag-cell .ag-icon{
        background: url(icons/arrow-right-dark.svg) no-repeat center center / 6px 12px;
        &::before,
        &::after{
            opacity: 0;
        }
    }
    .ag-group-expanded{
        .ag-icon{
            background-image: url(icons/arrow-down-dark.svg);
            background-size: 12px 6px;
        }
    }
    .ag-horizontal-left-spacer,
    .ag-horizontal-right-spacer{
        opacity: 0;
    }
    
}    


.imgSpanLogo {
    display: flex;
    gap: 5px; /* Adjust spacing between images as needed */
}

.logo {
    width: 30px; /* Adjust the width as needed */
    height: 30px; /* Adjust the height as needed */
    object-fit: cover; /* Maintain aspect ratio and cover the area */
}

.me-4px{
    margin-right: 4px;
}
.me-5px{
    margin-right: 5px;
}

/* Hide show column with new design start */
.extraProgress{position: absolute;left: 0;bottom: 0;right: 0;z-index: 1;}
.rowThree{
    .progress{
        opacity: 0;
        pointer-events: none;
    }
}
.showRow1{
    &.hideRow2{
        &.hideRow3{
            .rowTwo{
                display: none !important;
            }
            .rowThree{
                height: auto !important;
                opacity: 0;
                pointer-events: none;
                > div:not(.progress){
                    display: none !important;
                }
            }
            &.hash-serviceCall{
                .headerColor{
                    height: 35px !important;
                }
                .rowOne{
                    height: 35px !important;
                    .h-32px{
                        height: 35px !important;
                    }
                    .lh-32px{
                        line-height: 35px !important;
                    }
                }
            }            
        }
    }

    &.showRow2{
        &.hideRow3{
            .rowThree{
                height: auto !important;
                opacity: 0;
                pointer-events: none;
                > div:not(.progress){
                    display: none !important;
                }
            }    
        }                     
    }

    &.showRow3{
        &.hideRow2{
            .rowTwo{
                display: none !important;
            }
            .rowThree{
                > div.h-24px{
                    height: 29px !important;                    
                    > div.h-24px{
                        height: 29px !important;                
                        &.ms-auto{
                            display: inline-flex;
                            align-items: center;
                        }    
                    }                    
                }
            }      
        }              
    }
}

.hideRow1{
    &.showRow2{
        &.showRow3{
            .headerColor,
            .rowOne{
                display: none !important;
            }
            &.rowThree{
                height: auto !important;
                opacity: 0;
                pointer-events: none;
                > div:not(.progress){
                    display: none !important;
                }
            }            
        }
        &.hideRow3{
            .headerColor,
            .rowOne{
                display: none !important;
            }
            .rowThree{
                height: auto !important;
                opacity: 0;
                pointer-events: none;
                > div:not(.progress){
                    display: none !important;
                }
            }    
        }
    }
    &.hideRow2{
        &.showRow3{
            .headerColor,
            .rowOne,
            .rowTwo{
                display: none !important;
            }     
            .rowThree{
                > div.h-24px{
                    height: 29px !important;                    
                }
            }
        }
    }        

}

/* Hide show column with new design end */

.md-timeline-template-event{
    &.rounded-4px{
        &.bg-white{
            -webkit-box-shadow: 0 0 5px rgba(0,0,0,0.05);
            box-shadow: 0 0 5px rgba(0,0,0,0.05);
        }        
    }        
}

.headerColor{
    border-radius: 4px 4px 0 0;
}
.mbsc-schedule-event-resize{
    z-index: 3;
}

.planningScreen{
    &.pScreenmain{
        .mbsc-timeline.mbsc-ltr{
            .mbsc-schedule-event-inner{
                left: 200px;
            }
        }
    }    
} 

.custom-select option:disabled {
    background-color: #f0f0f0 !important;
    color: #999 !important;
  }
  
.hasImgsspan{
    > span:not(:first-child){
        margin-left: -4px;
        position: relative;
        z-index: 2;
        &:last-child{
            z-index: 3;
        }
    }
}

.form-check-input{
    &:not(:checked){
        &:focus{
            border-color: #A4A6AA;
        }
    }
}

.dropdown-menu {
    position: absolute;
    bottom: 50px; /* Adjust based on your design */
    left: 500px;
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .dropdown-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background: #f0f0f0;
  }

body:has(.addTaskform){
    .assignPopup{
        &.tasklist{
            .mbsc-popup{
                max-width: 1000px !important;
                width: 1000px !important;
                max-height: 100% !important;
                min-height: 600px;
                .mbsc-popup-buttons{
                    border-top: 1px solid #DADCE0 !important;
                }
                .filterLeft{
                    .menu-sub{
                        .chkWrapper{
                            .form-check{
                                &.mb-3{
                                    line-height: normal !important;                                    
                                }
                            }
                        }
                    }
                }
            }
        }            
    }        
}

.taskdPicker{
    .mbsc-ios.mbsc-button{
        padding: 0;
        color: var(--kt-primary);
        font-size: 18px;
        font-weight: 600;
    }
}  

.py-9px{
    padding-top: 9px !important;
    padding-bottom: 9px !important;
}

.btn-outline{
    &.btn-outline-primary{
        &.bg-gray-100{
            background-color: var(--kt-gray-100) !important;
            &:hover{
                background-color: var(--kt-primary) !important;
            }
            &:focus:not(:hover){
                color: var(--kt-primary) !important;
            }
        }
    }
}

.hasImgsspan{
    > span{
        position: relative;
        &:nth-child(2){
            z-index: 2;
        }
        &:nth-child(3){
            z-index: 3;
        }
        &:not(:first-child){
            margin-left: -4px;
        }
    }
}

.serRow {
    &:last-child{
        .position-relative{
            &.me-5{
                .menu{
                    &.top-100{
                        top: auto !important;
                        bottom: 100% !important;
                    }
                }    
            }
        }
    }
}
.pScreenmain{
    &.monthView{
        .eventBody{
            &.p-4{
                padding: 1rem !important;
            }   
        }        
    }
    &.yearView
        .eventBody{
            &.p-4{
                padding: 1rem !important;
            }   
        }
}    
.text-break_all{
    word-break: break-all !important;
}


.mbsc-timeline{
    .mbsc-timeline-grid-scroll{
        overflow-y: scroll;
    }
}

.mb-2px{
    margin-bottom: 2px;
}
.mb-8px{
    margin-bottom: 8px ;
}
.pa-8px{
    padding: 8px;
}
.py-25px{
    padding-top: 2.5px;
    padding-bottom: 2.5px;
}
.hasImgsspan{
    > span{
        position: relative;
        &:not(:first-child){
            margin-left: -4px;
        }
        &:nth-child(2){
            z-index: 1;
        }
        &:nth-child(3){
            z-index: 2;
        }
    }        
}
.newTooltip {    
    .tooltip-inner{
        padding: 16px;
        -webkit-box-shadow: 0px 8px 16px rgba(6, 12, 28, 0.14);        
        box-shadow: 0px 8px 16px rgba(6, 12, 28, 0.14);        
        width: 400px;
        max-width: 400px;
    }
    &.pronewTooltip{
         opacity: 0;
        &.set-arrow-middle{
            .tooltip-arrow{
                left: 50% !important;
                -webkit-transform: translate(-50%,0) !important;
                transform: translate(-50%,0) !important;                
            }
        }
        &.show-arrow-top{
            .tooltip-arrow{
                left: 50% !important;
                -webkit-transform: translate(-50%,-50%) !important;
                transform: translate(-50%,-50%) !important;
                top: 0 !important;
                bottom: auto !important;
                width: 22px;
                height: 11px;
                &::before{
                    border-color: transparent;
                    border-bottom-color: #ffffff;
                    border-top: 0;
                    bottom: 0;
                }                    
            }    
        }
        &.show-arrow-bottom{
            .tooltip-arrow{
                left: 50% !important;
                -webkit-transform: translate(-50%,50%) !important;
                transform: translate(-50%,50%) !important;                
                top: auto !important;
                bottom: 0 !important;                
                width: 22px;
                height: 11px;                
                &::before{
                    border-color: transparent;
                    border-top-color: #ffffff;
                    border-bottom: 0;
                    top: 0;
                }                    
            }    
        }        
    }
}

.aside-logo{
    .infoBtn{
        background-image: url(../sass/icons/Info-white.svg);
    }    
}

.infoBtn{
    width: 16px !important;
    height: 16px !important;
    font-size: 0 !important;
    border: 0 !important;
    border-radius: 50% !important;
    background: url(../sass/icons/Info.svg) no-repeat center center / cover;
    position: relative;
    &::after{
        position: absolute;
        inset: 0;
        content: '';        
        border-radius: 50% !important;
        background: #F4C755 url(../sass/icons/Info-hover.svg) no-repeat center center / cover;
        -webkit-transition: 100ms all;
        transition: 300ms all;
        opacity: 0;
    }
    &[aria-describedby]{
        &::after{
            opacity: 1;
        }
        background: none !important;
    }    
}
body:has(.todoShow),
body:has(.tasklist){
    #tooltip-copy-to-clipboard{
        z-index: 9999999;
    }
}

.z-index-plan-bucket{
    position: relative !important;
}
#renderServiceCallTooltip {
    z-index: 999999 !important;
}
.popover{
    font-family: inherit !important;
    z-index: 99999 !important;
}
.border-1-imp{
    border-width: 1px !important;
}

/* Info button css end */
.LayoutBtns {
    .overflow-auto{
        > div{
            &:not(.bg-yellow-light){
                &:hover{
                    background-color: var(--kt-gray-100);
                }                    
            }
        }
    }
    > .btn{            
      &::after{
        width: 12px;
        height: 8px;
        content: '';
        margin-left: 6px;
        margin-right: 2px;
        background-color: var(--kt-primary);
        -webkit-mask: url(icons/down-arrow.svg)  no-repeat center right / cover;
        mask: url(icons/down-arrow.svg)  no-repeat center right / cover;
      }      
    }
}

.clamp-2-line{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.required-padd-none{
    &::after{
        padding-left: 0;
    }  
}
.row-task .ag-cell{
    background: #fefcf6 !important;
}
.row-phase .ag-cell{
    background: #fff !important;
    font-weight: 900 !important;
}
.noScroll{
    .mbsc-timeline{
        .mbsc-timeline-grid-scroll{
            overflow: hidden !important;
        }            
    }
}
.table-responsive tr + tr td .h-42px.d-flex .infoBtn {
    display: none !important;
}
#project-page{
    height: 100%;
}

.table-responsive tr + tr td .h-42px.d-flex .infoBtn {
    display: none !important;
}
.date_editSidebar{
    display: none;
}
.dateShow{
    .date_editSidebar{
        display: block;
    }  
}  

button.border-0.p-0.bg-transparent.buttn-link {
    text-decoration: underline;
        color: #164387;
}

.ag-watermark{
    display: none !important;
}