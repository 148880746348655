@import './scroller.ios.colors';

@if ($mbsc-ios-theme and $mbsc-scroller) {
  .mbsc-ios {
    &.mbsc-scroller-wheel-multi.mbsc-scroller-wheel-cont-3d {
      visibility: hidden;
    }

    &.mbsc-scroller-wheel-group-cont {
      padding: 0 0.625em;
    }

    &.mbsc-scroller-wheel-group {
      padding: 0.625em;
    }

    &.mbsc-wheel-group-checkmark {
      padding: 0.625em 0;
    }

    &.mbsc-scroller-wheel-3d {
      overflow: visible;
    }

    &.mbsc-scroller-wheel-line {
      display: block;
      z-index: 1;
      border-radius: 0.5em;
      margin: 0 0.625em;
    }

    &.mbsc-scroller-wheel-overlay {
      display: block;
    }

    &.mbsc-scroller-wheel-item {
      padding: 0 0.5em;
      font-size: 1.25em;
      text-align: center;
      box-sizing: border-box;
      border-radius: 0.5em;
    }

    &.mbsc-scroller-wheel-item-3d {
      font-size: 1.125em;
    }

    &.mbsc-wheel-item-checkmark {
      padding-left: 1.75em;
      padding-right: 1.75em;
    }

    &.mbsc-scroller-wheel-header {
      font-size: 0.875em;
    }

    /* checkmark */

    &.mbsc-wheel-checkmark {
      display: none;
      position: absolute;
      width: 1.75em;
      height: 1.75em;
      top: 50%;
      margin-top: -0.875em;

      &::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        display: block;
        opacity: 0;
        transform: rotate(-45deg);

        top: 32%;
        left: 26%;
        width: 0.75em;
        height: 0.375em;
        border: 0.125em solid currentColor;
        border-top: 0;
        border-right: 0;
        transition: opacity 0.2s ease-in-out;
      }

      &.mbsc-selected::after {
        opacity: 1;
      }

      &.mbsc-ltr {
        left: 0;
      }

      &.mbsc-rtl {
        right: 0;
      }
    }

    &.mbsc-wheel-item-multi &.mbsc-wheel-checkmark,
    &.mbsc-scroller-pointer &.mbsc-wheel-item-checkmark .mbsc-wheel-checkmark {
      display: block;
    }

    /* Desktop styling */

    &.mbsc-scroller-pointer {
      .mbsc-scroller-wheel-group-cont,
      .mbsc-scroller-wheel-group {
        padding: 0;
      }

      .mbsc-scroller-wheel-wrapper {
        padding: 0.1875em 0;
      }

      .mbsc-scroller-wheel-overlay {
        display: none;
      }

      .mbsc-scroller-wheel-item {
        font-size: 1em;
        padding: 0 0.75em;
        margin: 0 0.1875em;
      }

      .mbsc-scroller-wheel-line {
        margin: 0 0.1875em;
      }

      .mbsc-wheel-item-checkmark.mbsc-ltr {
        text-align: left;
        padding-left: 1.75em;
      }

      .mbsc-wheel-item-checkmark.mbsc-rtl {
        text-align: right;
        padding-right: 1.75em;
      }

      // Group styling

      .mbsc-scroller-wheel-header {
        font-size: 0.75em;
        font-weight: 700;
      }
    }
  }

  @include mbsc-ios-scroller('ios', $mbsc-ios-colors);
  @include mbsc-ios-scroller('ios-dark', $mbsc-ios-dark-colors);
}
