.md-work-order-checkbox-label.mbsc-checkbox {
    padding-top: 5px;
    padding-bottom: 5px;
}

.md-work-order-date {
    font-size: 14px;
    border-bottom: 1px solid #ccc;
    padding: 5px 10px;
}

.md-work-order-date-title {
    font-size: 13px;
    color: #959595;
    padding: 5px 10px;
    line-height: 18px;
}

.md-work-order-price-tag {
    display: inline-block;
    font-size: 11px;
    line-height: 16px;
    vertical-align: middle;
    border: 1px solid #959595;
    color: #959595;
    border-radius: 5px;
    margin: 0 10px;
    padding: 0px 5px;
}

.md-work-order-scheduling .mbsc-timeline-parent {
    height: 32px;
}

.md-rect-bg{
    background-color: red;
}