@import './timegrid.ios';
@import './timegrid.material';
@import './timegrid.windows';

@if $mbsc-datepicker {
  // When the calendar control is present, then the calendar specifies the height,
  // but when the row is wrapped, we need a minimum height, so the grid won't collapse
  // into 0
  .mbsc-datepicker-control-calendar .mbsc-datepicker-tab-timegrid {
    min-height: 10em;
  }

  // when there is no other control there the grid needs a minimum height
  .mbsc-datepicker-tab-timegrid {
    min-height: 19em;
  }

  .mbsc-timegrid-item.mbsc-disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  .mbsc-datepicker-tab-timegrid.mbsc-datepicker-tab {
    position: relative;
    overflow: auto;
    align-items: flex-start;
    width: 18.5em;
  }

  .mbsc-timegrid-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .mbsc-timegrid-row {
    display: table-row;
  }

  .mbsc-timegrid-cell {
    display: table-cell;
    position: relative; // do not remove!

    &.mbsc-disabled {
      cursor: not-allowed;
    }
  }

  .mbsc-timegrid-item {
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
  }
}
