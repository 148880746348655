@import './list.ios.colors';

@if ($mbsc-ios-theme and $mbsc-eventcalendar) {
  .mbsc-ios {
    &.mbsc-list-item {
      margin-top: -1px;
      padding: 1em;
    }

    &.mbsc-list-item:before,
    &.mbsc-list-item:after {
      content: '';
      position: absolute;
      right: 0;
      left: 1em;
    }

    &.mbsc-list-item.mbsc-rtl:before,
    &.mbsc-list-item.mbsc-rtl:after {
      left: 0;
      right: 1em;
    }

    &.mbsc-list-item:before {
      top: 0;
      transition: none;
    }

    &.mbsc-list-item:after {
      bottom: 0;
    }

    &.mbsc-list-item:first-child:before,
    &.mbsc-list-item:last-child:after {
      left: 0;
      right: 0;
    }

    &.mbsc-list-item.mbsc-hover,
    &.mbsc-list-item.mbsc-active {
      z-index: 3;
    }

    &.mbsc-list-item.mbsc-focus,
    &.mbsc-list-item.mbsc-selected {
      z-index: 5;
    }

    &.mbsc-list-item.mbsc-focus:before,
    &.mbsc-list-item.mbsc-focus:after,
    &.mbsc-list-item.mbsc-hover:before,
    &.mbsc-list-item.mbsc-hover:after,
    &.mbsc-list-item.mbsc-active:before,
    &.mbsc-list-item.mbsc-active:after,
    &.mbsc-list-item.mbsc-selected:before,
    &.mbsc-list-item.mbsc-selected:after {
      left: 0;
      right: 0;
    }

    &.mbsc-list-header {
      z-index: 4;
      margin-top: -1px;
      padding: 2.333334em 1.333334em 0.5em 1.333334em;
      font-size: 0.75em;
      line-height: 1.5em;
      border-top: 1px solid;
      border-bottom: 1px solid;
      text-transform: uppercase;
    }

    &.mbsc-list-header:first-child {
      border-top: 0;
      margin-top: 0;
    }

    &.mbsc-list-item-background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0.25em;
      border-radius: 0.5em;
      transition: background-color 0.15s ease-in-out;
      pointer-events: none;
    }
  }

  @include mbsc-ios-list('ios', $mbsc-ios-colors);
  @include mbsc-ios-list('ios-dark', $mbsc-ios-dark-colors);
}
