@import '../../themes/ios';

@mixin mbsc-ios-datepicker($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');
  $text: map-get($params, 'text');

  $background-alt: '';
  $background-top-bottom: '';
  $border-color: '';
  $buttons-background: '';
  $clear-hover: '';

  @if (lightness($background) > 50%) {
    $background-alt: lighten($background, 3%);
    $background-top-bottom: adjust-hue(darken(saturate($background, 12%), 13%), 216deg);
    $border-color: darken($background, 17%);
    $clear-hover: darken($border-color, 10%);
    $buttons-background: darken($background, 3%);
  } @else {
    $background-alt: lighten($background, 11%);
    $background-top-bottom: lighten($background, 11%);
    $border-color: lighten($background, 20%);
    $clear-hover: lighten($border-color, 10%);
    $buttons-background: lighten($background, 8%);
  }

  .mbsc-#{$theme} {
    &.mbsc-picker {
      .mbsc-popup-arrow,
      .mbsc-popup-body {
        background: $background-alt;
      }
    }

    &.mbsc-picker-header {
      border-color: $border-color;
    }

    &.mbsc-datepicker {
      .mbsc-calendar,
      .mbsc-calendar-slide,
      .mbsc-calendar-cell {
        background: $background-alt;
      }
    }

    &.mbsc-datepicker-top.mbsc-datepicker-control-date,
    &.mbsc-datepicker-bottom.mbsc-datepicker-control-date {
      background: $background-top-bottom;
    }

    &.mbsc-datepicker-inline {
      background: $background-alt;
      border-color: $border-color;
      color: $text;
    }

    /* Range Control */

    &.mbsc-range-control-value.active {
      color: $accent;
    }

    &.mbsc-range-control-text-empty,
    &.mbsc-range-control-text-empty.active {
      color: rgba($text, 0.4);
    }

    &.mbsc-range-label-clear {
      color: $border-color;

      &:hover {
        color: $clear-hover;
      }
    }
  }
}
