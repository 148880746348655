@import './popup.ios';
@import './popup.material';
@import './popup.windows';

@if $mbsc-popup {
  @keyframes mbsc-fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes mbsc-fade-out {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes mbsc-pop-in {
    from {
      opacity: 0;
      transform: scale(0.8);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes mbsc-pop-out {
    from {
      opacity: 1;
      transform: scale(1);
    }

    to {
      opacity: 0;
      transform: scale(0.8);
    }
  }

  @keyframes mbsc-slide-up-in {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes mbsc-slide-up-out {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(100%);
    }
  }

  @keyframes mbsc-slide-down-in {
    from {
      transform: translateY(-100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes mbsc-slide-down-out {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(-100%);
    }
  }

  .mbsc-popup-open-ios {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
  }

  .mbsc-popup-ctx {
    position: relative;
    --mbsc-safe-top: 0;
    --mbsc-safe-right: 0;
    --mbsc-safe-bottom: 0;
    --mbsc-safe-left: 0;
  }

  .mbsc-popup-limits {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .mbsc-popup-limits-anchored,
  .mbsc-popup-limits-center {
    margin: 1em;
  }

  .mbsc-popup,
  .mbsc-popup-wrapper {
    box-sizing: border-box;
  }

  .mbsc-popup-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    justify-content: center;
    border: 0 solid transparent;
    z-index: 99998;
    user-select: none;
  }

  .mbsc-popup-wrapper-ctx {
    position: absolute;
    right: auto;
    bottom: auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .mbsc-popup-wrapper-center {
    padding: 1em;
    align-items: center;
    border-width: var(--mbsc-safe-top) var(--mbsc-safe-right) var(--mbsc-safe-bottom) var(--mbsc-safe-left);
  }

  .mbsc-popup-wrapper-top {
    align-items: flex-start;
    border-bottom: var(--mbsc-safe-bottom) solid transparent;
  }

  .mbsc-popup-wrapper-bottom {
    align-items: flex-end;
    border-top-width: var(--mbsc-safe-top);
  }

  .mbsc-popup-wrapper-anchored {
    border-width: 0 var(--mbsc-safe-right) var(--mbsc-safe-bottom) var(--mbsc-safe-left);
  }

  .mbsc-popup-hidden {
    opacity: 0;
  }

  .mbsc-popup-overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -10em;
    pointer-events: auto;
    background: rgba(0, 0, 0, 0.5);
    transform: translateZ(0);
  }

  .mbsc-popup-overlay-in {
    opacity: 1;
    animation: mbsc-fade-in 150ms cubic-bezier(0, 0, 0.2, 1);
  }

  .mbsc-popup-overlay-out {
    opacity: 0;
    animation: mbsc-fade-out 75ms cubic-bezier(0.4, 0, 1, 1);
  }

  .mbsc-popup {
    max-height: 100%;
    min-width: 12em;
    position: relative;
    z-index: 2;
    pointer-events: auto;
    text-shadow: none;
    user-select: none;
  }

  .mbsc-popup-center {
    min-width: 16em;
  }

  .mbsc-popup-bottom {
    width: 100%;
    bottom: 0;
  }

  .mbsc-popup-top {
    width: 100%;
    top: 0;
  }

  .mbsc-popup-anchored {
    position: absolute;
    // max-width: calc(100% - 16px);
  }

  // .mbsc-popup-anchored-top {
  //   margin-top: -1em;
  // }

  // .mbsc-popup-anchored-bottom {
  //   margin-top: 1em;
  // }

  .mbsc-popup-anchored-left {
    margin-left: -1em;
  }

  .mbsc-popup-anchored-right {
    margin-left: 1em;
  }

  .mbsc-popup-arrow-wrapper {
    position: absolute;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;
  }

  .mbsc-popup-arrow-wrapper-top,
  .mbsc-popup-arrow-wrapper-bottom {
    left: 0;
    right: 0;
    height: 2em;
  }

  .mbsc-popup-arrow-wrapper-left,
  .mbsc-popup-arrow-wrapper-right {
    top: 0;
    bottom: 0;
    width: 2em;
  }

  .mbsc-popup-arrow-wrapper-top {
    top: 100%;
  }

  .mbsc-popup-arrow-wrapper-bottom {
    bottom: 100%;
  }

  .mbsc-popup-arrow-wrapper-left {
    left: 100%;
  }

  .mbsc-popup-arrow-wrapper-right {
    right: 100%;
  }

  .mbsc-popup-arrow {
    position: absolute;
    box-sizing: border-box;
    width: 1.5em;
    height: 1.5em;
    pointer-events: auto;
  }

  .mbsc-popup-arrow-top {
    bottom: 1.25em;
  }

  .mbsc-popup-arrow-bottom {
    top: 1.25em;
  }

  .mbsc-popup-arrow-top,
  .mbsc-popup-arrow-bottom {
    left: 50%;
    margin-left: -0.75em;
    transform: rotate(-45deg);
  }

  .mbsc-popup-arrow-left {
    right: 1.25em;
  }

  .mbsc-popup-arrow-right {
    left: 1.25em;
  }

  .mbsc-popup-arrow-left,
  .mbsc-popup-arrow-right {
    top: 50%;
    margin-top: -0.75em;
    transform: rotate(45deg);
  }

  .mbsc-popup-focus {
    outline: 0;
  }

  .mbsc-popup-body {
    overflow: hidden;
    transform: translateZ(0);
  }

  .mbsc-popup-body-top {
    padding-top: var(--mbsc-safe-top);
  }

  .mbsc-popup-body-bottom {
    padding-bottom: var(--mbsc-safe-bottom);
  }

  .mbsc-popup-body-top,
  .mbsc-popup-body-bottom {
    padding-left: var(--mbsc-safe-left);
    padding-right: var(--mbsc-safe-right);
  }

  .mbsc-popup-body-round {
    padding: 0;
  }

  .mbsc-popup-header {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .mbsc-popup-content {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mbsc-popup-padding {
    padding: 1em;
  }

  /* Animations */

  .mbsc-popup-pop-in {
    opacity: 1;
    animation: mbsc-pop-in 150ms cubic-bezier(0, 0, 0.2, 1);
  }

  .mbsc-popup-pop-out {
    opacity: 0;
    animation: mbsc-pop-out 75ms cubic-bezier(0.4, 0, 1, 1);
  }

  .mbsc-popup-slide-up-in {
    animation: mbsc-slide-up-in 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .mbsc-popup-slide-up-out {
    animation: mbsc-slide-up-out 200ms cubic-bezier(0.4, 0, 1, 1);
  }

  .mbsc-popup-slide-down-in {
    animation: mbsc-slide-down-in 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .mbsc-popup-slide-down-out {
    animation: mbsc-slide-down-out 200ms cubic-bezier(0.4, 0, 1, 1);
  }

  /* Desktop styling */

  .mbsc-popup-pointer {
    &.mbsc-picker .mbsc-popup-overlay,
    .mbsc-popup-overlay-anchored {
      background: none;
    }
  }

  .mbsc-popup-round {
    .mbsc-popup-slide-up-in {
      animation-name: mbsc-slide-up-in, mbsc-fade-in;
    }

    .mbsc-popup-slide-up-out {
      animation-name: mbsc-slide-up-out, mbsc-fade-out;
    }

    .mbsc-popup-slide-down-in {
      animation-name: mbsc-slide-down-in, mbsc-fade-in;
    }

    .mbsc-popup-slide-down-out {
      animation-name: mbsc-slide-down-out, mbsc-fade-out;
    }

    .mbsc-popup-top,
    .mbsc-popup-bottom {
      width: auto;
      margin-top: 3em;
      margin-bottom: 3em;
    }

    // .mbsc-popup-short {
    //   margin-top: 0;
    //   margin-bottom: 0;
    // }
  }
}
