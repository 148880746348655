@import './datepicker.ios';
@import './datepicker.material';
@import './datepicker.windows';

@if $mbsc-datepicker {
  /* Needed for angular */
  mbsc-datetime,
  mbsc-date,
  mbsc-time {
    display: block;
    width: 100%;
  }

  .mbsc-datepicker-inline {
    height: 100%;
  }

  .mbsc-datepicker .mbsc-calendar {
    height: 100%;
    max-width: 100%;
    padding-bottom: 0.5em;
    box-sizing: border-box;
    // For IE11
    display: block;
  }

  .mbsc-datepicker .mbsc-calendar-header .mbsc-calendar-week-days {
    padding: 0 0.5em;
  }

  /* Start/end controls */

  .mbsc-range-control-wrapper .mbsc-segmented-input {
    // v4 compatibility
    width: 1px;
  }

  .mbsc-range-label-clear {
    position: absolute;
    top: 50%;
  }

  /* Tabs */

  .mbsc-datepicker-tab-wrapper {
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
  }

  .mbsc-datepicker-tab {
    visibility: hidden;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }

  .mbsc-datepicker-tab-expand {
    height: 100%;
  }

  .mbsc-datepicker-tab-active {
    visibility: visible;
  }

  .mbsc-datepicker-time-modal {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    max-width: none;
    height: 100%;
  }

  .mbsc-datepicker .mbsc-calendar-slide {
    padding: 0 0.5em;
  }
}
