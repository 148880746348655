@import './eventcalendar.ios.colors';

@if ($mbsc-ios-theme and $mbsc-eventcalendar) {
  .mbsc-ios {
    &.mbsc-eventcalendar .mbsc-calendar-header-vertical {
      margin-bottom: -1px;
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }

    &.mbsc-eventcalendar .mbsc-calendar-wrapper {
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }

    &.mbsc-popover-list .mbsc-event:last-child:after {
      display: none;
    }

    &.mbsc-event-day.mbsc-list-header {
      font-weight: bold;
      padding: 0.5em 1em;
      line-height: 1.5em;
      text-transform: none;
    }

    &.mbsc-event-time {
      width: 6em;
      font-size: 0.875em; // 14px
      line-height: 1.428572em; // 20px;
    }

    &.mbsc-event-date {
      width: 10.5em;
    }

    &.mbsc-event-color {
      width: 0.25em;
      border-radius: 0.125em;
      margin: 0 0.5em 0 -0.5em;
    }

    &.mbsc-event-color.mbsc-rtl {
      margin: 0 -0.5em 0 0.5em;
    }

    &.mbsc-event-time.mbsc-ltr {
      text-align: right;
    }

    &.mbsc-event-time.mbsc-rtl {
      text-align: left;
    }

    &.mbsc-event-end {
      opacity: 0.7;
    }

    &.mbsc-event-text {
      font-weight: 500;
    }

    &.mbsc-event-sep {
      display: none;
    }

    &.mbsc-calendar-button-today {
      font-weight: 500;
      padding: 0;
    }

    /* Schedule view */

    /* TODO: refactor this without cascade */
    &.mbsc-eventcalendar-schedule .mbsc-calendar-day,
    &.mbsc-eventcalendar-schedule .mbsc-calendar-wrapper {
      border: 0;
    }

    &.mbsc-schedule-wrapper {
      position: relative;
      margin-top: -1px;
      z-index: 2;
    }

    /* Header */

    &.mbsc-schedule-header {
      border-bottom: 1px solid;
    }

    &.mbsc-schedule-header-item {
      padding: 0.4375em 0;
      line-height: 1.75em;
    }

    &.mbsc-schedule-header-item.mbsc-ltr {
      border-left-style: solid;
      border-left-width: 1px;
    }

    &.mbsc-schedule-header-item.mbsc-rtl {
      border-right-style: solid;
      border-right-width: 1px;
    }

    &.mbsc-schedule-header-item:first-child {
      border: 0;
    }

    &.mbsc-schedule-header-dayname {
      font-size: 1em;
      font-weight: 400;
    }

    &.mbsc-schedule-header-day {
      display: inline-block;
      height: 1.625em;
      width: 1.625em;
      line-height: 1.625em;
      border-radius: 50%;
      text-align: center;
      font-size: 1em;
      font-weight: 400;
    }

    &.mbsc-schedule-header-item-large {
      padding: 0.1875em 0;

      &.mbsc-schedule-header-item {
        border-color: transparent;
      }

      .mbsc-schedule-header-dayname {
        display: inline-block;
        padding: 0 0.375em;
        font-size: 1em;
        font-weight: 400;
      }
    }

    &.mbsc-schedule-all-day-wrapper {
      max-height: 3.125em;
      border-bottom: 1px solid;
      // border-top: 1px solid;
    }

    &.mbsc-schedule-all-day-text {
      padding: 0.5em 0.666667em;
      font-size: 0.75em;
      text-transform: lowercase;
    }

    /* Timezone labels, indicators */

    &.mbsc-schedule-timezone-label {
      line-height: 2.5em;
      padding: 0 0.666667em;
    }

    &.mbsc-schedule-date-header {
      position: relative;
      z-index: 3;
      will-change: opacity;
      border-bottom: 1px solid;
    }

    &.mbsc-schedule-date-header-text {
      padding: 0.5em;
      font-size: 0.75em;
      font-weight: bold;
    }

    &.mbsc-schedule-resource.mbsc-ltr {
      border-left-width: 1px;
      border-left-style: solid;
    }

    &.mbsc-schedule-resource.mbsc-rtl {
      border-right-width: 1px;
      border-right-style: solid;
    }

    &.mbsc-schedule-resource:first-child {
      border: 0;
    }

    /* Grid & All-day row */

    &.mbsc-schedule-all-day-item {
      padding: 0.25em 0;
      min-height: 1.875em;
    }

    &.mbsc-schedule-time {
      font-size: 0.625em;
      padding: 0 0.8em;
    }

    &.mbsc-schedule-cursor-time-x {
      width: 6.8em;
      padding: 0 0.8em;
    }

    /* Schedule event style */

    &.mbsc-schedule-event:after {
      content: '';
      position: absolute;
      top: -0.5em;
      right: 1px;
      bottom: -0.5em;
      left: 1px;
      z-index: -1;
    }

    &.mbsc-schedule-event-start:after {
      top: 1px;
    }

    &.mbsc-schedule-event-end:after {
      bottom: 1px;
    }

    &.mbsc-schedule-event-custom:after {
      display: none;
    }

    &.mbsc-schedule-event-background {
      opacity: 0.3;
      transition: opacity 0.15s ease-in-out;
    }

    &.mbsc-schedule-event-start {
      &:after,
      .mbsc-schedule-event-background {
        border-top-left-radius: 0.25em;
        border-top-right-radius: 0.25em;
      }
    }

    &.mbsc-schedule-event-end {
      &:after,
      .mbsc-schedule-event-background {
        border-bottom-left-radius: 0.25em;
        border-bottom-right-radius: 0.25em;
      }
    }

    &.mbsc-timeline-event:after {
      margin-top: 6px;
      top: 0;
      bottom: 0;
    }

    &.mbsc-timeline-event-start.mbsc-ltr,
    &.mbsc-timeline-event-end.mbsc-rtl {
      &:after,
      .mbsc-schedule-event-background {
        border-bottom-left-radius: 0.25em;
        border-top-left-radius: 0.25em;
      }
    }

    &.mbsc-timeline-event-end.mbsc-ltr,
    &.mbsc-timeline-event-start.mbsc-rtl {
      &:after,
      .mbsc-schedule-event-background {
        border-bottom-right-radius: 0.25em;
        border-top-right-radius: 0.25em;
      }
    }

    &.mbsc-schedule-event-hover {
      .mbsc-schedule-event-background {
        opacity: 0.6;
      }
    }

    &.mbsc-schedule-event-active,
    &.mbsc-schedule-event-dragging {
      .mbsc-schedule-event-background {
        opacity: 0.9;
      }
    }

    &.mbsc-schedule-event-dragging {
      top: 0.25em;
    }

    &.mbsc-timeline-event-dragging {
      top: 0;
    }

    &.mbsc-schedule-event-bar {
      position: absolute;
      top: -0.5em;
      bottom: -0.5em;
      width: 0.1875em;
      background: currentColor;
    }

    &.mbsc-schedule-event-bar.mbsc-ltr {
      left: 1px;
    }

    &.mbsc-schedule-event-bar.mbsc-rtl {
      right: 1px;
    }

    &.mbsc-schedule-event-start {
      .mbsc-schedule-event-bar {
        top: 1px;

        &.mbsc-ltr {
          border-top-left-radius: 0.1875em;
        }

        &.mbsc-rtl {
          border-top-left-radius: 0.1875em;
        }
      }
    }

    &.mbsc-schedule-event-end {
      .mbsc-schedule-event-bar {
        bottom: 1px;

        &.mbsc-ltr {
          border-bottom-left-radius: 0.1875em;
        }

        &.mbsc-rtl {
          border-bottom-left-radius: 0.1875em;
        }
      }
    }

    &.mbsc-schedule-event-title {
      font-weight: 600;
    }

    &.mbsc-schedule-event-buffer-background {
      opacity: 0.15;
    }

    /* Schedule event all-day style */

    &.mbsc-schedule-event-all-day:after {
      top: 0;
      bottom: 0;
    }

    &.mbsc-schedule-event-all-day-background.mbsc-schedule-event-background {
      border-radius: 0;
    }

    &.mbsc-timeline-event-start.mbsc-schedule-event-all-day.mbsc-ltr:after,
    &.mbsc-timeline-event-end.mbsc-schedule-event-all-day.mbsc-rtl:after,
    &.mbsc-timeline-event-start.mbsc-ltr .mbsc-schedule-event-all-day-background,
    &.mbsc-timeline-event-end.mbsc-rtl .mbsc-schedule-event-all-day-background,
    &.mbsc-schedule-event-start.mbsc-schedule-event-all-day.mbsc-ltr:after,
    &.mbsc-schedule-event-end.mbsc-schedule-event-all-day.mbsc-rtl:after,
    &.mbsc-schedule-event-start.mbsc-ltr .mbsc-schedule-event-all-day-background,
    &.mbsc-schedule-event-end.mbsc-rtl .mbsc-schedule-event-all-day-background {
      left: 0.25em;
      border-top-left-radius: 0.25em;
      border-bottom-left-radius: 0.25em;
    }

    &.mbsc-timeline-event-end.mbsc-schedule-event-all-day.mbsc-ltr:after,
    &.mbsc-timeline-event-start.mbsc-schedule-event-all-day.mbsc-rtl:after,
    &.mbsc-timeline-event-end.mbsc-ltr .mbsc-schedule-event-all-day-background,
    &.mbsc-timeline-event-start.mbsc-rtl .mbsc-schedule-event-all-day-background,
    &.mbsc-schedule-event-end.mbsc-schedule-event-all-day.mbsc-ltr:after,
    &.mbsc-schedule-event-start.mbsc-schedule-event-all-day.mbsc-rtl:after,
    &.mbsc-schedule-event-end.mbsc-ltr .mbsc-schedule-event-all-day-background,
    &.mbsc-schedule-event-start.mbsc-rtl .mbsc-schedule-event-all-day-background {
      right: 0.25em;
      border-top-right-radius: 0.25em;
      border-bottom-right-radius: 0.25em;
    }

    /* Large screen styles */

    &.mbsc-calendar-width-md {
      .mbsc-schedule-all-day-wrapper {
        max-height: 3.5em;
      }

      .mbsc-schedule-time,
      .mbsc-schedule-time-indicator-time-x {
        font-size: 0.75em;
        padding: 0 0.666667em;
      }

      .mbsc-schedule-cursor-time-x {
        font-size: 0.75em;
        width: 5.666667em;
        padding: 0 0.666667em;
      }
    }

    /* timeline resource icon */
    &.mbsc-timeline-resource-icon {
      width: 1.214286em;
      padding: 0 0.142858em;
    }
  }

  @include mbsc-ios-eventcalendar('ios', $mbsc-ios-colors);
  @include mbsc-ios-eventcalendar('ios-dark', $mbsc-ios-dark-colors);
}
