@import '../../themes/ios';

@mixin mbsc-ios-list($theme, $params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');

  $colors: mbsc-ios-colors($params);

  $background-alt: '';
  $background-contrast: '';
  $border-color: '';
  $header-background: '';
  $header-text: '';

  @if (lightness($background) > 50%) {
    $background-alt: lighten($background, 3%);
    $background-contrast: #000;
    $border-color: darken($background, 17%);
    $header-background: adjust-hue(darken(saturate($background, 19%), 2%), 240deg);
    $header-text: adjust-hue(lighten(saturate($text, 2%), 44%), 240deg);
  } @else {
    $background-alt: $background;
    $background-contrast: #fff;
    $border-color: lighten($background, 20%);
    $header-background: adjust-hue(lighten(desaturate($background, 19%), 10%), 240deg);
    $header-text: adjust-hue(darken(desaturate($text, 2%), 44%), 240deg);
  }

  .mbsc-#{$theme} {
    &.mbsc-list-item {
      background: $background-alt;
      border-color: $border-color;
      color: $text;
    }

    &.mbsc-list-item:before,
    &.mbsc-list-item:after {
      border-top: 1px solid $border-color;
    }

    &.mbsc-list-header {
      background: $header-background;
      color: $header-text;
      border-color: $border-color;
    }

    &.mbsc-list-item.mbsc-active:before {
      background: rgba(0, 0, 0, 0.15);
    }
  }
}
