.w-40{width: 40%}
.w-60{width: 60%}
.loginInner{max-width: 340px;}
.loginInner a{background-color: #ffffff;-webkit-border-radius: 1.23rem;border-radius: 1.23rem;}
.logoLink img{max-height: 50px;}
.loginForm .form-control{height: 5.3846rem;border: 1px solid #20519D;-webkit-border-radius: 0.77rem;border-radius: 0.77rem;padding: 0 2rem;background-color: #ffffff;}
.loginForm .form-control:focus{border-color: var(--kt-primary);}
/*.loginForm h1{font: 600 2.31rem/1.2 'Inter', sans-serif;} */
.logoName{font: 600 3rem/1.2 'Inter', sans-serif;}

.form-label{color: #1B1A16;font: 600 1.5384rem/1.2 'Inter', sans-serif;}
.loginForm .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon){
    padding-left: 3.85rem;padding-right: 3.85rem;
}
.loginForm .btn{font: 600 1.5384rem/1.2 'Inter', sans-serif;height: 4.62rem;-webkit-border-radius: 6px;border-radius: 6px;}

.subscriptionDiv{
    .fs-16{
        font-size: 16px;
    }
}

.accloginRow{
    &:hover{
        background-color: var(--kt-gray-100);
    }
}

@media only screen and (max-width: 991px){
.w-40{width: 100%}
.w-60{width: 100%}
}

@media only screen and (max-width: 767px){
    .loginForm h1{font-size: 2.2rem;}	
    .logoLink img{max-height: 30px;}
    .loginInner h1{font-size: 1.4rem;}
    .loginForm .form-control{height: 4rem}

    .form-label{font-size: 1.3rem;margin-bottom: 0.8rem;}
    .loginForm .btn{font-size: 18px;}
    .loginForm .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon){padding-left: 30px;padding-right: 30px;}
    .logoName{font-size: 2rem;}

}    

@media only screen and (min-width: 992px) and (max-width: 1024px){
    .logoName{font-size: 2rem;}
}