@import './form-controls.ios.colors.scss';

@if ($mbsc-ios-theme and $mbsc-forms) {
  .mbsc-ios {
    &.mbsc-form-control-wrapper {
      margin-top: -1px;
    }

    &.mbsc-form-control-wrapper.mbsc-error {
      z-index: 2;
    }

    &.mbsc-form-control-wrapper:before,
    &.mbsc-form-control-wrapper:after {
      content: '';
      position: absolute;
      border-top: 1px solid transparent;
    }

    &.mbsc-form-control-wrapper.mbsc-ltr:after,
    &.mbsc-form-control-wrapper.mbsc-ltr:before {
      right: 0;
      left: 1em;
    }

    &.mbsc-form-control-wrapper.mbsc-rtl:after,
    &.mbsc-form-control-wrapper.mbsc-rtl:before {
      left: 0;
      right: 1em;
    }

    &.mbsc-form-control-wrapper:before {
      top: 0;
    }

    &.mbsc-form-control-wrapper:after {
      bottom: 0;
    }

    .mbsc-block-title + &.mbsc-form-control-wrapper.mbsc-ltr:before,
    .mbsc-form-group-title + &.mbsc-form-control-wrapper.mbsc-ltr:before,
    &.mbsc-form-control-wrapper.mbsc-ltr:first-child:before,
    &.mbsc-form-control-wrapper.mbsc-ltr:last-child:after {
      left: 0;
    }

    .mbsc-block-title + &.mbsc-form-control-wrapper.mbsc-rtl:before,
    .mbsc-form-group-title + &.mbsc-form-control-wrapper.mbsc-rtl:before,
    &.mbsc-form-control-wrapper.mbsc-rtl:first-child:before,
    &.mbsc-form-control-wrapper.mbsc-rtl:last-child:after {
      right: 0;
    }

    &.mbsc-form-control-label.mbsc-disabled,
    &.mbsc-description.mbsc-disabled {
      opacity: 0.3;
    }

    .mbsc-form-group-inset .mbsc-form-group-title + .mbsc-form-control-wrapper,
    .mbsc-form-group-inset .mbsc-form-control-wrapper:first-child {
      border-top-left-radius: 0.5em;
      border-top-right-radius: 0.5em;
    }

    .mbsc-form-group-inset .mbsc-form-control-wrapper:last-child {
      border-bottom-left-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
    }

    .mbsc-form-group-inset .mbsc-form-group-title,
    .mbsc-form-group-inset .mbsc-form-group-title + .mbsc-form-control-wrapper:before,
    .mbsc-form-group-inset .mbsc-form-control-wrapper:first-child:before,
    .mbsc-form-group-inset .mbsc-form-control-wrapper:last-child:after {
      border-width: 0;
    }
  }

  @include mbsc-ios-form-controls('ios', $mbsc-ios-colors);
  @include mbsc-ios-form-controls('ios-dark', $mbsc-ios-dark-colors);
}
