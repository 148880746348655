@import '../../themes/ios';

@mixin mbsc-ios-form-controls($theme, $params) {
  $text: map-get($params, 'text');
  $background: map-get($params, 'background');

  $bg-param: map-get($params, 'form-background');
  $err-param: map-get($params, 'form-error');

  $background: if($bg-param, $bg-param, $background);

  $is-dark: false;
  $border-color: '';
  $wrapper-background: '';
  $wrapper-background-popup: '';

  @if (lightness($background) > 50%) {
    $border-color: darken($background, 17%);
    $wrapper-background: lighten($background, 6%);
    $wrapper-background-popup: $wrapper-background;
  } @else {
    $is-dark: true;
    $border-color: lighten($background, 20%);
    $wrapper-background: lighten($background, 11%);
    $wrapper-background-popup: lighten($background, 17%);
  }

  $error: if($err-param, $err-param, $mbsc-ios-error);

  .mbsc-#{$theme} {
    /* Wrapper */

    &.mbsc-form-control-wrapper {
      background: $wrapper-background;
    }

    &.mbsc-form-control-wrapper:before,
    &.mbsc-form-control-wrapper:after {
      border-color: $border-color;
    }

    &.mbsc-form-control-wrapper.mbsc-error:after,
    &.mbsc-form-control-wrapper.mbsc-error + .mbsc-form-control-wrapper:before {
      border-color: $error;
    }

    /* Inside popup */

    @if ($is-dark) {
      &.mbsc-popup &.mbsc-form-control-wrapper {
        background: $wrapper-background-popup;
      }
    }
  }
}
