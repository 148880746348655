@import './calendar-view.ios';
@import './calendar-view.material';
@import './calendar-view.windows';

@if $mbsc-calendar-view {
  @keyframes mbsc-zoom-in-up {
    from {
      opacity: 0;
      transform: scale(2);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes mbsc-zoom-in-down {
    from {
      opacity: 0;
      transform: scale(0.5);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes mbsc-zoom-out-up {
    from {
      opacity: 1;
      transform: scale(1);
    }

    to {
      opacity: 0;
      transform: scale(2);
    }
  }

  @keyframes mbsc-zoom-out-down {
    from {
      opacity: 1;
      transform: scale(1);
    }

    to {
      opacity: 0;
      transform: scale(0.5);
    }
  }

  .mbsc-calendar {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    position: relative;
  }

  .mbsc-calendar-wrapper {
    position: relative;
    z-index: 1;
    // width: 100%;
    height: 100%;
    overflow: hidden;
  }

  /* Header */

  .mbsc-calendar-header {
    position: relative;
    z-index: 1;
    will-change: opacity;

    .mbsc-calendar-week-days {
      margin-left: -1px;
    }
  }

  .mbsc-calendar-controls {
    align-items: center;
    box-sizing: content-box;
    min-height: 2.5em;
  }

  .mbsc-calendar-button.mbsc-button.mbsc-reset {
    margin: 0;
  }

  .mbsc-calendar-button-prev-multi {
    order: -1;
  }

  .mbsc-calendar-button-next-multi {
    order: 1;
  }

  .mbsc-calendar-body {
    // height: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }

  .mbsc-calendar-body-inner {
    // height: 100%;
    position: relative;
    overflow: hidden;
    margin-left: -1px;
  }

  .mbsc-calendar-wrapper-fixed {
    // display: block;
    height: auto;
    overflow: visible;

    // .mbsc-calendar-header {
    //   display: block;
    // }

    // .mbsc-calendar-body {
    //   display: block;
    //   height: auto;
    // }
  }

  .mbsc-calendar-title-wrapper {
    align-items: center;
    overflow: hidden;
  }

  .mbsc-calendar-title {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.125em;
    // line-height: 2.222223em;
    padding: 0 0.25em;
    display: inline-block;
    vertical-align: middle;
  }

  /* Scrollview */

  .mbsc-calendar-scroll-wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  .mbsc-calendar-picker-wrapper {
    position: relative;
    width: 16em;
    height: 16em;
    overflow: hidden;
  }

  .mbsc-calendar-picker {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .mbsc-calendar-picker-main {
    position: relative;
    z-index: 0;
  }

  .mbsc-calendar-picker-in-up {
    opacity: 0;
    animation: mbsc-zoom-in-up 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
  }

  .mbsc-calendar-picker-in-down {
    opacity: 0;
    animation: mbsc-zoom-in-down 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
  }

  .mbsc-calendar-picker-out-up {
    opacity: 0;
    animation: mbsc-zoom-out-up 200ms cubic-bezier(0.4, 0, 1, 1) forwards;
  }

  .mbsc-calendar-picker-out-down {
    opacity: 0;
    animation: mbsc-zoom-out-down 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
  }

  .mbsc-calendar-scroll-wrapper > div {
    height: 100%;
  }

  .mbsc-calendar-scroll-wrapper > div > div {
    height: 100%;
    transform: translate3d(0, 0, 0);
  }

  .mbsc-calendar-slide {
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .mbsc-calendar-slide.mbsc-calendar-picker {
    position: relative;
  }

  .mbsc-calendar-slide.mbsc-ltr {
    left: 0;
  }

  .mbsc-calendar-slide.mbsc-rtl {
    right: 0;
  }

  .mbsc-calendar-table {
    height: 100%;
    // Needed for iOS Safari to prevent animation flicker
    transform: translateZ(0);
  }

  .mbsc-calendar-cell {
    position: relative;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
  }

  .mbsc-calendar-cell:focus {
    outline: 0;
  }

  .mbsc-calendar-cell-inner {
    display: inline-block;
    padding: 0 0.25em;
    vertical-align: middle;
  }

  .mbsc-calendar-cell.mbsc-disabled {
    cursor: not-allowed;
  }

  .mbsc-calendar-week-day {
    height: 2em;
    font-size: 0.6875em;
    line-height: 2em;
    text-align: center;
    border-bottom: 1px solid transparent;
  }

  .mbsc-calendar-year,
  .mbsc-calendar-month {
    vertical-align: middle;
  }

  .mbsc-calendar-year-text,
  .mbsc-calendar-month-text {
    margin: 0.875em 0;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 4.5em;
    box-sizing: border-box;
  }

  .mbsc-calendar-day {
    min-width: 0;
    text-align: center;
  }

  .mbsc-calendar-day-empty {
    cursor: default;
  }

  .mbsc-calendar-day-text {
    display: inline-block;
    text-align: center;
  }

  .mbsc-calendar-day-text.mbsc-calendar-cell-text {
    // Stronger rule is needed for box-sizing, if placed inside mbsc-grid
    box-sizing: content-box;
  }

  .mbsc-calendar-day-inner {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    border: 1px solid transparent;
    box-sizing: border-box;
  }

  .mbsc-calendar-day-hidden {
    visibility: hidden;
  }

  .mbsc-calendar-month-name {
    display: none;
  }

  .mbsc-calendar-width-md .mbsc-calendar-month-name {
    display: inline-block;
    font-weight: bold;
  }

  .mbsc-calendar-popup .mbsc-calendar-month-name {
    display: none;
  }

  .mbsc-calendar-day-outer .mbsc-calendar-day-text,
  .mbsc-calendar-day-outer .mbsc-calendar-month-name {
    opacity: 0.5;
  }

  .mbsc-calendar-day-outer.mbsc-selected .mbsc-calendar-day-text,
  .mbsc-calendar-day-outer.mbsc-selected .mbsc-calendar-month-name {
    opacity: 1;
  }

  .mbsc-disabled .mbsc-calendar-cell-text,
  .mbsc-disabled .mbsc-calendar-month-name {
    opacity: 0.2;
  }

  /* Day highlight */

  .mbsc-calendar-day-highlight:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
  }

  /* Marks */

  .mbsc-calendar-day-marked {
    padding-bottom: 0.25em;
  }

  .mbsc-calendar-marks {
    position: absolute;
    left: 0;
    right: 0;
    height: 0.375em;
    margin-top: -0.125em;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
  }

  .mbsc-calendar-mark {
    display: inline-block;
    vertical-align: top;
    width: 0.25em;
    height: 0.25em;
    margin: 0.0625em;
    border-radius: 0.25em;
  }

  /* Labels */

  .mbsc-calendar-labels {
    position: absolute;
    left: -1px;
    right: -1px;
  }

  .mbsc-calendar-label-wrapper {
    position: absolute;
    z-index: 2;
    pointer-events: none;
  }

  .mbsc-calendar-label {
    padding: 0 0.3em;
  }

  .mbsc-calendar-text:focus {
    outline: 0;
  }

  .mbsc-calendar-label-text {
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    z-index: 2;
  }

  .mbsc-calendar-text:before,
  .mbsc-calendar-label-background {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s;
  }

  .mbsc-calendar-label.mbsc-calendar-label-hover:before {
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .mbsc-calendar-text-more {
    padding: 0 0.3em;
  }

  .mbsc-calendar-text-more.mbsc-calendar-label-active:before,
  .mbsc-calendar-text-more.mbsc-calendar-label-hover:before {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .mbsc-calendar-text {
    display: block;
    position: relative;
    height: 1.6em;
    line-height: 1.6em;
    // overflow: hidden;
    font-size: 0.625em;
    white-space: nowrap;
    margin: 0 0.1em 0.2em 0.2em;
  }

  .mbsc-calendar-text-placeholder {
    z-index: -1;
  }

  .mbsc-calendar-text.mbsc-ltr {
    text-align: left;
  }

  .mbsc-calendar-text.mbsc-rtl {
    text-align: right;
  }

  /* Label drag & drop */

  .mbsc-calendar-label-resize {
    position: absolute;
    top: 0;
    height: 100%;
    width: 0.5em;
    z-index: 2;
    cursor: ew-resize;
  }

  .mbsc-calendar-label-resize:after {
    content: '';
    display: none;
    position: absolute;
    top: 50%;
    width: 0.8em;
    height: 0.8em;
    margin-top: -0.4em;
    background: #fff;
    border-radius: 0.8em;
    border: 1px solid currentColor;
    box-sizing: border-box;
  }

  .mbsc-calendar-label-resize-start.mbsc-ltr,
  .mbsc-calendar-label-resize-end.mbsc-rtl {
    left: 0;
  }

  .mbsc-calendar-label-resize-start.mbsc-rtl,
  .mbsc-calendar-label-resize-end.mbsc-ltr {
    right: 0;
  }

  .mbsc-calendar-label-resize-start.mbsc-ltr:after,
  .mbsc-calendar-label-resize-end.mbsc-rtl:after {
    left: -0.4em;
  }

  .mbsc-calendar-label-resize-end.mbsc-ltr:after,
  .mbsc-calendar-label-resize-start.mbsc-rtl:after {
    right: -0.4em;
  }

  .mbsc-calendar-label-resize-start-touch:before,
  .mbsc-calendar-label-resize-end-touch:before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -1em;
    width: 2em;
    height: 2em;
  }

  .mbsc-calendar-label-resize-start-touch:before {
    left: -1em;
  }

  .mbsc-calendar-label-resize-end-touch:before {
    right: -1em;
  }

  .mbsc-calendar-label-hover,
  .mbsc-calendar-label-dragging {
    .mbsc-calendar-label-resize:after {
      display: block;
    }
  }

  .mbsc-calendar-label-inactive {
    opacity: 0.4;
  }

  .mbsc-calendar-label-hidden {
    visibility: hidden;
  }

  .mbsc-calendar-labels-dragging {
    z-index: 3;
  }

  /* Multi month grid view */

  .mbsc-calendar-grid {
    overflow: auto;
    padding: 0 2%;

    .mbsc-calendar-table {
      margin: 0 5%;
      width: 90%;
    }
  }

  .mbsc-calendar-grid-item {
    margin: 1em 0;
    // Space for month title, needed for height 100% to work correctly for .mbsc-calendar-table in Safari
    padding-top: 2.5em;
  }

  .mbsc-calendar-month-title {
    padding-bottom: 0.8em;
    margin: 0 10%;
    margin-top: -2em;
    font-size: 1.25em;
    font-weight: bold;
    line-height: 1.2em;
  }

  /* Variable row */

  .mbsc-calendar-body-inner-variable {
    overflow-y: auto;

    .mbsc-calendar-table {
      min-height: 20em;
    }
  }
}
