@import '../../themes/ios';

@mixin mbsc-ios-segmented($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');

  $bg-param: map-get($params, 'form-background');
  $acc-param: map-get($params, 'form-accent');

  $background: if($bg-param, $bg-param, $background);
  $accent: if($acc-param, $acc-param, $accent);

  $is-dark: false;
  $selectbox: '';
  $selectbox-contrast: '';
  $button-background: '';
  $shadow: '';

  @if (lightness($background) > 50%) {
    $button-background: darken($background, 10%);
    $selectbox: #fff;
    $selectbox-contrast: #000;
    $shadow: darken($background, 24%);
  } @else {
    $is-dark: true;
    $button-background: lighten($background, 11%);
    $selectbox: #5a5a5a;
    $selectbox-contrast: #fff;
    $shadow: $background;
  }

  .mbsc-#{$theme} {
    &.mbsc-segmented {
      background: $button-background;
    }

    &.mbsc-segmented-item:before {
      border-color: rgba($selectbox-contrast, 0.2);
    }

    &.mbsc-segmented-item.mbsc-focus .mbsc-segmented-selectbox {
      box-shadow: 0 0 0 0.0625em rgba($selectbox-contrast, 0.5) inset;
    }

    &.mbsc-segmented-selectbox-inner {
      background: $selectbox;

      &.mbsc-selected {
        box-shadow: $shadow 3px 3px 8px -4px;
      }
    }

    &.mbsc-segmented-button.mbsc-button {
      background: transparent;
      color: $selectbox-contrast;
    }

    /* Inside popup and calendar header */
    @if ($is-dark) {
      &.mbsc-datepicker .mbsc-segmented,
      &.mbsc-popup &.mbsc-segmented,
      &.mbsc-calendar-header &.mbsc-segmented {
        background: lighten($background, 17%);
      }
    }

    /* Color presets */

    &.mbsc-segmented-primary {
      background: $mbsc-ios-primary;
    }

    &.mbsc-segmented-secondary {
      background: $mbsc-ios-secondary;
    }

    &.mbsc-segmented-success {
      background: $mbsc-ios-success;
    }

    &.mbsc-segmented-warning {
      background: $mbsc-ios-warning;
    }

    &.mbsc-segmented-danger {
      background: $mbsc-ios-danger;
    }

    &.mbsc-segmented-info {
      background: $mbsc-ios-info;
    }

    &.mbsc-segmented-light {
      background: $mbsc-ios-light;
    }

    &.mbsc-segmented-dark {
      background: $mbsc-ios-dark;
    }
  }
}
