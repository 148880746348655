@import '../../themes/ios';

@mixin mbsc-ios-timegrid($theme, $params) {
  $background: map-get($params, 'background');
  $accent: map-get($params, 'accent');
  $text: map-get($params, 'text');

  $background-hover: '';
  $selected-text: '';

  @if (lightness($background) > 50%) {
    $background-hover: lighten($accent, 35%);
    $selected-text: lighten($text, 100%);
  } @else {
    $background-hover: darken($accent, 25%);
    $selected-text: lighten($text, 100%);
  }

  .mbsc-#{$theme} {
    &.mbsc-timegrid-item:hover,
    &.mbsc-timegrid-item:focus {
      background: rgba($accent, 0.3);
    }

    &.mbsc-timegrid-item.mbsc-selected {
      background: $accent;
      color: $selected-text;
    }
  }
}
