@import './timegrid.ios.colors';

@if ($mbsc-ios-theme and $mbsc-datepicker) {
  .mbsc-ios {
    &.mbsc-timegrid-item {
      line-height: 2em;
      margin: 0.5em 0.625em;
      border-radius: 2em;
      font-size: 0.875em;
    }

    &.mbsc-timegrid-item.mbsc-selected,
    &.mbsc-timegrid-item:hover,
    &.mbsc-timegrid-item:focus {
      font-size: 1em;
      margin: 0 0.25em;
      outline: none;
    }
  }

  @include mbsc-ios-timegrid('ios', $mbsc-ios-colors);
  @include mbsc-ios-timegrid('ios-dark', $mbsc-ios-dark-colors);
}
